/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
import logo from '../../assets/img/leistung_logo.jpeg';
import logotext from '../../assets/img/leistung_text.jpeg';
import './ExamplesNavbar.scss'

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
  
} from "reactstrap";

function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/index"
            target="_self"
            title="Coded by Creative Tim"
            tag={Link}
          >
            <img className="navbar-logo" src ={logo}  alt="Leistung Corp" />
            <img className="navbar-logo-text" src ={logotext}  alt="Leistung Corp" />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink to="/index" tag={Link}>
               Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/about-us" tag={Link}>
               About Us
              </NavLink>
            </NavItem>
            <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle nav caret>
            Products
          </DropdownToggle>
          <DropdownMenu >
            
            <DropdownItem className="dd-products"><a  className="dropdown-item products" href="/products-electrical">Electrical</a></DropdownItem>
            <DropdownItem className="dd-products"><a  className="dropdown-item products" href="/products-mechanical">Mechanical</a></DropdownItem>
            <DropdownItem className="dd-products"><a  className="dropdown-item products" href="/products-electronics">Electronics</a></DropdownItem>
            <DropdownItem className="dd-products"><a  className="dropdown-item products" href="/products-electromechanical">ElectroMechanical</a></DropdownItem>
            <DropdownItem className="dd-products"><a  className="dropdown-item products" href="/products-pneumatic">Pneumatic</a></DropdownItem>
            <DropdownItem className="dd-products"><a  className="dropdown-item products" href="/products-instrument">Instrumentations</a></DropdownItem>
            <DropdownItem className="dd-products"><a  className="dropdown-item products" href="/products-safety">Safety</a></DropdownItem>
            <DropdownItem className="dd-products"><a  className="dropdown-item products" href="/products-heavy">Heavy Equipments</a></DropdownItem>
            <DropdownItem className="dd-products"><a  className="dropdown-item products" href="/products-hydraulics">Hydraulics</a></DropdownItem>
            <DropdownItem className="dd-products"><a  className="dropdown-item products" href="/products-Oil">Oil and Gas</a></DropdownItem>
            <DropdownItem className="dd-products"><a  className="dropdown-item products" href="/products-testing">Testing</a></DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <NavItem>
              <NavLink to="/index" tag={Link}>
               Services
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                 to="/contact" tag={Link}
              >
                Contact
              </NavLink>
            </NavItem>
            <NavItem className="d-none">
              <NavLink
                data-placement="bottom"
                href="https://twitter.com/CreativeTim?ref=creativetim"
                target="_blank"
                title="Follow us on Twitter"
              >
                <i className="fa fa-twitter" />
                <p className="d-lg-none">Twitter</p>
              </NavLink>
            </NavItem>
            <NavItem className="d-none">
              <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/CreativeTim?ref=creativetim"
                target="_blank"
                title="Like us on Facebook"
              >
                <i className="fa fa-facebook-square" />
                <p className="d-lg-none">Facebook</p>
              </NavLink>
            </NavItem>
            <NavItem className="d-none">
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
                target="_blank"
                title="Follow us on Instagram"
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </NavLink>
            </NavItem>
            <NavItem className="d-none">
              <NavLink
                data-placement="bottom"
                href="https://www.github.com/CreativeTimOfficial?ref=creativetim"
                target="_blank"
                title="Star on GitHub"
              >
                <i className="fa fa-github" />
                <p className="d-lg-none">GitHub</p>
              </NavLink>
            </NavItem>
            </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default ExamplesNavbar;

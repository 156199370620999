/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProductsSafetyHeader from "components/Headers/ProductsSafetyHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "views/examples/ProductsSafety.scss";

import {
  Card,
  CardBody,
  CardTitle,
  CardImg,
} from "reactstrap";

import product1 from "../../assets/img/Protection _ Measurement Devices/Feeder Protection/CSEZen-F600.jpg";
import product2 from "../../assets/img/Protection _ Measurement Devices/Feeder Protection/CSEZen-F250.jpg";
import product3 from "../../assets/img/Protection _ Measurement Devices/Feeder Protection/CSEZen-F200.jpg";
import product4 from "../../assets/img/Protection _ Measurement Devices/Feeder Protection/CSEPro-F300.jpg";
import product5 from "../../assets/img/Protection _ Measurement Devices/Feeder Protection/CSEPro-F220.jpg";
import product6 from "../../assets/img/Protection _ Measurement Devices/Feeder Protection/CSEPro-F120.jpg";

import product7 from "../../assets/img/Protection _ Measurement Devices/Motor Protection/CSEZen-M350.jpg";
import product8 from "../../assets/img/Protection _ Measurement Devices/Motor Protection/CSEPro-M200.jpg";
import product9 from "../../assets/img/Protection _ Measurement Devices/Motor Protection/CSEPro-M200.jpg";


import product10 from "../../assets/img/Protection _ Measurement Devices/Transformer Protection/CSEZen-T200.jpg";
import product11 from "../../assets/img/Protection _ Measurement Devices/Transformer Protection/CSEZen-T140.jpg";
import product12 from "../../assets/img/Protection _ Measurement Devices/Transformer Protection/CSEZen-T240.jpg";
import product13 from "../../assets/img/Protection _ Measurement Devices/Transformer Protection/CSEZen-T100.jpg";
import product14 from "../../assets/img/Protection _ Measurement Devices/Transformer Protection/CSEPro-T170.jpg";
import product15 from "../../assets/img/Protection _ Measurement Devices/Transformer Protection/CSEPro-T140.jpg";
import product16 from "../../assets/img/Protection _ Measurement Devices/Transformer Protection/IRD1T2-WG.jpg";


import product17 from "../../assets/img/Protection _ Measurement Devices/Self Powered Relays (RMU)/CSDPR-V2-400.jpg";
import product18 from "../../assets/img/Protection _ Measurement Devices/Self Powered Relays (RMU)/CSDPR-V2-200.jpg";
import product19 from "../../assets/img/Protection _ Measurement Devices/Self Powered Relays (RMU)/CSPR-V5.jpg";
import product20 from "../../assets/img/Protection _ Measurement Devices/Self Powered Relays (RMU)/CSPR1.jpg";



import product21 from "../../assets/img/Protection _ Measurement Devices/Current Protection/CSENex-I450.jpg";
import product22 from "../../assets/img/Protection _ Measurement Devices/Current Protection/CSENex-I350.jpg";
import product23 from "../../assets/img/Protection _ Measurement Devices/Current Protection/CSENex-I150.jpg";
import product24 from "../../assets/img/Protection _ Measurement Devices/Current Protection/CSENex-I201 202 203.jpg";
import product25 from "../../assets/img/Protection _ Measurement Devices/Current Protection/CSENex-I300.jpg";
import product26 from "../../assets/img/Protection _ Measurement Devices/Current Protection/CSENex-I100 101.jpg";
import product27 from "../../assets/img/Protection _ Measurement Devices/Current Protection/IRI Pro-V3.jpg";
import product28 from "../../assets/img/Protection _ Measurement Devices/Current Protection/IRI Pro-V4.jpg";
import product29 from "../../assets/img/Protection _ Measurement Devices/Current Protection/IRI1-ES.jpg";
import product30 from "../../assets/img/Protection _ Measurement Devices/Current Protection/IRI1-ER.jpg";

import product31 from "../../assets/img/Protection _ Measurement Devices/Voltage Protection/CSENex-U 200.jpg";
import product32 from "../../assets/img/Protection _ Measurement Devices/Voltage Protection/IRU Pro-U O R UO UOR.jpg";

import product33 from "../../assets/img/Protection _ Measurement Devices/AMF RELAYS/EGC-250.jpg";
import product34 from "../../assets/img/Protection _ Measurement Devices/AMF RELAYS/EGC-150.jpg";
import product35 from "../../assets/img/Protection _ Measurement Devices/AMF RELAYS/EGA-250.jpg";
import product36 from "../../assets/img/Protection _ Measurement Devices/AMF RELAYS/EC-RXA-V2.jpg";
import product37 from "../../assets/img/Protection _ Measurement Devices/AMF RELAYS/EC2.jpg";
import product38 from "../../assets/img/Protection _ Measurement Devices/AMF RELAYS/E-Gen.jpg";

import product39 from "../../assets/img/Protection _ Measurement Devices/Meter Series/Artis CSM Meter.jpg";
import product40 from "../../assets/img/Protection _ Measurement Devices/Meter Series/Vertis CSME Meter.jpg";
import product41 from "../../assets/img/Protection _ Measurement Devices/Meter Series/Smart Metering Solution.jpg";

import product42 from "../../assets/img/Protection _ Measurement Devices/FPI Series/Fault Passage Indicator (Combined For Short Circuit _ Earth fault) CSFPI (SC+EFPI).jpg";
import product43 from "../../assets/img/Protection _ Measurement Devices/FPI Series/Fault Passage Indicator (Only For Earth fault) CSFPI (EFPI).jpg";
import product44 from "../../assets/img/Protection _ Measurement Devices/FPI Series/Live Line Indicator.jpg";


import product45 from "../../assets/img/Protection _ Measurement Devices/Auxiliary Relay/MRA-PRO-V2.jpg";
import product46 from "../../assets/img/Protection _ Measurement Devices/Auxiliary Relay/Auxiliary Relay With Electro-mechanical design.jpg";

import product47 from "../../assets/img/Protection _ Measurement Devices/Generator Solutions/MRI1-IR-SR ER.jpg";
import product48 from "../../assets/img/Protection _ Measurement Devices/Generator Solutions/MRF2.jpg";
import product49 from "../../assets/img/Protection _ Measurement Devices/Generator Solutions/BF1-U O UO.jpg";
import product50 from "../../assets/img/Protection _ Measurement Devices/Generator Solutions/MRN2.jpg";
import product51 from "../../assets/img/Protection _ Measurement Devices/Generator Solutions/MRG2.jpg";
import product52 from "../../assets/img/Protection _ Measurement Devices/Generator Solutions/MRR1.jpg";
import product53 from "../../assets/img/Protection _ Measurement Devices/Generator Solutions/BU1-AC-U O UO.jpg";


import product54 from "../../assets/img/Protection _ Measurement Devices/EMPR Relays/mPRO-100.jpg";
import product55 from "../../assets/img/Protection _ Measurement Devices/EMPR Relays/mPRO-100.jpg";
import product56 from "../../assets/img/Protection _ Measurement Devices/EMPR Relays/CSMPA.jpg";
import product57 from "../../assets/img/Protection _ Measurement Devices/EMPR Relays/CSMPM.jpg";



import product58 from "../../assets/img/Asset and personal protection/Insulating Rubber Gloves.jpg";
import product59 from "../../assets/img/Asset and personal protection/Insulating Composite Gloves.jpg";
import product60 from "../../assets/img/Asset and personal protection/Arc Suits.jpg";
import product62 from "../../assets/img/Asset and personal protection/Air Rods.jpg";
import product61 from "../../assets/img/Asset and personal protection/Free Standing Air Termination.jpeg";
import product63 from "../../assets/img/Asset and personal protection/Strike Pad.jpg";
import product64 from "../../assets/img/Asset and personal protection/Bare Copper Tape.jpg";
import product65 from "../../assets/img/Asset and personal protection/Aluminium Tape.jpg";
import product66 from "../../assets/img/Asset and personal protection/Bare Stranded Copper Conductor.jpg";
import product67 from "../../assets/img/Asset and personal protection/Earth Bar.jpg";
import product68 from "../../assets/img/Asset and personal protection/Creepage Extender.jpg";
import product69 from "../../assets/img/Asset and personal protection/Insulating Boots.jpg";
import product70 from "../../assets/img/Asset and personal protection/Suspension _ Tension Insulators.jpg";
import product72 from "../../assets/img/Asset and personal protection/HV Surge Arresters.jpg";
import product71 from "../../assets/img/Asset and personal protection/MV Surge Arresters.jpg";
import product73 from "../../assets/img/Asset and personal protection/Transmission Line Surge Arresters.jpg";





import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function ProductsSafety() {
  

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProductsSafetyHeader />
      <div className="section  ">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p className="safety-intro">
              “Life matters” - we offer wide range of specialized head to foot safety without compromise on quality. 
              </p>
              <br />
            </Col>
          </Row>
          <br />
        </Container>
      </div>
      <div className="section container safety-content pt-0">
        <div
          className="safety-bg"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg2-safety.webp").default + ")",
          }}
        >
          <div className="safety-heading">
            <div className="heading-safety">Safety</div>
          </div>
          <div className="safety-product-list">
            <ul>
              <li className="safety-item">Protection & Measurement Devices</li>
              <li className="safety-item">Asset & Personnel Protection</li>
              <li className="safety-item">PPE</li>
              <li className="safety-item">Arc Protection (ABB)</li>
              <li className="safety-item">Hot stick operators</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section mechanical-products">
        <Container>
          <Row>
            <Carousel
              responsive={responsive}
              containerClass="carousel-container"
              itemClass="carousel-item"
              swipeable={false}
              draggable={true}
            >
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Arc flash garments</CardTitle>
                  {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
                </CardBody>
              </Card>
              <Card >
                <CardImg top src={product2} alt="..." />
                <CardBody>
                  <CardTitle>Protective clothing's</CardTitle>
                  {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
                </CardBody>
              </Card>
              <Card >
                <CardImg top src={product3} alt="..." />
                <CardBody>
                  <CardTitle>Flash Fire Garments</CardTitle>
                  {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
                </CardBody>
              </Card>
             
              <Card >
                <CardImg top src={product4} alt="..." />
                <CardBody>
                  <CardTitle>Fire, Safety and Security Devices</CardTitle>
                  {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
                </CardBody>
              </Card>
            </Carousel>
            ;
          </Row>
          <br />
        </Container>
      </div>

      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Protection & Measurement Devices</div>

          <Container className="no-padding">
            <div className="heading-electrical">Feeder Protection
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>CSEZen-F600</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product2} alt="..." />
                  <CardBody>
                    <CardTitle>CSEZen-F250</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product3} alt="..." />
                  <CardBody>
                    <CardTitle>CSEZen-F200</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product4} alt="..." />
                  <CardBody>
                    <CardTitle>CSEPro-F300</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product5} alt="..." />
                  <CardBody>
                    <CardTitle>CSEPro-F220</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product6} alt="..." />
                  <CardBody>
                    <CardTitle>CSEPro-F120</CardTitle>

                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Motor Protection
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product7} alt="..." />
                  <CardBody>
                    <CardTitle>CSEZen-M350</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product8} alt="..." />
                  <CardBody>
                    <CardTitle>CSEPro-M200</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product9} alt="..." />
                  <CardBody>
                    <CardTitle>CSENex-M100</CardTitle>

                  </CardBody>
                </Card>
              </div>
          
            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Transformer Protection
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product10} alt="..." />
                  <CardBody>
                    <CardTitle>CSEZen-T200</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product11} alt="..." />
                  <CardBody>
                    <CardTitle>CSEZen-T140</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product12} alt="..." />
                  <CardBody>
                    <CardTitle>CSEZen-T240</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product13} alt="..." />
                  <CardBody>
                    <CardTitle>CSEZen-T100</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product14} alt="..." />
                  <CardBody>
                    <CardTitle>CSEPro-T170</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product15} alt="..." />
                  <CardBody>
                    <CardTitle>CSEPro-T140</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product16} alt="..." />
                  <CardBody>
                    <CardTitle>IRD1T2-WG</CardTitle>

                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Self Powered Relays (RMU)
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product17} alt="..." />
                  <CardBody>
                    <CardTitle>CSDPR-V2-400</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product18} alt="..." />
                  <CardBody>
                    <CardTitle>CSDPR-V2-200</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product19} alt="..." />
                  <CardBody>
                    <CardTitle>CSPR-V5</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product20} alt="..." />
                  <CardBody>
                    <CardTitle>CSPR1</CardTitle>

                  </CardBody>
                </Card>
              </div>
           
            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Current Protection
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product21} alt="..." />
                  <CardBody>
                    <CardTitle>CSENex-I450</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product22} alt="..." />
                  <CardBody>
                    <CardTitle>CSENex-I350</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product23} alt="..." />
                  <CardBody>
                    <CardTitle>CSENex-I150</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product24} alt="..." />
                  <CardBody>
                    <CardTitle>CSENex-I201/202/203</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product25} alt="..." />
                  <CardBody>
                    <CardTitle>CSENex-I300</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product26} alt="..." />
                  <CardBody>
                    <CardTitle>CSENex-I100/101</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product27} alt="..." />
                  <CardBody>
                    <CardTitle>IRI Pro-V3</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product28} alt="..." />
                  <CardBody>
                    <CardTitle>IRI Pro-V4</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product29} alt="..." />
                  <CardBody>
                    <CardTitle>IRI1-ES</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product30} alt="..." />
                  <CardBody>
                    <CardTitle>IRI1-ER</CardTitle>

                  </CardBody>
                </Card>
              </div>
           
           
           
            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Voltage Protection
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product31} alt="..." />
                  <CardBody>
                    <CardTitle>CSENex-U 200</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product32} alt="..." />
                  <CardBody>
                    <CardTitle>IRU Pro-U/O/R/UO/UOR</CardTitle>

                  </CardBody>
                </Card>
              </div>
             
           
            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">AMF RELAYS
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product33} alt="..." />
                  <CardBody>
                    <CardTitle>EGC-250</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product34} alt="..." />
                  <CardBody>
                    <CardTitle>EGC-150</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product35} alt="..." />
                  <CardBody>
                    <CardTitle>EGA-250</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product36} alt="..." />
                  <CardBody>
                    <CardTitle>EC-RXA-V2</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product37} alt="..." />
                  <CardBody>
                    <CardTitle>EC2</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product38} alt="..." />
                  <CardBody>
                    <CardTitle>E-GEN+</CardTitle>

                  </CardBody>
                </Card>
              </div>
           
            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Meter Series
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product39} alt="..." />
                  <CardBody>
                    <CardTitle>Artis CSM Meter</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product40} alt="..." />
                  <CardBody>
                    <CardTitle>Vertis CSME Mete</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product41} alt="..." />
                  <CardBody>
                    <CardTitle>Smart Metering Solution: CSM-A</CardTitle>

                  </CardBody>
                </Card>
              </div>

              
           
            </div>
          </Container>

          <Container className="no-padding">
            <div className="heading-electrical">FPI Series
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product42} alt="..." />
                  <CardBody>
                    <CardTitle>Fault Passage Indicator (Combined For Short Circuit & Earth fault) : CSFPI (SC+EFPI)</CardTitle>
                   
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product43} alt="..." />
                  <CardBody>
                    <CardTitle>Fault Passage Indicator (Only For Earth fault) : CSFPI (EFPI)</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product44} alt="..." />
                  <CardBody>
                    <CardTitle>Live Line Indicator</CardTitle>

                  </CardBody>
                </Card>
              </div>
        

            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Auxiliary Relay</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product45} alt="..." />
                  <CardBody>
                    <CardTitle>MRA-PRO-V2</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product46} alt="..." />
                  <CardBody>
                    <CardTitle>Auxiliary Relay With Electro-mechanical design</CardTitle>

                  </CardBody>
                </Card>
              </div>


            </div>
          </Container>

          <Container className="no-padding">
            <div className="heading-electrical">Generator Solutions
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product47} alt="..." />
                  <CardBody>
                    <CardTitle>MRI1-IR-SR/ER</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product48} alt="..." />
                  <CardBody>
                    <CardTitle>MRF2</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product49} alt="..." />
                  <CardBody>
                    <CardTitle>BF1-U/O/UO</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product50} alt="..." />
                  <CardBody>
                    <CardTitle>MRN2</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product51} alt="..." />
                  <CardBody>
                    <CardTitle>MRG2</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product52} alt="..." />
                  <CardBody>
                    <CardTitle>MRR1</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product53} alt="..." />
                  <CardBody>
                    <CardTitle>BU1-AC-U/O/UO</CardTitle>

                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>

         

          <Container className="no-padding">
            <div className="heading-electrical">EMPR Relays
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product54} alt="..." />
                  <CardBody>
                    <CardTitle>mPRO-200</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product54} alt="..." />
                  <CardBody>
                    <CardTitle>mPRO-100</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product55} alt="..." />
                  <CardBody>
                    <CardTitle>mPRO-90</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product56} alt="..." />
                  <CardBody>
                    <CardTitle>CSMPA</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product57} alt="..." />
                  <CardBody>
                    <CardTitle>CSMPM</CardTitle>

                  </CardBody>
                </Card>
              </div>

          
            </div>
          </Container>
          
        </Container>

      </div>


      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Asset & Personnel Protection
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product58} alt="..." />
                  <CardBody>
                    <CardTitle>Insulating Rubber Gloves</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product59} alt="..." />
                  <CardBody>
                    <CardTitle>Insulating Composite Gloves</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product58} alt="..." />
                  <CardBody>
                    <CardTitle>Inner Gloves</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product60} alt="..." />
                  <CardBody>
                    <CardTitle>Arc Suits</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product60} alt="..." />
                  <CardBody>
                    <CardTitle>Early Streamer Emission</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product61} alt="..." />
                  <CardBody>
                    <CardTitle>Free Standing Air Termination</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product62} alt="..." />
                  <CardBody>
                    <CardTitle>Air Rods</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product63} alt="..." />
                  <CardBody>
                    <CardTitle>Strike Pad</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product63} alt="..." />
                  <CardBody>
                    <CardTitle>Rod Brackets and Couplings</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product64} alt="..." />
                  <CardBody>
                    <CardTitle>Bare Copper Tape</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product65} alt="..." />
                  <CardBody>
                    <CardTitle>Aluminium Tape</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product66} alt="..." />
                  <CardBody>
                    <CardTitle>Bare Stranded Copper Conductor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>PVC Covered Stranded Copper Conductor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product67} alt="..." />
                  <CardBody>
                    <CardTitle>Earth Bar</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product68} alt="..." />
                  <CardBody>
                    <CardTitle>Creepage Extender</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product68} alt="..." />
                  <CardBody>
                    <CardTitle>Overhead Line Cover</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product69} alt="..." />
                  <CardBody>
                    <CardTitle>Insulating Boots</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product69} alt="..." />
                  <CardBody>
                    <CardTitle>Post Insulators</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product69} alt="..." />
                  <CardBody>
                    <CardTitle>Suspension & Tension Insulators</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product70} alt="..." />
                  <CardBody>
                    <CardTitle>Railway Insulators</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product71} alt="..." />
                  <CardBody>
                    <CardTitle>MV Surge Arresters</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product72} alt="..." />
                  <CardBody>
                    <CardTitle>HV Surge Arresters</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product73} alt="..." />
                  <CardBody>
                    <CardTitle>Transmission Line Surge Arresters</CardTitle>

                  </CardBody>
                </Card>
              </div>
             

            </div>
          </Container>
       
          
        </Container>

      </div>

      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">PPE
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Face sheild</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Hard hat</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Safety glasses</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Safety goggles</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Anchorage Connectors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Body Belts</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Cable & Rope Grabs</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Carabiners</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Confined Space</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Fall Protection Accessories</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Fall Protection Kits</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Full Body Harnesses</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Lanyards</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Lifelines</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Portable Frames & Jibs</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Rescue & Descent Devices</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Self-retracting Lifelines</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Tool Fall Protection</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Tripods</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>RFID Readers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>RFID Tags</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>In-ear Headsets</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Over-ear Headsets</CardTitle>

                  </CardBody>
                </Card>
              </div>

            </div>
          </Container>
       
          
        </Container>

      </div>

      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Arc protection (ABB)
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Arc fault detection system</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Arc limiter</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>active arc protection</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Arc Guard System</CardTitle>

                  </CardBody>
                </Card>
              </div>
            

            </div>
          </Container>
       
          
        </Container>

      </div>

      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Arc protection (ABB)
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Arc fault detection system</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Arc limiter</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>active arc protection</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Arc Guard System</CardTitle>

                  </CardBody>
                </Card>
              </div>
            

            </div>
          </Container>
       
          
        </Container>

      </div>
      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Hot stick operators
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Rotary Hot Stick Operator</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
            

            </div>
          </Container>
       
          
        </Container>

      </div>
      <DemoFooter />
    </>
  );
}

export default ProductsSafety;

/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";


// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProductsElectricalHeader from "components/Headers/ProductsElectricalHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "views/examples/ContactsPage.scss";
import MapContainer from '../../components/MapComponent.js'
import MailForm from "components/MailForm.js";
import EmailJSForm from "components/EmailJS/EmailJSForm.js";



function ProductsElectrical() {
  

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProductsElectricalHeader />
      <div className="section  contact">
        <Container>
          
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p className="electrical-intro">
               To know more about us please use below details. <br/> We'll be happy to connect with you!
              </p>
              
              <br />
              
            </Col>
          </Row>
          <Row className="contacts-row"> 
              <Col>
              <Card>
              <CardTitle tag="h2" className="card-head address">Address</CardTitle>
              <CardText>9100 Southwest freeway<br/>
              Houston, Texas 77074</CardText>
             
              

              </Card></Col>
              <Col>
              <Card>
              <CardTitle  className="card-head address">Contact</CardTitle>
              
              <CardText>
              sales@leistungcorp.com <br/>
              +1 713-999-9099</CardText>
              </Card></Col>
          </Row>
          <br />
        </Container>
      </div>
      
      <div className="section map">
        <Container>
        <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p className="electrical-intro">
              To contact us, Please use below Form
              </p>
              
              <br />
              
            </Col>
          </Row>
          <EmailJSForm/>
          </Container> </div>
         
      <div className="section map">
        <Container>
        <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p className="electrical-intro">
              Find Us here!
              </p>
              
              <br />
              
            </Col>
          </Row>
          <div className="map-container">
 <MapContainer />
          </div>
         
          </Container> </div>
     
      <DemoFooter />
    </>
  );
}

export default ProductsElectrical;

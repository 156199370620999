/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProductsPneumaticsHeader from "components/Headers/ProductsPneumaticsHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "views/examples/ProductsPneumatics.scss";

import {
  Card,
  CardBody,
  CardTitle,
  CardImg
} from "reactstrap";

import product1 from '../../assets/img/INSTRUMENT AND ELECTRICAL TEST EQUIPMENT/Hart communicators.jpeg';
import product2 from '../../assets/img/INSTRUMENT AND ELECTRICAL TEST EQUIPMENT/Digital Deadweight Tester.jpeg';
import product3 from '../../assets/img/INSTRUMENT AND ELECTRICAL TEST EQUIPMENT/Calibrator or Digital Manometers.jpeg';
import product4 from '../../assets/img/INSTRUMENT AND ELECTRICAL TEST EQUIPMENT/Gauges and Transmitters.jpeg';
import product5 from '../../assets/img/INSTRUMENT AND ELECTRICAL TEST EQUIPMENT/Load Cell and Scales Calibrator.jpeg';
import product6 from '../../assets/img/INSTRUMENT AND ELECTRICAL TEST EQUIPMENT/Temperature Dry Block.jpeg';
import product7 from '../../assets/img/INSTRUMENT AND ELECTRICAL TEST EQUIPMENT/PH conductivity Calibrator.jpeg';
import product8 from '../../assets/img/INSTRUMENT AND ELECTRICAL TEST EQUIPMENT/Flow Meters’ Calibrator.jpeg';
import product9 from '../../assets/img/INSTRUMENT AND ELECTRICAL TEST EQUIPMENT/Vibration Speed Calibrator.jpeg';
import product10 from '../../assets/img/circuit breaker remote racking systems/SRU Battery Powered System.png';
import product11 from '../../assets/img/circuit breaker remote racking systems/SR _ SRN GE Magneblast System.jpg';
import product12 from '../../assets/img/Offline Fault Locator/Max-3.jpg';
import product13 from '../../assets/img/Offline Fault Locator/MAX SLS.jpg';
import product14 from '../../assets/img/Offline Fault Locator/ACCUMAX.jpg';
import product15 from '../../assets/img/Dedicated DC Earth Fault Location System/DC361.jpg';
import product16 from '../../assets/img/Dedicated DC Earth Fault Location System/DC 361P.jpg';
import product17 from '../../assets/img/Dedicated DC Earth Fault Location System/DC 451P.jpg';
import product18 from '../../assets/img/Tower Footing Earth Resistance Meter/PREZIOHM TFR 54HF.jpg';
import product19 from '../../assets/img/Tower Footing Earth Resistance Meter/TFR 73HF.jpg';
import product20 from '../../assets/img/Earth Tester/ET 46D.jpg';
import product21 from '../../assets/img/Earth Tester/ET 46S.jpg';





import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


function ProductsTesting() {
  

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProductsPneumaticsHeader />
      <div className="section  ">
        <Container>
          
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p className="pneumatic-intro">
              Emerging world in needs of pneumatic instrumentation and we provide wide range of sensors , switches and control elements 
              </p>
              <br />
              
            </Col>
          </Row>
          <br />
        </Container>
      </div>
      <div className="section container pneumatic-content pt-0">
          <div className="pneumatic-bg" style={{
          backgroundImage:
            "url(" + require("assets/img/bg2-pneumatic.webp").default + ")",
        }}>
            <div className="pneumatic-heading"><div className="heading-pneumatic">
            Pneumatic</div></div>
            <div className="pneumatic-product-list">
                <ul>
                    <li className="pneumatic-item">Electrical and Instrument testing </li>
                    <li className="pneumatic-item">Circuit breaker remote racking systems
 </li>
                    <li className="pneumatic-item"> Offline Fault Locator

</li>
                    <li className="pneumatic-item"> Dedicated DC Earth Fault Location System

</li>
                    <li className="pneumatic-item"> Tower Footing Earth Resistance Meter
</li>
                    <li className="pneumatic-item"> Earth Tester
</li>
                    <li className="pneumatic-item"> Insulation Tester

</li>

<li className="pneumatic-item"> HVDC Offline Fault Locator

</li>
<li className="pneumatic-item"> Testing Equipments (Megger)

</li>
<li className="pneumatic-item">Monitoring
</li>
<li className="pneumatic-item">Tracing of power cables (Power Arbour)

</li>
                    
                </ul>
            </div>
          </div>
      </div>
    
      <div className="section mechanical-products electrical-items">
        <Container>
          <div className="heading-electrical-main">Electrical and Instrument testing ​</div>

          <Container className="no-padding">
         
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Hart communicators</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product2} alt="..." />
                  <CardBody>
                    <CardTitle>Digital Deadweight Tester</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product3} alt="..." />
                  <CardBody>
                    <CardTitle>Calibrator /Digital Manometers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product4} alt="..." />
                  <CardBody>
                    <CardTitle>Gauges and Transmitters</CardTitle>

                  </CardBody>
                </Card>
              </div>
           
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product5} alt="..." />
                  <CardBody>
                    <CardTitle>Load Cell and Scales Calibrator</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product6} alt="..." />
                  <CardBody>
                    <CardTitle>Temperature Dry Block/Bath</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product7} alt="..." />
                  <CardBody>
                    <CardTitle>PH /conductivity Calibrator</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product8} alt="..." />
                  <CardBody>
                    <CardTitle>Flow Meters’ Calibrator</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product9} alt="..." />
                  <CardBody>
                    <CardTitle>Vibration/Speed Calibrator</CardTitle>

                  </CardBody>
                </Card>
              </div>


            </div>
          </Container>
        </Container>

        <Container>
          <div className="heading-electrical-main">Circuit breaker remote racking systems ​</div>

          <Container className="no-padding">
         
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product10} alt="..." />
                  <CardBody>
                    <CardTitle>SRU Battery Powered System</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product11} alt="..." />
                  <CardBody>
                    <CardTitle>SR & SRN GE Magneblast System</CardTitle>

                  </CardBody>
                </Card>
              </div>
           

            </div>
          </Container>
        </Container>

        <Container>
          <div className="heading-electrical-main">Offline Fault Locator​</div>

          <Container className="no-padding">
         
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product12} alt="..." />
                  <CardBody>
                    <CardTitle>MAX 3</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product13} alt="..." />
                  <CardBody>
                    <CardTitle>MAX SLS</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product14} alt="..." />
                  <CardBody>
                    <CardTitle>ACCUMAX</CardTitle>

                  </CardBody>
                </Card>
              </div>
           

            </div>
          </Container>
        </Container>

        <Container>
          <div className="heading-electrical-main">Dedicated DC Earth Fault Location System​</div>

          <Container className="no-padding">
         
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product15} alt="..." />
                  <CardBody>
                    <CardTitle>DC 361</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product16} alt="..." />
                  <CardBody>
                    <CardTitle>DC 361P</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product17} alt="..." />
                  <CardBody>
                    <CardTitle> DC 451P</CardTitle>

                  </CardBody>
                </Card>
              </div>
           

            </div>
          </Container>
        </Container>

        <Container>
          <div className="heading-electrical-main">Tower Footing Earth Resistance Meter
​</div>

          <Container className="no-padding">
         
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product18} alt="..." />
                  <CardBody>
                    <CardTitle>PREZIOHM TFR 54HF</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product19} alt="..." />
                  <CardBody>
                    <CardTitle>TFR 73HF</CardTitle>

                  </CardBody>
                </Card>
              </div>
          
           

            </div>
          </Container>
        </Container>

        <Container>
          <div className="heading-electrical-main">
          Earth Tester

​</div>

          <Container className="no-padding">
         
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product20} alt="..." />
                  <CardBody>
                    <CardTitle>ET 46D</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product21} alt="..." />
                  <CardBody>
                    <CardTitle>ET 46S</CardTitle>

                  </CardBody>
                </Card>
              </div>
          
           

            </div>
          </Container>
        </Container>

        <Container>
          <div className="heading-electrical-main">
         Insulation Tester

​</div>

          <Container className="no-padding">
         
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product20} alt="..." />
                  <CardBody>
                    <CardTitle>IT 5A10 (5KV)</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product21} alt="..." />
                  <CardBody>
                    <CardTitle>IT 10A10 (10KV)</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product21} alt="..." />
                  <CardBody>
                    <CardTitle>IT 15A10 (15KV)</CardTitle>

                  </CardBody>
                </Card>
              </div>
           

            </div>
          </Container>
        </Container>
      </div>

      
      <DemoFooter />
    </>
  );
}

export default ProductsTesting;

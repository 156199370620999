/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
 Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProductsInstrumentHeader from "components/Headers/ProductsInstrumentHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "views/examples/ProductsInstrument.scss";
import {
  Card,
  CardBody,
  CardTitle,
  CardImg
} from "reactstrap";

import product1 from '../../assets/img/sampler/Gas Sampler.jpeg';
import product2 from '../../assets/img/sampler/Liquid Sampler.jpeg';
import product3 from '../../assets/img/sampler/Liquid Sampler.jpeg';
import product4 from '../../assets/img/sampler/Liquefied Gas Sampler.jpeg';
import product5 from '../../assets/img/sampler/Sampler Recovery System.jpeg';


import product9 from '../../assets/img/Enclosures Cooling Solutions/Enclosure Air Conditioners.jpeg';
import product11 from '../../assets/img/Enclosures Cooling Solutions/Filtered Fans.jpeg';
import product10 from '../../assets/img/Enclosures Cooling Solutions/Heat Exchanger.jpeg';

import product12 from '../../assets/img/Transmission and Distribution Insulators/Digital Static Transfer Switches.jpeg';
import product13 from '../../assets/img/Transmission and Distribution Insulators/Remote Power Distribution.jpeg';
import product14 from '../../assets/img/Transmission and Distribution Insulators/Remote Power Distribution.jpeg';
import product15 from '../../assets/img/Transmission and Distribution Insulators/DC Battery Chargers.jpeg';

import product16 from '../../assets/img/Pyrometers/High Temperature Infrared Pyrometers.jpg';
import product17 from '../../assets/img/Pyrometers/Thermalert 4.0 Series Pyrometers.jpg';
import product18 from '../../assets/img/Pyrometers/Compact CI and CM.jpg';
import product20 from '../../assets/img/Pyrometers/Marathon MM.jpg';
import product21 from '../../assets/img/Pyrometers/Compact GP.jpg';
import product22 from '../../assets/img/Pyrometers/Modline 7.jpg';
import product19 from '../../assets/img/Pyrometers/Compact MI3.jpg';


import product23 from '../../assets/img/Thermal Profiling Systems/Oven Tracker Systems.jpg';
import product24 from '../../assets/img/Thermal Profiling Systems/Kiln Tracker System.jpg';
import product25 from '../../assets/img/Thermal Profiling Systems/Furnace Tracker Systems.jpg';
import product26 from '../../assets/img/Thermal Profiling Systems/Solar Tracker System.jpg';
import product27 from '../../assets/img/Thermal Profiling Systems/Reflow Tracker System.jpg';

import product28 from '../../assets/img/Gas Meters/Diaphragm Gas Meter.jpg';
import product29 from '../../assets/img/Gas Meters/AMR Postpaid Gas Meter.jpg';
import product30 from '../../assets/img/Gas Meters/AMR Prepaid Gas Meter.jpeg';
import product31 from '../../assets/img/Gas Meters/Diaphragm Gas Meter.jpg';


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


function ProductsInstrument() {
  

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProductsInstrumentHeader />
      <div className="section  ">
        <Container>
          
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p className="instrument-intro">
              Wide range of instrumention cables, controllers and transmitters is one of our core area of supply 
              </p>
              <br />
              
            </Col>
          </Row>
          <br />
        </Container>
      </div>
      <div className="section container instrument-content pt-0">
          <div className="instrument-bg" style={{
          backgroundImage:
            "url(" + require("assets/img/bg2-instrument.webp").default + ")",
        }}>
            <div className="instrument-heading"><div className="heading-instrument">
            Instrumentations</div></div>
            <div className="instrument-product-list">
                <ul>
           
                    <li className="mechanical-item">Instrumentation</li>
                <li className="mechanical-item">Pyromoeters</li>
                    <li className="mechanical-item">Thermal Profiling Systes</li>
                    <li className="mechanical-item">Gas Meters</li>
                    <li className="mechanical-item">Metal Detection</li>
                    <li className="mechanical-item">Gauges</li>
                 
                    
                </ul>
            </div>
          </div>
      </div>

      <div className="section mechanical-products">
        <Container>
          <Row>

          <Carousel responsive={responsive}
           containerClass="carousel-container"
           itemClass="carousel-item"
           swipeable={false}
  draggable={true}
  
  >
             
    <Card >
            <CardImg top src={product1} alt="..." />
              <CardBody>

                <CardTitle>Temperature Controllers</CardTitle>
                {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
              </CardBody>
            </Card>
            <Card >
            <CardImg top src={product2} alt="..." />
              <CardBody>

                <CardTitle>Fault Indicators</CardTitle>
                {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
              </CardBody>
            </Card>
            <Card >
            <CardImg top src={product3} alt="..." />
              <CardBody>

                <CardTitle>Heaters</CardTitle>
                {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
              </CardBody>
            </Card>
            <Card >
            <CardImg top src={product4} alt="..." />
              <CardBody>

                <CardTitle>Infrared Pyrometers</CardTitle>
                {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
              </CardBody>
            </Card>
            
            <Card >
            <CardImg top src={product5} alt="..." />
              <CardBody>

                <CardTitle>Temperature Transmitters</CardTitle>
                {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
              </CardBody>
            </Card>
            <Card >
            <CardImg top src={product1} alt="..." />
              <CardBody>

                <CardTitle>Thermal Imagers</CardTitle>
                {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
              </CardBody>
            </Card>
            <Card >
            <CardImg top src={product1} alt="..." />
              <CardBody>

                <CardTitle>Temperature Switches</CardTitle>
                {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
              </CardBody>
            </Card>
            <Card >
            <CardImg top src={product1} alt="..." />
              <CardBody>

                <CardTitle>Thermowells</CardTitle>
                {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
              </CardBody>
            </Card>
</Carousel>;
          </Row>
          <br />
        </Container>
      </div>
      <div className="section mechanical-products instrument-items">
        <Container>
          <div className="heading-electrical-main">Instrumentation</div>

          <Container className="no-padding">
            <div className="heading-electrical">Samplers</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Natural Gas Samplers</CardTitle>
               
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product2} alt="..." />
                  <CardBody>
                    <CardTitle>Light Liquids Samplers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product3} alt="..." />
                  <CardBody>
                    <CardTitle>Crude Oil Containers and Samplers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product4} alt="..." />
                  <CardBody>
                    <CardTitle>Constant Pressure Sample Cylinders</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product5} alt="..." />
                  <CardBody>
                    <CardTitle>Automatic Insertion Devices</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Probes</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Filter and Filter Dryers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Jet Control Valves</CardTitle>

                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Enclosures Cooling Solutions
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product9} alt="..." />
                  <CardBody>
                    <CardTitle>Enclosure Air Conditioners</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product10} alt="..." />
                  <CardBody>
                    <CardTitle>Heat Exchangers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product11} alt="..." />
                  <CardBody>
                    <CardTitle>Filtered Fans</CardTitle>
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Cabinet</CardTitle>

                  </CardBody>
                </Card>
              </div>

            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Transmission and Distribution Insulators Industrial UPS 
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product12} alt="..." />
                  <CardBody>
                    <CardTitle>Digital Static Transfer Switches</CardTitle>

                  </CardBody>
                </Card>
              </div>


            </div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product13} alt="..." />
                  <CardBody>
                    <CardTitle>Remote Power Distribution</CardTitle>

                  </CardBody>
                </Card>
              </div>


            </div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product14} alt="..." />
                  <CardBody>
                    <CardTitle>Circuit Management</CardTitle>

                  </CardBody>
                </Card>
              </div>


            </div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product15} alt="..." />
                  <CardBody>
                    <CardTitle>DC Battery Chargers</CardTitle>

                  </CardBody>
                </Card>
              </div>


            </div>
          </Container>
        </Container>



        <Container>
          <div className="heading-electrical-main">Pyrometers
</div>

          <Container className="no-padding">
        
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product16} alt="..." />
                  <CardBody>
                    <CardTitle>High Temperature Infrared Pyrometers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product17} alt="..." />
                  <CardBody>
                    <CardTitle>Thermalert 4.0 Series Pyrometers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product18} alt="..." />
                  <CardBody>
                    <CardTitle>Compact CI and CM</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product19} alt="..." />
                  <CardBody>
                    <CardTitle>Compact MI3</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product20} alt="..." />
                  <CardBody>
                    <CardTitle>Marathon MM</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product21} alt="..." />
                  <CardBody>
                    <CardTitle>Compact GP</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product22} alt="..." />
                  <CardBody>
                    <CardTitle>Modline® 7</CardTitle>

                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
       
        </Container>




        <Container>
          <div className="heading-electrical-main">Thermal Profiling Systems



</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product23} alt="..." />
                  <CardBody>
                    <CardTitle>Oven Tracker Systems</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product24} alt="..." />
                  <CardBody>
                    <CardTitle> Kiln Tracker System</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product25} alt="..." />
                  <CardBody>
                    <CardTitle>Furnace Tracker Systems</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product26} alt="..." />
                  <CardBody>
                    <CardTitle>Solar Tracker System</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product27} alt="..." />
                  <CardBody>
                    <CardTitle>Reflow Tracker System</CardTitle>

                  </CardBody>
                </Card>
              </div>
           



            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Fuses and Fuse Holders</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Battery terminal fuses</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Blade Fuses</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>British Standard IEC fuses</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Cable Limiters</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>CCP disconnect switches</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Class CC fuse blocks and holders</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Class T fuse blocks and holders</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>double row connectors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>double row connectors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Power Module Panels</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Power Module Switches</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Pullout Telcom disconnects and fuses</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Supplemental fuse blocks, holders, and clips</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Telcom fuse holders</CardTitle>

                  </CardBody>
                </Card>
              </div>






            </div>
          </Container>


        </Container>


        <Container>
          <div className="heading-electrical-main">Gas Meters
</div>

          <Container className="no-padding">
 
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product28} alt="..." />
                  <CardBody>
                    <CardTitle>Diaphragm Gas Meter</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product29} alt="..." />
                  <CardBody>
                    <CardTitle>AMR Postpaid Gas Meter</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product30} alt="..." />
                  <CardBody>
                    <CardTitle>AMR Prepaid Gas Meter</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product31} alt="..." />
                  <CardBody>
                    <CardTitle>Retrofitable AMR Module for G1.6 Gas Meter</CardTitle>

                  </CardBody>
                </Card>
              </div>
            

            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Metal Detection
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Xtreme Metal Detectors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>1200 Series Metal Detectors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>CFP Metal Separator</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>MetAlarm Metal Detectors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Onload Changeover Switches</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>FF Metal Separator</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>PolyMag Plastic Additives</CardTitle>

                  </CardBody>
                </Card>
              </div>






            </div>
          </Container>

          <Container className="no-padding">
            <div className="heading-electrical">Guages
            </div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Level Guages</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Pressure Guages</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Precision Guages</CardTitle>

                  </CardBody>
                </Card>
              </div>


            </div>
          </Container>

        </Container>

      </div>
      
      <DemoFooter />
    </>
  );
}

export default ProductsInstrument;

/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProductsHeavyHeader from "components/Headers/ProductsHeavyHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "views/examples/ProductsElectroMechanical.scss";

import {
  Card,
  CardBody,
  CardTitle,
  CardImg,
} from "reactstrap";

import product1 from "../../assets/img/Electrical _ Electro Mechanical/Power-transformer.jpg";
import product2 from "../../assets/img/Electrical _ Electro Mechanical/Actuator-Valves.jpg";
import product3 from "../../assets/img/Electrical _ Electro Mechanical/Air-damper-actuator-.jpg";
import product4 from "../../assets/img/Electrical _ Electro Mechanical/Air-Preparation-Units-.png";
import product5 from "../../assets/img/Electrical _ Electro Mechanical/Air-pressure-boosters.png";
import product6 from "../../assets/img/Electrical _ Electro Mechanical/Analog-Timer-300x300.jpg";
import product7 from "../../assets/img/Electrical _ Electro Mechanical/Angle-seated-valve-.jpg";
import product8 from "../../assets/img/Electrical _ Electro Mechanical/Annunciator-panel-.jpg";
import product9 from "../../assets/img/Electrical _ Electro Mechanical/ASI-Air-Box.jpg";
import product10 from "../../assets/img/Electrical _ Electro Mechanical/ASI-Gateway-.jpg";
import product11 from "../../assets/img/Electrical _ Electro Mechanical/Ball-valve-actuator-.jpg";
import product12 from "../../assets/img/Electrical _ Electro Mechanical/Brake-actuator.jpg";
import product13 from "../../assets/img/Electrical _ Electro Mechanical/Brass-watertight-plug-receptacle.jpg";
import product14 from "../../assets/img/Electrical _ Electro Mechanical/Conductivity-Analyzer-.jpg";
import product15 from "../../assets/img/Electrical _ Electro Mechanical/Control-panel.jpg";
import product16 from "../../assets/img/Electrical _ Electro Mechanical/Control-Valve.jpg";
import product17 from "../../assets/img/Electrical _ Electro Mechanical/Cooling-Fan.jpg";
import product18 from "../../assets/img/Electrical _ Electro Mechanical/Data-logger.jpg";
import product19 from "../../assets/img/Electrical _ Electro Mechanical/Diaphragm-actuator-.jpg";
import product20 from "../../assets/img/Electrical _ Electro Mechanical/Differential-pressure-transmitter.jpg";
import product21 from "../../assets/img/Electrical _ Electro Mechanical/Digital-pressure-gauge-.jpg";
import product22 from "../../assets/img/Electrical _ Electro Mechanical/Dry-Units.jpg";
import product23 from "../../assets/img/Electrical _ Electro Mechanical/-Energy-meter.jpg";
import product24 from "../../assets/img/Electrical _ Electro Mechanical/Fork-Sensors-.jpg";
import product25 from "../../assets/img/Electrical _ Electro Mechanical/FRL-Units.jpg";
import product26 from "../../assets/img/Electrical _ Electro Mechanical/Hydraulic-actuator.jpg";
import product27 from "../../assets/img/Electrical _ Electro Mechanical/Industrial-Coupling.jpg";
import product28 from "../../assets/img/Electrical _ Electro Mechanical/Industrial-fuselinks.jpg";
import product29 from "../../assets/img/Electrical _ Electro Mechanical/Industrial-fuselinks.jpg";
import product30 from "../../assets/img/Electrical _ Electro Mechanical/indutrial-Power-Socket.jpg";
import product31 from "../../assets/img/Electrical _ Electro Mechanical/Insulated-rubber-mat-.jpg";
import product32 from "../../assets/img/Electrical _ Electro Mechanical/Inverter-drive.jpg";
import product33 from "../../assets/img/Electrical _ Electro Mechanical/Isolator-Switch.jpg";
import product34 from "../../assets/img/Electrical _ Electro Mechanical/Level-indicator.jpg";
import product35 from "../../assets/img/Electrical _ Electro Mechanical/Limit-switch.jpg";
import product36 from "../../assets/img/Electrical _ Electro Mechanical/Linear-Actuator.jpg";
import product37 from "../../assets/img/Electrical _ Electro Mechanical/Linear-Bearings-.jpg";
import product38 from "../../assets/img/Electrical _ Electro Mechanical/Linear-Hydraulic-actuator.jpg";
import product39 from "../../assets/img/Electrical _ Electro Mechanical/Load-cell-bar-.jpg";
import product40 from "../../assets/img/Electrical _ Electro Mechanical/Mechanical-flow-meter.jpg";
import product41 from "../../assets/img/Electrical _ Electro Mechanical/Micro-sensor.jpg";
import product42 from "../../assets/img/Electrical _ Electro Mechanical/Mother-card-.jpg";
import product43 from "../../assets/img/Electrical _ Electro Mechanical/Motor-starter-switch.jpg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function ProductsElectroMechanical() {
  

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProductsHeavyHeader />
      <div className="section  ">
        <Container>
          
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p className="heavy-intro">
              Tailer made heavy equipment’s and tools supply based on client needs. Starting from engineering till finished product supply is our commitments 

              </p>
              <br />
              
            </Col>
          </Row>
          <br />
        </Container>
      </div>
      <div className="section heavy-content pt-0 container">
          <div className="heavy-bg" style={{
          backgroundImage:
            "url(" + require("assets/img/bg2-safety.webp").default + ")",
        }}>
            <div className="heavy-heading"><div className="heading-heavy">
          ElectroMechanical</div></div>
            <div className="heavy-product-list">
                <ul>
                <li className="heavy-item">Power transformer</li>
                    <li className="heavy-item">Actuator Valves</li>
                    <li className="heavy-item">Air damper actuator</li>
                    <li className="heavy-item">Air Preparation Units</li>
                    <li className="heavy-item">Air pressure boosters</li>
                    <li className="heavy-item">Analog Timer</li>
                    <li className="heavy-item">Angle seated valve</li>
                    <li className="heavy-item">Annunciator panel</li>
                    <li className="heavy-item">ASI Air Box</li>
                    <li className="heavy-item">ASI Gateway</li>
                    <li className="heavy-item">Ball valve actuator</li>
                    <li className="heavy-item">Brass watertight plug & receptacle</li>
                    <li className="heavy-item">Conductivity Analyzer</li>
                    <li className="heavy-item">Control Panel</li>
                    <li className="heavy-item">Control Valve</li>
                    <li className="heavy-item">Cooling Fan</li>
                    <li className="heavy-item">Data logger</li>
                    <li className="heavy-item">Diaphragm actuator</li>
                    <li className="heavy-item">Differential pressure transmitter</li>
                    <li className="heavy-item">Digital pressure gauge</li>
                    <li className="heavy-item">Digital Timer</li>
                    <li className="heavy-item">Dry Units</li>
                    <li className="heavy-item">Energy meter</li>
                    <li className="heavy-item">Float level switch</li>
                    <li className="heavy-item">Flow controller</li>
                    <li className="heavy-item">Fork Sensors</li>
                    <li className="heavy-item">FRL Units</li>
                    <li className="heavy-item">Hydraulic actuator</li>
                    <li className="heavy-item">Inductive proximity sensor</li>
                    <li className="heavy-item">Industrial Coupling</li>
                    <li className="heavy-item">Industrial fuselinks</li>
                    <li className="heavy-item">Industrial power plug</li>
                    <li className="heavy-item">Indutrial Power Socket</li>
                    <li className="heavy-item">Inverter drive</li>
                    <li className="heavy-item">Isolator Switch</li>
                    <li className="heavy-item">Level indicator</li>
                    <li className="heavy-item">Limit switch</li>
                    <li className="heavy-item">Linear Actuator</li>
                    <li className="heavy-item">Linear Bearings</li>
                    <li className="heavy-item">Linear Hydraulic actuator</li>
                    <li className="heavy-item">Load cell bar</li>
                    <li className="heavy-item">Mechanical flow meter</li>
                    <li className="heavy-item">Micro sensor</li>
                    <li className="heavy-item">Mother card</li>
                    <li className="heavy-item">Motor starter switch</li>

                    
                </ul>
            </div>
          </div>
      </div>

      
      <div className="section mechanical-products heavy-equipment-items">
        <Container>
          <div className="item-grid-container">
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Power Transformer</CardTitle>
                  {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product2} alt="..." />
                <CardBody>
                  <CardTitle>Actuator Valves</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product3} alt="..." />
                <CardBody>
                  <CardTitle>Air damper actuator</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product4} alt="..." />
                <CardBody>
                  <CardTitle>Air Preparation Units</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product5} alt="..." />
                <CardBody>
                  <CardTitle> Air pressure boosters</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product6} alt="..." />
                <CardBody>
                  <CardTitle > Analog Timer</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product7} alt="..." />
                <CardBody>
                  <CardTitle>Angle seated valve</CardTitle>
              
                </CardBody>
              </Card>
         </div>
     
        
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product8} alt="..." />
                <CardBody>
                  <CardTitle> Annunciator panel</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product9} alt="..." />
                <CardBody>
                  <CardTitle> ASI Air Box</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product10} alt="..." />
                <CardBody>
                  <CardTitle>ASI Gateway</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product11} alt="..." />
                <CardBody>
                  <CardTitle> Ball valve actuator</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product12} alt="..." />
                <CardBody>
                  <CardTitle> Brake actuator</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product13} alt="..." />
                <CardBody>
                  <CardTitle> Brass watertight plug & receptacle</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product14} alt="..." />
                <CardBody>
                  <CardTitle> Conductivity Analyzer</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product15} alt="..." />
                <CardBody>
                  <CardTitle> Control panel</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product16} alt="..." />
                <CardBody>
                  <CardTitle> Control Valve</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product17} alt="..." />
                <CardBody>
                  <CardTitle>Cooling Fan</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product18} alt="..." />
                <CardBody>
                  <CardTitle>Data logger</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product19} alt="..." />
                <CardBody>
                  <CardTitle>Diaphragm actuator</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product20} alt="..." />
                <CardBody>
                  <CardTitle>Differential pressure transmitter</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product21} alt="..." />
                <CardBody>
                  <CardTitle>Digital pressure gauge</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product21} alt="..." />
                <CardBody>
                  <CardTitle>Digital Timer</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product22} alt="..." />
                <CardBody>
                  <CardTitle>Dry Units</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product23} alt="..." />
                <CardBody>
                  <CardTitle>Energy meter</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product23} alt="..." />
                <CardBody>
                  <CardTitle>Float level Switch</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product23} alt="..." />
                <CardBody>
                  <CardTitle>Flow controller</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product24} alt="..." />
                <CardBody>
                  <CardTitle>Fork Sensors</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product25} alt="..." />
                <CardBody>
                  <CardTitle>FRL Units</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product26} alt="..." />
                <CardBody>
                  <CardTitle>Hydraulic actuator</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product26} alt="..." />
                <CardBody>
                  <CardTitle>Inductive proximity sensor</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product27} alt="..." />
                <CardBody>
                  <CardTitle>Industrial Coupling</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product28} alt="..." />
                <CardBody>
                  <CardTitle>Industrial fuselinks</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product29} alt="..." />
                <CardBody>
                  <CardTitle>Industrial power plug</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product30} alt="..." />
                <CardBody>
                  <CardTitle>Indutrial Power Socket</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product31} alt="..." />
                <CardBody>
                  <CardTitle>Insulated rubber mat</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product32} alt="..." />
                <CardBody>
                  <CardTitle>Inverter drive</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product33} alt="..." />
                <CardBody>
                  <CardTitle>Isolator Switch</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product34} alt="..." />
                <CardBody>
                  <CardTitle>Level indicator</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product35} alt="..." />
                <CardBody>
                  <CardTitle>Limit switch</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product36} alt="..." />
                <CardBody>
                  <CardTitle>Linear Actuator</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product37} alt="..." />
                <CardBody>
                  <CardTitle>Linear Bearings</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product38} alt="..." />
                <CardBody>
                  <CardTitle>Linear Hydraulic actuator</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product39} alt="..." />
                <CardBody>
                  <CardTitle>Load cell bar</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product40} alt="..." />
                <CardBody>
                  <CardTitle>Mechanical flow meter</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product41} alt="..." />
                <CardBody>
                  <CardTitle>Micro sensor</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product42} alt="..." />
                <CardBody>
                  <CardTitle>Mother card</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product43} alt="..." />
                <CardBody>
                  <CardTitle>Motor starter switch</CardTitle>
              
                </CardBody>
              </Card>
         </div>
          </div>
        </Container>
      </div>

     
      <DemoFooter />
    </>
  );
}

export default ProductsElectroMechanical;

import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import React, { Component} from 'react';

const mapStyles = {
    width: '400px',
    height: '400px',
  };
  
export class MapContainer extends Component {
    
render() {
  return (
    <Map
    google={this.props.google}
    zoom={8}
    style={mapStyles}
    initialCenter={{ lat: 26.706322955930446, lng: -80.05018794221877}}
    
  >
    <Marker position={{ lat: 26.706322955930446, lng: -80.05018794221877}} />
  </Map>
 );
 }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCYjI4y5DFLMf03F2-vy_U97EgSIYuZW9Y'
})(MapContainer)
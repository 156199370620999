/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import './DemoFooter.scss'
import logo from '../../assets/img/logo.webp';

// reactstrap components
import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white ">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul className="">
              <li className="d-inline-block  text-left">
                <div className="">
                <a className="footer-titles"
                  href=""
                  target="_self"
                >
                  PRODUCTS
                </a>
                <a
                  href="/products-electrical"
                  target="_self"
                >
                   ELECTRICAL
                </a>
                <a
                  href="/products-mechanical"
                  target="_self"
                >
                MECHANICAL
                </a>
                <a
                  href="/products-pneumatic"
                  target="_self"
                >
                 PNEUMATIC
                </a>
                <a
                  href="/products-instrument"
                  target="_self"
                >
                 INSTRUMENTATION
                </a>
                <a
                  href="/products-safety"
                  target="_self"
                >
                 SAFETY
                </a>
                <a
                  href="/products-heavy"
                  target="_self"
                >
                 HEAVY EQUIPMENTS
                </a>
              
                </div>
                <div className=""></div>

                </li>
              <li className="d-inline-block  text-left">
                <a className="footer-titles"
                  href="/home"
                  target="_self"
                >
                  SERVICES
                </a>
              </li>
             
              <li className="footer-contact-us d-inline-block text-left ">
                <div className="d-flex flex-column"> 
                <a className="footer-titles" href="/contact"
                  target="_self">CONTACT US</a>
                <p>sales@leistungcorp.com <br/>
                +1 713-999-9099 </p>
              </div>
               

              </li>
              <li className="footer-logo d-inline-block text-left float-end">  <img className="navbar-logo" src ={logo} style={{width:"50px",height:"50px"}} /> </li>
            
            </ul>
          </nav>
          <div className="credits ml-auto d-none">
            <span className="copyright">
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" /> by Creative Tim
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;

/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProductsHeavyHeader from "components/Headers/ProductsHeavyHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "views/examples/ProductsHeavy.scss";

import {
  Card,
  CardBody,
  CardTitle,
  CardImg,
} from "reactstrap";

import product1 from "../../assets/img/heavy-hand-tools-1.webp";
import product2 from "../../assets/img/heavy-p-gen-1.webp";
import product3 from "../../assets/img/heavy-grounding-1.webp";
import product4 from "../../assets/img/heavy-cable-handling-1.webp";
import transformer1 from "../../assets/img/heavy-transformers/Power Transformers.jpg";
import transformer2 from "../../assets/img/heavy-transformers/Cast Resin Transformer.jpg";
import transformer3 from "../../assets/img/heavy-transformers/Vaccum Impregnated Transformer (VPI).jpg";
import transformer4 from "../../assets/img/heavy-transformers/Inverter  Solar Transformer.jpg";
import transformer5 from "../../assets/img/heavy-transformers/Converter Duty or Drive Duty Transformer.jpg";
import transformer6 from "../../assets/img/heavy-transformers/Furnace Transformer.jpg";
import transformer7 from "../../assets/img/heavy-transformers/Earthing or Grounding Transformer.jpg";
import transformer8 from "../../assets/img/heavy-transformers/Auto Transformer.jpg";
import transformer9 from "../../assets/img/heavy-transformers/Pad Mounted Transformer.jpg";
import transformer10 from "../../assets/img/heavy-transformers/Scott T Transformer.jpg";
import transformer11 from "../../assets/img/heavy-transformers/Compact Substation.jpg";



import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function ProductsHeavy() {
  

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProductsHeavyHeader />
      <div className="section  ">
        <Container>
          
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p className="heavy-intro">
              Tailer made heavy equipment’s and tools supply based on client needs. Starting from engineering till finished product supply is our commitments 

              </p>
              <br />
              
            </Col>
          </Row>
          <br />
        </Container>
      </div>
      <div className="section heavy-content pt-0 container">
          <div className="heavy-bg" style={{
          backgroundImage:
            "url(" + require("assets/img/bg2-safety.webp").default + ")",
        }}>
            <div className="heavy-heading"><div className="heading-heavy">
           Heavy Equipments and Tools</div></div>
            <div className="heavy-product-list">
                <ul>
                <li className="heavy-item">LV Rated Hand Tools</li>
                    <li className="heavy-item">Power Generators</li>
                    <li className="heavy-item">Grounding Devices</li>
                    <li className="heavy-item">Cable Handling Equipments</li>
                    <li className="heavy-item">Transformers</li>
                  
                    
                </ul>
            </div>
          </div>
      </div>

      <div className="section mechanical-products">
        <Container className="d-none">
          <Row>
            <Carousel
              responsive={responsive}
              containerClass="carousel-container"
              itemClass="carousel-item"
              swipeable={false}
              draggable={true}
            >
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>LV Rated Hand Tools</CardTitle>
                  {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
                </CardBody>
              </Card>
              <Card >
                <CardImg top src={product2} alt="..." />
                <CardBody>
                  <CardTitle>Power Generators</CardTitle>
                  {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
                </CardBody>
              </Card>
              <Card >
                <CardImg top src={product3} alt="..." />
                <CardBody>
                  <CardTitle>Grounding Devices</CardTitle>
                  {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
                </CardBody>
              </Card>
             
              <Card >
                <CardImg top src={product4} alt="..." />
                <CardBody>
                  <CardTitle>Cable Handling Equipments</CardTitle>
                  {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
                </CardBody>
              </Card>
            </Carousel>
            ;
          </Row>
          <br />
        </Container>
      </div>
      <div className="section mechanical-products heavy-equipment-items">
        <Container>
          <div className="item-grid-container">
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={transformer1} alt="..." />
                <CardBody>
                  <CardTitle>Power Transformer</CardTitle>
                  {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={transformer2} alt="..." />
                <CardBody>
                  <CardTitle>Cast Resin Transformer</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={transformer3} alt="..." />
                <CardBody>
                  <CardTitle>Vaccum Impregnated Transformer (VPI)</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={transformer4} alt="..." />
                <CardBody>
                  <CardTitle> Inverter / Solar Transformer</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={transformer5} alt="..." />
                <CardBody>
                  <CardTitle> Converter Duty / Drive Duty Transformer</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={transformer6} alt="..." />
                <CardBody>
                  <CardTitle > Furnace Transformer</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={transformer7} alt="..." />
                <CardBody>
                  <CardTitle>Earthing / Grounding Transformer</CardTitle>
              
                </CardBody>
              </Card>
         </div>
     
        
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={transformer8} alt="..." />
                <CardBody>
                  <CardTitle> Auto Transformer</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={transformer9} alt="..." />
                <CardBody>
                  <CardTitle> Pad Mounted Transformer</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={transformer10} alt="..." />
                <CardBody>
                  <CardTitle> Scott T Transformer</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={transformer11} alt="..." />
                <CardBody>
                  <CardTitle> Compact Substation</CardTitle>
              
                </CardBody>
              </Card>
         </div>
         <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle> Distribution Transformer</CardTitle>
              
                </CardBody>
              </Card>
         </div>
          </div>
        </Container>
      </div>

     
      <DemoFooter />
    </>
  );
}

export default ProductsHeavy;


import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProductsHeavyHeader from "components/Headers/ProductsHeavyHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "views/examples/ProductsHeavy.scss";

import {
  Card,
  CardBody,
  CardTitle,
  CardImg,
} from "reactstrap";

import product1 from "../../assets/img/oil and gas/valve/Gate-Valve.jpg";
import product2 from "../../assets/img/oil and gas/valve/Ball-valve.jpg";
import product3 from "../../assets/img/oil and gas/valve/butterfly-valve.jpg";
import product4 from "../../assets/img/oil and gas/valve/Diaphragm-Valve.jpg";
import product5 from "../../assets/img/oil and gas/valve/Flush-bottom-valve.jpg";
import product6 from "../../assets/img/oil and gas/valve/Flush-bottom-valve.jpg";
import product7 from "../../assets/img/oil and gas/valve/Y-Type-Gate-Valve.jpg";
import product8 from "../../assets/img/oil and gas/valve/Piston-Valve.jpg";
import product9 from "../../assets/img/oil and gas/valve/Ball-valve-flanged-type.jpg";
import product10 from "../../assets/img/oil and gas/valve/Plug-valve.jpg";
import product11 from "../../assets/img/oil and gas/valve/Dairy-Valve.jpg";
import product12 from "../../assets/img/oil and gas/valve/Back-pressure-regulating-valve.jpg";
import product13 from "../../assets/img/oil and gas/valve/Flow-Indicator-Valve.jpg";
import product14 from "../../assets/img/oil and gas/valve/SS-Swing-Check-Valves.jpg";
import product15 from "../../assets/img/oil and gas/valve/HDPE-ball-valve.jpg";
import product16 from "../../assets/img/oil and gas/valve/Inline-Sight-Glass.jpg";
import product18 from "../../assets/img/oil and gas/hoses/-Rubber-Hose-.jpg";
import product19 from "../../assets/img/oil and gas/hoses/Dry-Cement-Rubber-Hose.jpg";
import product20 from "../../assets/img/oil and gas/hoses/Clear-PVC-water-suction-hose.jpg";
import product21 from "../../assets/img/oil and gas/hoses/Clear-PVC-water-suction-hose.jpg";
import product22 from "../../assets/img/oil and gas/hoses/Hydraulic-hoses-.jpg";
import product23 from "../../assets/img/oil and gas/hoses/Metal-Hose-.jpg";
import product24 from "../../assets/img/oil and gas/hoses/Hose-clamps-.jpg";
import product25 from "../../assets/img/oil and gas/hoses/Hose-Fittings-Accessories.jpg";


import "react-multi-carousel/lib/styles.css";


function ProductsOil() {
  

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProductsHeavyHeader />
      <div className="section  ">
        <Container>
          
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p className="heavy-intro">
             
              </p>
              <br />
              
            </Col>
          </Row>
          <br />
        </Container>
      </div>
      <div className="section heavy-content pt-0 container">
          <div className="heavy-bg" style={{
          backgroundImage:
            "url(" + require("assets/img/bg2-safety.webp").default + ")",
        }}>
            <div className="heavy-heading"><div className="heading-heavy">
          Hydraulics</div></div>
            <div className="heavy-product-list">
                <ul>
                <li className="heavy-item">Oil and Gas

</li>
                    <li className="heavy-item">Valves
</li>
                    <li className="heavy-item">Pipe and Fittings</li>
                    <li className="heavy-item">Industrials Hoses and fittings</li>
                  
                    
                </ul>
            </div>
          </div>
      </div>

      
      <div className="section mechanical-products heavy-equipment-items">
     
        <Container>
        <div className="heading-electrical-main"> Valves
</div>
<Container className="no-padding">
         
         <div className="item-grid-container">
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product1} alt="..." />
               <CardBody>
                 <CardTitle>Gate Valve </CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product2} alt="..." />
               <CardBody>
                 <CardTitle>Ball Valve </CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product3} alt="..." />
               <CardBody>
                 <CardTitle>Butterfly Valve</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product4} alt="..." />
               <CardBody>
                 <CardTitle>Diaphragm Valve</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product5} alt="..." />
               <CardBody>
                 <CardTitle>Flush Bottom Valve</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product6} alt="..." />
               <CardBody>
                 <CardTitle>Pnematic gate </CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product7} alt="..." />
               <CardBody>
                 <CardTitle>Y Type Gate Valve</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product8} alt="..." />
               <CardBody>
                 <CardTitle>Piston Valve</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product9} alt="..." />
               <CardBody>
                 <CardTitle>Ball valve flanged type</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product10} alt="..." />
               <CardBody>
                 <CardTitle>Plug valve</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product11} alt="..." />
               <CardBody>
                 <CardTitle>Dairy Valve</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product12} alt="..." />
               <CardBody>
                 <CardTitle>Back pressure regulating valve</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product13} alt="..." />
               <CardBody>
                 <CardTitle>low Indicator Valve</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product14} alt="..." />
               <CardBody>
                 <CardTitle>SS Swing Check Valves</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product15} alt="..." />
               <CardBody>
                 <CardTitle>HDPE ball valve</CardTitle>
               
               </CardBody>
             </Card>
           </div>

           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product16} alt="..." />
               <CardBody>
                 <CardTitle>Inline Sight Glass</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           
          
         </div>
       </Container>
     
        </Container>

        <Container>
        <div className="heading-electrical-main"> Pipe and Fittings
</div>
<Container className="no-padding">
         
         <div className="item-grid-container">
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product4} alt="..." />
               <CardBody>
                 <CardTitle>CS Pipes</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product5} alt="..." />
               <CardBody>
                 <CardTitle>Copper pipe</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product6} alt="..." />
               <CardBody>
                 <CardTitle>Copper tube</CardTitle>
               
               </CardBody>
             </Card>
           </div>
          
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product7} alt="..." />
               <CardBody>
                 <CardTitle>SS Tubes</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product8} alt="..." />
               <CardBody>
                 <CardTitle>Butweld fittings</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product9} alt="..." />
               <CardBody>
                 <CardTitle>Blind flange</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product10} alt="..." />
               <CardBody>
                 <CardTitle>Threaded Flange</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product11} alt="..." />
               <CardBody>
                 <CardTitle>Slip-on flange</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product12} alt="..." />
               <CardBody>
                 <CardTitle>Socket weld flange</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product12} alt="..." />
               <CardBody>
                 <CardTitle>Expansion Joint</CardTitle>
               
               </CardBody>
             </Card>
           </div>
          
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product12} alt="..." />
               <CardBody>
                 <CardTitle>Y-Strainer</CardTitle>
               
               </CardBody>
             </Card>
           </div>
          
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product12} alt="..." />
               <CardBody>
                 <CardTitle>Lateral-Tees</CardTitle>
               
               </CardBody>
             </Card>
           </div>
          
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product12} alt="..." />
               <CardBody>
                 <CardTitle>HDPE pipe coupling</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product12} alt="..." />
               <CardBody>
                 <CardTitle>          
</CardTitle>
               
               </CardBody>
             </Card>
           </div>
          
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product12} alt="..." />
               <CardBody>
                 <CardTitle>Dismantling Joint</CardTitle>
               
               </CardBody>
             </Card>
           </div>
          
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product12} alt="..." />
               <CardBody>
                 <CardTitle>Ductile Iron Coupling</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product12} alt="..." />
               <CardBody>
                 <CardTitle>Flange adaptor</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product12} alt="..." />
               <CardBody>
                 <CardTitle>Pipe repair clamps</CardTitle>
               
               </CardBody>
             </Card>
           </div>
          
          


         </div>
       </Container>
     
        </Container>

        <Container>
        <div className="heading-electrical-main"> Industrial Hoses and fitting
</div>
<Container className="no-padding">
         
         <div className="item-grid-container">
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product18} alt="..." />
               <CardBody>
                 <CardTitle>Rubber Hose</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product19} alt="..." />
               <CardBody>
                 <CardTitle>Dry Cement Rubber Hose</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product20} alt="..." />
               <CardBody>
                 <CardTitle>PVC water suction hose</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product21} alt="..." />
               <CardBody>
                 <CardTitle>Clear PVC water suction hose</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product22} alt="..." />
               <CardBody>
                 <CardTitle>Hydraulic hoses</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product23} alt="..." />
               <CardBody>
                 <CardTitle>    Metal Hose      
</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product24} alt="..." />
               <CardBody>
                 <CardTitle> Hose clamps</CardTitle>
               
               </CardBody>
             </Card>
           </div>
        
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product25} alt="..." />
               <CardBody>
                 <CardTitle> Hose Fittings & Accessories</CardTitle>
               
               </CardBody>
             </Card>
           </div>
      
      
          
         </div>
       </Container>
     
        </Container>
      </div>

     
      <DemoFooter />
    </>
  );
}

export default ProductsOil;

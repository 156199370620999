/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProductsPneumaticsHeader from "components/Headers/ProductsPneumaticsHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "views/examples/ProductsPneumatics.scss";

import {
  Card,
  CardBody,
  CardTitle,
  CardImg
} from "reactstrap";

import product1 from '../../assets/img/Pneumatic cylinders/Pneumatic tie-rod medium-duty cylinders – R series.png';
import product2 from '../../assets/img/Pneumatic cylinders/pneumatic mill duty cylinders EM 02 series.png';
import product3 from '../../assets/img/Pneumatic cylinders/Pneumatic threaded cylinders - T series.png';
import product4 from '../../assets/img//Pneumatic cylinders/Tie-rod light-duty cylinders – L series.png';
import product5 from '../../assets/img/pn-hvac-1.webp';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


function ProductsPneumatics() {
  

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProductsPneumaticsHeader />
      <div className="section  ">
        <Container>
          
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p className="pneumatic-intro">
              Emerging world in needs of pneumatic instrumentation and we provide wide range of sensors , switches and control elements 
              </p>
              <br />
              
            </Col>
          </Row>
          <br />
        </Container>
      </div>
      <div className="section container pneumatic-content pt-0">
          <div className="pneumatic-bg" style={{
          backgroundImage:
            "url(" + require("assets/img/bg2-pneumatic.webp").default + ")",
        }}>
            <div className="pneumatic-heading"><div className="heading-pneumatic">
            Pneumatic</div></div>
            <div className="pneumatic-product-list">
                <ul>
                    <li className="pneumatic-item">Pneumatic cylinders​
</li>
                    
                </ul>
            </div>
          </div>
      </div>
    
      <div className="section mechanical-products electrical-items">
        <Container>
          <div className="heading-electrical-main">Pneumatic cylinders​</div>

          <Container className="no-padding">
         
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Pneumatic tie-rod medium-duty cylinders – R series</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product2} alt="..." />
                  <CardBody>
                    <CardTitle>Pneumatic mill-duty cylinders - EM 02 series</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product3} alt="..." />
                  <CardBody>
                    <CardTitle>Pneumatic threaded cylinders - T series</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product4} alt="..." />
                  <CardBody>
                    <CardTitle>Tie-rod light-duty cylinders – L series</CardTitle>

                  </CardBody>
                </Card>
              </div>

            </div>
          </Container>
      
     
        </Container>


      </div>

      
      <DemoFooter />
    </>
  );
}

export default ProductsPneumatics;

/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import {
  Card,
  CardBody,
  CardTitle,
 CardImg
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProductsMechanicalHeader from "components/Headers/ProductsMechanicalHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "views/examples/ProductsMechanical.scss";
import product1 from '../../assets/img/Precision Planetary Gearboxes _ Gearmotors/TQ Series.webp';
import product2 from '../../assets/img/Precision Planetary Gearboxes _ Gearmotors/MP.webp';
import product3 from '../../assets/img/Precision Planetary Gearboxes _ Gearmotors/TQF Series.webp';
import product4 from '../../assets/img/Precision Planetary Gearboxes _ Gearmotors/TQW.webp';
import product5 from '../../assets/img/Precision Planetary Gearboxes _ Gearmotors/TR.webp';
import product6 from '../../assets/img/Precision Planetary Gearboxes _ Gearmotors/TQK.webp';
import product7 from '../../assets/img/Precision Planetary Gearboxes _ Gearmotors/SL Series.webp';
import product8 from '../../assets/img/Precision Planetary Gearboxes _ Gearmotors/LCK Series.webp';
import product9 from '../../assets/img/Precision Planetary Gearboxes _ Gearmotors/TQFEK_1.webp';
import product10 from '../../assets/img/Precision Planetary Gearboxes _ Gearmotors/MPEK_1.webp';
import product11 from '../../assets/img/Precision Planetary Gearboxes _ Gearmotors/BMS Series_1.webp';

import product12 from '../../assets/img/instrument fitting/2-Valve-Manifold-300x300.jpg';
import product13 from '../../assets/img/instrument fitting/ball-valve-.jpg';
import product14 from '../../assets/img/instrument fitting/Double-Block-Bleed-Valves-.jpg';
import product15 from '../../assets/img/instrument fitting/Instumentation-valve-.jpg';
import product16 from '../../assets/img/instrument fitting/Needle-Valve.jpg';
import product17 from '../../assets/img/instrument fitting/Monoflange-Valve-.jpg';
import product18 from '../../assets/img/instrument fitting/Flush-Ring-.jpg';
import product19 from '../../assets/img/instrument fitting/Pnuematic-flow-control-valve-.jpg';
import product20 from '../../assets/img/instrument fitting/Pneumatic-gripper-.jpg';
import product21 from '../../assets/img/instrument fitting/Pmeumatic-Knocker-.jpg';
import product22 from '../../assets/img/instrument fitting/Pneumatic-fittings-.jpg';
import product23 from '../../assets/img/instrument fitting/Air-Headers-.jpg';
import product24 from '../../assets/img/instrument fitting/Union-Tee-.jpg';
import product25 from '../../assets/img/instrument fitting/BulkHead-Union-.jpg';
import product26 from '../../assets/img/instrument fitting/Reducing-Union-.jpg';
import product27 from '../../assets/img/instrument fitting/Male-Connector-NPT-.jpg';
import product28 from '../../assets/img/instrument fitting/Back-Ferrule-.jpg';
import product29 from '../../assets/img/instrument fitting/Pressure-Temperature-Guages-.jpg';

import product30 from '../../assets/img/metal products/Anchor-Bolts-.jpg';
import product31 from '../../assets/img/metal products/Bonnet-bolt-.jpg';
import product32 from '../../assets/img/metal products/Collar-bolt-.jpg';
import product33 from '../../assets/img/metal products/Wedge-Anchor-.jpg';
import product34 from '../../assets/img/metal products/SS-Stud-bolt-.jpg';
import product35 from '../../assets/img/metal products/SS-Flat-Bars-.jpg';
import product36 from '../../assets/img/metal products/SS-Round-bars-.jpg';
import product37 from '../../assets/img/metal products/ss plate.jpg';
import product38 from '../../assets/img/metal products/Galv.-steel-plates-.jpg';
import product39 from '../../assets/img/metal products/HDG-Gratings-.jpg';
import product40 from '../../assets/img/metal products/FRP-grating-.jpg';

import product41 from '../../assets/img/Pump and motor/Electric-Motor.jpg';
import product42 from '../../assets/img/Pump and motor/Chemical-pump.png';
import product43 from '../../assets/img/Pump and motor/Water-pump.jpg';
import product44 from '../../assets/img/Pump and motor/Centrifugal-vertical-pump-600x600.jpg';
import product45 from '../../assets/img/Pump and motor/Chopper-Pump.jpg';
import product46 from '../../assets/img/Pump and motor/Slurry-pump.jpg';
import product47 from '../../assets/img/Pump and motor/Helical-Rotor-Pump.jpg';
import product48 from '../../assets/img/Pump and motor/Vibration-pump.jpg';
import product49 from '../../assets/img/Pump and motor/End-Suction-Fire-Pump.jpg';
import product50 from '../../assets/img/Pump and motor/Double-diaphram-pump.jpg';
import product51 from '../../assets/img/Pump and motor/Vibration-pump.jpg';

import product52 from '../../assets/img/power transmission product/bearings/ballbearings_.webp';
import product53 from '../../assets/img/power transmission product/bearings/cylindricalbearings_.jpg';
import product54 from '../../assets/img/power transmission product/bearings/filamentbearings_thumbnail_1.jpg';
import product55 from '../../assets/img/power transmission product/bearings/sleevebearings_thumbnail.webp';
import product56 from '../../assets/img/power transmission product/bearings/sphericalrollerbearings_thumbnail.jpg';
import product57 from '../../assets/img/power transmission product/bearings/bearingspartskits_thumbnail.webp';

import product58 from '../../assets/img/power transmission product/CONVEYING SOLUTIONS/kleantopconveyorbelts_thumbanil.webp';
import product59 from '../../assets/img/power transmission product/CONVEYING SOLUTIONS/mattopandtabletopchain_thumbanil.jpg';
import product60 from '../../assets/img/power transmission product/CONVEYING SOLUTIONS/metalconveyorbelts_thumbanil.jpg';
import product61 from '../../assets/img/power transmission product/CONVEYING SOLUTIONS/Turn_Spiral_Cage_Belts.jpg';
import product62 from '../../assets/img/power transmission product/CONVEYING SOLUTIONS/conveyingcomponents_thumbanil.webp';
import product63 from '../../assets/img/power transmission product/CONVEYING SOLUTIONS/conveyorsprockets_thumbanil.jpg';

import product64 from '../../assets/img/power transmission product/COUPLINGS/disccouplings_thumbnail.webp';
import product65 from '../../assets/img/power transmission product/COUPLINGS/elastometriccoupling_thumbnail.webp';
import product66 from '../../assets/img/power transmission product/COUPLINGS/fluidcoupling_thumbnail.webp';
import product67 from '../../assets/img/power transmission product/COUPLINGS/gearcoupling_thumbnail.jpg';
import product68 from '../../assets/img/power transmission product/COUPLINGS/gridcoupling_thumbnail.jpg';
import product69 from '../../assets/img/power transmission product/COUPLINGS/rigidcouplings_thumbnail.webp';
import product70 from '../../assets/img/power transmission product/COUPLINGS/torsionallysoftcouplings_thumbnail.webp';
import product71 from '../../assets/img/power transmission product/COUPLINGS/torsionallystiffcouplings_thumbnail.webp';

import product72 from '../../assets/img/power transmission product/GEAR/concentricinlinegear_thumbnail.webp';
import product73 from '../../assets/img/power transmission product/GEAR/parallelgear_thumbnail.webp';
import product74 from '../../assets/img/power transmission product/GEAR/rightgear_thumbnail.webp';
import product75 from '../../assets/img/power transmission product/GEAR/shaftt mount gear_thumbnail.webp';
import product76 from '../../assets/img/power transmission product/GEAR/gearcomponents_thumbnail.webp';

import product77 from '../../assets/img/power transmission product/INDUSTRIAL CHAIN/rollerchain_thumbnail.webp';
import product78 from '../../assets/img/power transmission product/INDUSTRIAL CHAIN/leafchain_thumbnail.webp';
import product79 from '../../assets/img/power transmission product/INDUSTRIAL CHAIN/engineeredsteelchain_thumbnail.jpg';
import product80 from '../../assets/img/power transmission product/INDUSTRIAL CHAIN/dropforgedchain_thumbnail.webp';
import product81 from '../../assets/img/power transmission product/INDUSTRIAL CHAIN/silent chain.webp';
import product82 from '../../assets/img/power transmission product/INDUSTRIAL CHAIN/idler.jpg';
import product83 from '../../assets/img/power transmission product/INDUSTRIAL CHAIN/Sprocket-Traction-Wheel.jpg';
import product84 from '../../assets/img/power transmission product/INDUSTRIAL CHAIN/Chain-Accessories.webp';
import product85 from '../../assets/img/power transmission product/INDUSTRIAL CHAIN/Conveyor-Components.webp';

import product86 from '../../assets/img/power transmission product/PT DRIVE COMPONENTS/backstops_.jpg';
import product87 from '../../assets/img/power transmission product/PT DRIVE COMPONENTS/brakes_.webp';
import product88 from '../../assets/img/power transmission product/PT DRIVE COMPONENTS/clutches_l.jpg';
import product89 from '../../assets/img/power transmission product/PT DRIVE COMPONENTS/locking and clamping.webp';
import product90 from '../../assets/img/power transmission product/PT DRIVE COMPONENTS/solidstatemotorswitches.webp';
import product91 from '../../assets/img/power transmission product/PT DRIVE COMPONENTS/torquelimiters.webp';

import product92 from '../../assets/img/industrial gear motor/Ran Series_.webp';
import product93 from '../../assets/img/industrial gear motor/VF-W.webp';
import product94 from '../../assets/img/industrial gear motor/A Series.webp';
import product95 from '../../assets/img/industrial gear motor/AS Series_1.webp';
import product96 from '../../assets/img/industrial gear motor/C Series.webp';
import product97 from '../../assets/img/industrial gear motor/evox_cp.webp';
import product98 from '../../assets/img/industrial gear motor/S Series.webp';
import product99 from '../../assets/img/industrial gear motor/F Series.webp';
import product100 from '../../assets/img/industrial gear motor/TA Series_1.webp';


import product101 from '../../assets/img/travel drive/600 Series.webp';
import product102 from '../../assets/img/travel drive/600WE Series.webp';
import product103 from '../../assets/img/travel drive/600WT Series_1.webp';
import product104 from '../../assets/img/travel drive/600Y -.webp';
import product105 from '../../assets/img/travel drive/700CK Series.webp';
import product106 from '../../assets/img/travel drive/700XT Series.webp';
import product107 from '../../assets/img/travel drive/700CE_1.webp';
import product108 from '../../assets/img/travel drive/700CT Series_1.webp';

import product109 from '../../assets/img/Transit mixer drive/500 Series- Hydraulic solution.webp';
import product110 from '../../assets/img/Transit mixer drive/500 Series- Electric solution.webp';

import product111 from '../../assets/img/Industrial Heavy-Duty Geared products/300M Series.webp';
import product112 from '../../assets/img/Industrial Heavy-Duty Geared products/3 by H Series.webp';
import product113 from '../../assets/img/Industrial Heavy-Duty Geared products/HDO Series.webp';

import product114 from '../../assets/img/Vibratory Feeders and Conveyors/LightDutyAndMediumDutyFeeders.jpg';
import product115 from '../../assets/img/Vibratory Feeders and Conveyors/HeavyDutyFeeders.jpg';
import product116 from '../../assets/img/Vibratory Feeders and Conveyors/vibratory Conveyors.jpg';
import product117 from '../../assets/img/Vibratory Feeders and Conveyors/vibratory screeners.jpg';
import product118 from '../../assets/img/Vibratory Feeders and Conveyors/vibratory control.jpg';
import product119 from '../../assets/img/Vibratory Feeders and Conveyors/Bin_Vibrators.jpg';


import product120 from '../../assets/img/Material Handling Equipment/Lifting Magnets.jpg';
import product121 from '../../assets/img/Material Handling Equipment/Magnetic Chip and Parts Conveyors.jpg';
import product122 from '../../assets/img/Material Handling Equipment/FinishmillUnloader-029.jpg';
import product123 from '../../assets/img/Material Handling Equipment/Magna-Rails and Rolls.jpg';
import product124 from '../../assets/img/Material Handling Equipment/Magnetic Palletizer Depalletizer.jpg';
import product125 from '../../assets/img/Material Handling Equipment/Sheet Fanners.jpg';

import product126 from '../../assets/img/Size Reduction and Laboratory Equipment/Crushing_RollsCrusher.jpg';
import product127 from '../../assets/img/Size Reduction and Laboratory Equipment/Pulverizers.jpg';
import product128 from '../../assets/img/Size Reduction and Laboratory Equipment/Disintegrators.jpg';
import product129 from '../../assets/img/Size Reduction and Laboratory Equipment/HammerMills.jpg';
import product130 from '../../assets/img/Size Reduction and Laboratory Equipment/BarrelMill.jpg';
import product131 from '../../assets/img/Size Reduction and Laboratory Equipment/RodandBallMill.jpg';
import product132 from '../../assets/img/Size Reduction and Laboratory Equipment/FingerCrushers.jpg';
import product133 from '../../assets/img/Size Reduction and Laboratory Equipment/TumbleMixer.jpg';
import product134 from '../../assets/img/Size Reduction and Laboratory Equipment/DrumMixer.jpg';
import product135 from '../../assets/img/Size Reduction and Laboratory Equipment/CascadeSplitters.jpg';
import product136 from '../../assets/img/Size Reduction and Laboratory Equipment/JonesRifflers.jpg';
import product137 from '../../assets/img/Size Reduction and Laboratory Equipment/ConeBlender.jpg';
import product138 from '../../assets/img/Size Reduction and Laboratory Equipment/V-Blender.jpg';
import product139 from '../../assets/img/Size Reduction and Laboratory Equipment/TubeDivider.jpg';
import product140 from '../../assets/img/Size Reduction and Laboratory Equipment/FilterPresses.jpg';

import product141 from '../../assets/img/rotary equipment/Hydraulic actuator.webp';
import product142 from '../../assets/img/rotary equipment/Pneumatic wing and tail actuators.jpg';
import product143 from '../../assets/img/rotary equipment/axial fan.jpeg';
import product144 from '../../assets/img/rotary equipment/coupling.jpeg';
import product145 from '../../assets/img/rotary equipment/Fan Blades.jpeg';
import product146 from '../../assets/img/rotary equipment/Hubs.jpeg';

import product147 from '../../assets/img/portable cutting and machine tools/Flange-facer-FI14M-1.webp';
import product148 from '../../assets/img/portable cutting and machine tools/Pipe Cold cutting and bewelling machine.jpg';
import product149 from '../../assets/img/portable cutting and machine tools/Stationary Pipe Cutting _ Beveling Machine.webp';
import product150 from '../../assets/img/portable cutting and machine tools/pipe bevellling machine.webp';
import product151 from '../../assets/img/portable cutting and machine tools/Tube Facing Machine Orbital.webp';
import product152 from '../../assets/img/portable cutting and machine tools/Orbital cutting machine.webp';
import product153 from '../../assets/img/portable cutting and machine tools/Pipe to pipe Orbital Welding Machine.webp';
import product154 from '../../assets/img/portable cutting and machine tools/Plate Edge Milling Machine _ Slag Remove Machine.webp';

import product155 from '../../assets/img/clutches and brakes/Commercial vehicle clutches/Automated ECA Clutch.jpg';
import product156 from '../../assets/img/clutches and brakes/Commercial vehicle clutches/Automated series clutch.webp';
import product157 from '../../assets/img/clutches and brakes/Commercial vehicle clutches/advantage-self-adjust-clutch.jpg';
import product158 from '../../assets/img/clutches and brakes/Commercial vehicle clutches/clutch-install-kit.jpg';
import product159 from '../../assets/img/clutches and brakes/Commercial vehicle clutches/Concentric Pneumatic Clutch Actuator.jpg';
import product160 from '../../assets/img/clutches and brakes/Commercial vehicle clutches/Diaphragm Spring Clutch.jpg';
import product161 from '../../assets/img/clutches and brakes/Commercial vehicle clutches/Easy Pedal Advantage clutch.jpg';
import product162 from '../../assets/img/clutches and brakes/Commercial vehicle clutches/EverTough Manual Adjust Clutch.jpg';
import product163 from '../../assets/img/clutches and brakes/Commercial vehicle clutches/EverTough self-adjust clutch.jpg';
import product164 from '../../assets/img/clutches and brakes/Commercial vehicle clutches/UltraShift DM Clutch.jpg';
import product165 from '../../assets/img/clutches and brakes/DC magnetic operated shoe brakes.jpg';
import product166 from '../../assets/img/clutches and brakes/Industrial duty solenoid operated shoe brakes.jpg';

import product167 from '../../assets/img/Engine valves and valvetrain/Deactivating Hydraulic Lash Adjuster.jpg';
import product168 from '../../assets/img/Engine valves and valvetrain/Diesel variable valve actuation.jpg';
import product169 from '../../assets/img/Engine valves and valvetrain/Engine Brake.png';
import product170 from '../../assets/img/Engine valves and valvetrain/Engine valves.jpg';
import product171 from '../../assets/img/Engine valves and valvetrain/Hydraulic lash adjusters.png';
import product172 from '../../assets/img/Engine valves and valvetrain/Rocker arms.png';
import product173 from '../../assets/img/Engine valves and valvetrain/Switching Roller Finger Follower.png';

import product174 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/Air-to-air refueling probes.jpg';
import product175 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/Aircraft fuel tanks.jpg';
import product176 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/boom-nozzle.jpg';
import product177 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/Buddy refueling pods.jpg';
import product178 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/Composite space pressure vessel.jpg';
import product179 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/Flexible coupling.jpg';
import product180 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/Fuel flow meters.jpg';
import product181 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/Fuselage refueling units.jpg';
import product182 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/MA-2.jpg';
import product183 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/MA-3 aerial refueling coupling.jpg';
import product184 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/MA-4 aerial refueling coupling.jpg';
import product185 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/MXU multi-platform aircraft cargo pod.jpg';
import product186 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/Refueling drogues.jpg';
import product187 from '../../assets/img/Fuel systems, emissions _ components/Aerial refueling/Wing air refueling pods.jpg';
import product188 from '../../assets/img/Fuel systems, emissions _ components/Fuel inerting/Air separation module.jpg';
import product189 from '../../assets/img/Fuel systems, emissions _ components/Fuel inerting/Enclosed electronics inerting systems.jpg';
import product190 from '../../assets/img/Fuel systems, emissions _ components/Fuel inerting/inerting-controller.jpg';
import product191 from '../../assets/img/Fuel systems, emissions _ components/Fuel inerting/NGS oxygen sensor.jpg';
import product192 from '../../assets/img/Fuel systems, emissions _ components/Fuel inerting/On-board inert gas generating system.jpg';
import product193 from '../../assets/img/Fuel systems, emissions _ components/Fuel measurement and management/digital-capacitance-probes.jpg';
import product194 from '../../assets/img/Fuel systems, emissions _ components/Fuel measurement and management/digital-compensator-probe.png';
import product195 from '../../assets/img/Fuel systems, emissions _ components/Fuel measurement and management/digital-densitometer.png';
import product196 from '../../assets/img/Fuel systems, emissions _ components/Fuel measurement and management/fuel-measurement-computer.png';
import product197 from '../../assets/img/Fuel systems, emissions _ components/Fuel measurement and management/gauging-wiring-harness.jpg';
import product198 from '../../assets/img/Fuel systems, emissions _ components/Fuel measurement and management/Refuel defuel panel.jpg';
import product199 from '../../assets/img/Fuel systems, emissions _ components/Fuel measurement and management/tank-wall-interface.jpg';
import product200 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/3-inch-bottom-loading-components.jpg';
import product201 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/3-inch-hydrant-couplers.jpg';
import product202 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/4-inch-bottom-loading-components.jpg';
import product203 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/4-inch-hydrant-couplers.jpg';
import product204 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/bottom-loading-adapters.jpg';
import product205 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/Bypass control valves.jpg';
import product206 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/gravity-fill-nozzles.jpg';
import product207 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/Helicopter closed circuit refueling nozzles.jpg';
import product208 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/Helicopter in-flight refueling.jpg';
import product209 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/hydrant-pit-valves.jpg';
import product210 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/nozzle-disconnects.jpg';
import product211 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/underwing-nozzles.jpg';
import product212 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/unisex-couplings.jpg';
import product213 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/vent-valves.jpg';
import product214 from '../../assets/img/Fuel systems, emissions _ components/ground fueling/venturis.jpg';
import product215 from '../../assets/img/Fuel systems, emissions _ components/Fuel emission control/Active Drain Liquid Trap.jpg';
import product216 from '../../assets/img/Fuel systems, emissions _ components/Fuel emission control/Compact combo valve.jpg';
import product217 from '../../assets/img/Fuel systems, emissions _ components/Fuel emission control/eVaptive electronic venting system.jpg';
import product218 from '../../assets/img/Fuel systems, emissions _ components/Fuel emission control/Fill limit vent valve.jpg';
import product219 from '../../assets/img/Fuel systems, emissions _ components/Fuel emission control/Fuel Tank Isolation Valve.jpg';
import product220 from '../../assets/img/Fuel systems, emissions _ components/Fuel emission control/Fuel Vapour Combo Valve.jpg';
import product221 from '../../assets/img/Fuel systems, emissions _ components/Fuel emission control/Grade vent valve.jpg';
import product222 from '../../assets/img/Fuel systems, emissions _ components/Fuel emission control/Inlet Check Valve.jpg';
import product223 from '../../assets/img/Fuel systems, emissions _ components/Fuel emission control/Pulse damper.jpg';
import product224 from '../../assets/img/Fuel systems, emissions _ components/Fuel emission control/Roll over valve.jpg';

import product225 from '../../assets/img/Centrifugal pumps and control valves/diaphragm control valve.png';
import product226 from '../../assets/img/Centrifugal pumps and control valves/Vortex Pumps T TA.png';
import product227 from '../../assets/img/Centrifugal pumps and control valves/Process Pumps EO EOS.png';
import product228 from '../../assets/img/Centrifugal pumps and control valves/Reactor Pump HT   HPT.png';
import product229 from '../../assets/img/Centrifugal pumps and control valves/Elbow Pumps RPP RPG.png';
import product230 from '../../assets/img/Centrifugal pumps and control valves/Zone 0 Pump.png';
import product231 from '../../assets/img/Centrifugal pumps and control valves/Pilot Pump S.png';
import product232 from '../../assets/img/Centrifugal pumps and control valves/Hybrid Pumps.png';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


function ProductsMechanical() {
  

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProductsMechanicalHeader />
      <div className="section  ">
        <Container>
          
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <p className="mechanical-intro">
              We provide wide range of mechanical products and accessories across the globe with verities of grade and sizes. Includes pipe, fittings, tubes, valves and actuators 
              </p>
              <br />
              
            </Col>
          </Row>
          <br />
        </Container>
      </div>
      <div className="section container mechanical-content pt-0">
          <div className="mechanical-bg" style={{
          backgroundImage:
            "url(" + require("assets/img/bg2-mechanical.webp").default + ")",
        }}>
            <div className="mechanical-heading"><div className="heading-mechanical">
                Mechanical</div></div>
            <div className="mechanical-product-list">
                <ul>
                    <li className="mechanical-item"> Precision Planetary Gearboxes & Gearmotors</li>
                    <li className="mechanical-item">Instrument Fitting</li>
                    <li className="mechanical-item">Metal Products</li>
                    <li className="mechanical-item">Pumps and Motor</li>
                    <li className="mechanical-item">Power transmission products</li>
                    <li className="mechanical-item">Industrial gear motor</li>
                    <li className="mechanical-item">Industrial gear motor</li>
                    <li className="mechanical-item">  Travel drive</li>
                    <li className="mechanical-item">Transit mixer drives</li>
                    <li className="mechanical-item">Industrial Heavy-Duty Geared products</li>
                    <li className="mechanical-item">Vibratory Feeders & Conveyors</li>
                    <li className="mechanical-item"> Material Handling Equipment</li>
                    <li className="mechanical-item">Size Reduction and Laboratory Equipment</li>
                    <li className="mechanical-item">  Rotating Equipment</li>
                    <li className="mechanical-item">Portable cutting and machine tool</li>
                    <li className="mechanical-item">Aerospace actuators and motion control</li>
                    <li className="mechanical-item">clutches and brakes</li>
                    <li className="mechanical-item">Engine valves and valvetrain</li>
                    <li className="mechanical-item">Fuel systems, emissions & components</li>
                    <li className="mechanical-item">Centrifugal pumps and control valves</li>
              
           
    
                </ul>
            </div>
          </div>
      </div>

      <div className="section mechanical-products mechanical-items">
        <Container>
        <div className="heading-electrical-main">Precision Planetary Gearboxes & Gearmotors</div>

        <Container className="no-padding">
            <div className="heading-electrical">In-line precision planetary gearboxes</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>TQ Series</CardTitle>
                    {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product2} alt="..." />
                  <CardBody>
                    <CardTitle>MP</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>TQF</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product3} alt="..." />
                  <CardBody>
                    <CardTitle>TQW</CardTitle>

                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Right-angle precision planetary gearboxes</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product5} alt="..." />
                  <CardBody>
                    <CardTitle>TR</CardTitle>
            
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product6} alt="..." />
                  <CardBody>
                    <CardTitle>TQK Series</CardTitle>

                  </CardBody>
                </Card>
              </div>
             
            </div>
          </Container>

          <Container className="no-padding">
            <div className="heading-electrical">In-line precision planetary gearboxes - Effective Line</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product7} alt="..." />
                  <CardBody>
                    <CardTitle>SL</CardTitle>
            
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product8} alt="..." />
                  <CardBody>
                    <CardTitle>LC</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product9} alt="..." />
                  <CardBody>
                    <CardTitle>TQFE</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product10} alt="..." />
                  <CardBody>
                    <CardTitle>MPE</CardTitle>

                  </CardBody>
                </Card>
              </div>

            </div>
          </Container>

          <Container className="no-padding">
            <div className="heading-electrical">In-line precision planetary gearboxes - Effective Line</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product11} alt="..." />
                  <CardBody>
                    <CardTitle>BMS Series</CardTitle>
            
                  </CardBody>
                </Card>
              </div>
            

            </div>
          </Container>
        </Container>
        <Container>
        <div className="heading-electrical-main">Instrument Fitting</div>

        <Container className="no-padding">
           
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product12} alt="..." />
                  <CardBody>
                    <CardTitle>2 Valve Manifold</CardTitle>
                    {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product12} alt="..." />
                  <CardBody>
                    <CardTitle>4 Way Manifold</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product12} alt="..." />
                  <CardBody>
                    <CardTitle>5 Way Manifold</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product13} alt="..." />
                  <CardBody>
                    <CardTitle>ball-valve</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product14} alt="..." />
                  <CardBody>
                    <CardTitle>Double Block & Bleed Valves</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product15} alt="..." />
                  <CardBody>
                    <CardTitle>Instumentation valve</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product16} alt="..." />
                  <CardBody>
                    <CardTitle>Needle Valve</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product17} alt="..." />
                  <CardBody>
                    <CardTitle>Monoflange Valve</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product18} alt="..." />
                  <CardBody>
                    <CardTitle>Flush Ring</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product19} alt="..." />
                  <CardBody>
                    <CardTitle>Pnuematic flow control valve</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product20} alt="..." />
                  <CardBody>
                    <CardTitle>Pneumatic gripper</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product21} alt="..." />
                  <CardBody>
                    <CardTitle>Pmeumatic Knocker</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product22} alt="..." />
                  <CardBody>
                    <CardTitle>Pneumatic fittings</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product23} alt="..." />
                  <CardBody>
                    <CardTitle>Air Headers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product24} alt="..." />
                  <CardBody>
                    <CardTitle>Union Tee</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product25} alt="..." />
                  <CardBody>
                    <CardTitle>BulkHead Union</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product26} alt="..." />
                  <CardBody>
                    <CardTitle>Reducing Union</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product27} alt="..." />
                  <CardBody>
                    <CardTitle>Male Connector NPT</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product28} alt="..." />
                  <CardBody>
                    <CardTitle>Back Ferrule</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product28} alt="..." />
                  <CardBody>
                    <CardTitle>Compression tube fitting</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product29} alt="..." />
                  <CardBody>
                    <CardTitle>Pressure & Temperature Guages</CardTitle>

                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
         
        </Container>
        <Container>
        <div className="heading-electrical-main">Metal Products</div>

        <Container className="no-padding">
           
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product30} alt="..." />
                  <CardBody>
                    <CardTitle>Industrial Fasteners</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product30} alt="..." />
                  <CardBody>
                    <CardTitle>Anchor Bolts</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product31} alt="..." />
                  <CardBody>
                    <CardTitle>Bonnet bolt</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product32} alt="..." />
                  <CardBody>
                    <CardTitle>Collar bolt</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product33} alt="..." />
                  <CardBody>
                    <CardTitle>Wedge Anchor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product34} alt="..." />
                  <CardBody>
                    <CardTitle>SS Stud bolt</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product35} alt="..." />
                  <CardBody>
                    <CardTitle>SS Flat Bars</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product36} alt="..." />
                  <CardBody>
                    <CardTitle>SS Round bars</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product37} alt="..." />
                  <CardBody>
                    <CardTitle>SS Plates</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product38} alt="..." />
                  <CardBody>
                    <CardTitle>Galv. steel plates</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product39} alt="..." />
                  <CardBody>
                    <CardTitle>HDG Gratings</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product40} alt="..." />
                  <CardBody>
                    <CardTitle>FRP grating</CardTitle>

                  </CardBody>
                </Card>
              </div>
          
            </div>
          </Container>
         
        </Container>
        <Container>
        <div className="heading-electrical-main">Pumps and Motors</div>

        <Container className="no-padding">
           
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product41} alt="..." />
                  <CardBody>
                    <CardTitle>Electric Motor</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product42} alt="..." />
                  <CardBody>
                    <CardTitle>Chemical pump</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product43} alt="..." />
                  <CardBody>
                    <CardTitle>Water pump</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product44} alt="..." />
                  <CardBody>
                    <CardTitle>Centrifugal vertical pump</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product45} alt="..." />
                  <CardBody>
                    <CardTitle>Chopper Pump</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product46} alt="..." />
                  <CardBody>
                    <CardTitle>Slurry pump</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product47} alt="..." />
                  <CardBody>
                    <CardTitle>Helical Rotor Pump</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product48} alt="..." />
                  <CardBody>
                    <CardTitle>Vibration pump</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product49} alt="..." />
                  <CardBody>
                    <CardTitle>End Suction Fire Pump</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product50} alt="..." />
                  <CardBody>
                    <CardTitle>Double diaphram pump</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product51} alt="..." />
                  <CardBody>
                    <CardTitle>Wilden pump metal</CardTitle>

                  </CardBody>
                </Card>
              </div>
            
          
            </div>
          </Container>
         
        </Container>
        <Container>
        <div className="heading-electrical-main">Power transmission products</div>

        <Container className="no-padding">
        <div className="heading-electrical">Bearings
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product52} alt="..." />
                  <CardBody>
                    <CardTitle>Ball bearing</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product53} alt="..." />
                  <CardBody>
                    <CardTitle>Cylindrical Roller Bearings</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product54} alt="..." />
                  <CardBody>
                    <CardTitle>Filament Bearings</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product55} alt="..." />
                  <CardBody>
                    <CardTitle>Sleeve Bearings</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product56} alt="..." />
                  <CardBody>
                    <CardTitle>Spherical Roller Bearings</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product57} alt="..." />
                  <CardBody>
                    <CardTitle>Bearing Parts & Kits</CardTitle>

                  </CardBody>
                </Card>
              </div>
            
            
          
            </div>
          </Container>
          <Container className="no-padding">
        <div className="heading-electrical">Conveying Solutions
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product58} alt="..." />
                  <CardBody>
                    <CardTitle>KleanTop Conveyor Belts</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product59} alt="..." />
                  <CardBody>
                    <CardTitle>MatTop & TableTop Chains</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product60} alt="..." />
                  <CardBody>
                    <CardTitle>Metal Conveyor Belts</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product61} alt="..." />
                  <CardBody>
                    <CardTitle>Turn & Spiral Cage Belts</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product62} alt="..." />
                  <CardBody>
                    <CardTitle>Conveyor Components</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product63} alt="..." />
                  <CardBody>
                    <CardTitle>Conveyor Sprockets</CardTitle>

                  </CardBody>
                </Card>
              </div>
            
            
          
            </div>
          </Container>
          <Container className="no-padding">
        <div className="heading-electrical">Coupling
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product64} alt="..." />
                  <CardBody>
                    <CardTitle>Disc Couplings</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product65} alt="..." />
                  <CardBody>
                    <CardTitle>Elastomeric Couplings</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product66} alt="..." />
                  <CardBody>
                    <CardTitle>Fluid Couplings</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product67} alt="..." />
                  <CardBody>
                    <CardTitle>Gear Couplings</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product68} alt="..." />
                  <CardBody>
                    <CardTitle>Grid Couplings</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product69} alt="..." />
                  <CardBody>
                    <CardTitle>Rigid Couplings</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product70} alt="..." />
                  <CardBody>
                    <CardTitle>torsionally Soft Couplings</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product71} alt="..." />
                  <CardBody>
                    <CardTitle>Torsionally Stiff Couplings</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product71} alt="..." />
                  <CardBody>
                    <CardTitle>Coupling Parts & Kits</CardTitle>

                  </CardBody>
                </Card>
              </div>
            
            
            
          
            </div>
          </Container>
         
          <Container className="no-padding">
        <div className="heading-electrical">Gear
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product72} alt="..." />
                  <CardBody>
                    <CardTitle>Concentric Inline Gear Drives</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product73} alt="..." />
                  <CardBody>
                    <CardTitle>Parallel Gear Drives</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product74} alt="..." />
                  <CardBody>
                    <CardTitle>Right Angle Gear Drives</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product75} alt="..." />
                  <CardBody>
                    <CardTitle>Shaft Mount Gear Drives</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product76} alt="..." />
                  <CardBody>
                    <CardTitle>Gear Components</CardTitle>

                  </CardBody>
                </Card>
              </div>
              
            
            
          
            </div>
          </Container>
          <Container className="no-padding">
        <div className="heading-electrical">Industrial chain
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product77} alt="..." />
                  <CardBody>
                    <CardTitle>Roller Chain</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product78} alt="..." />
                  <CardBody>
                    <CardTitle>Leaf Chain</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product79} alt="..." />
                  <CardBody>
                    <CardTitle>Engineered Steel Chain</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product80} alt="..." />
                  <CardBody>
                    <CardTitle>Drop Forged Chain</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product81} alt="..." />
                  <CardBody>
                    <CardTitle>Silent Chain</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product82} alt="..." />
                  <CardBody>
                    <CardTitle>Idlers</CardTitle>

                  </CardBody>
                </Card>
              </div>
                <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product83} alt="..." />
                  <CardBody>
                    <CardTitle>Sprockets & Wheels</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product84} alt="..." />
                  <CardBody>
                    <CardTitle>Chain Accessories</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product85} alt="..." />
                  <CardBody>
                    <CardTitle>Conveyor Components</CardTitle>

                  </CardBody>
                </Card>
              </div>
            
            
          
            </div>
          </Container>
          <Container className="no-padding">
        <div className="heading-electrical">PT drive components
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product86} alt="..." />
                  <CardBody>
                    <CardTitle>Backstops</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product87} alt="..." />
                  <CardBody>
                    <CardTitle>Brakes</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product88} alt="..." />
                  <CardBody>
                    <CardTitle>Clutches</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product89} alt="..." />
                  <CardBody>
                    <CardTitle>Locking & Clamping Devices</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product90} alt="..." />
                  <CardBody>
                    <CardTitle>Solid State Motor Switches</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product91} alt="..." />
                  <CardBody>
                    <CardTitle>Torque Limiters</CardTitle>

                  </CardBody>
                </Card>
              </div>
           
            
            
          
            </div>
          </Container>
         
         

        

        </Container>
        

        <Container>
        <div className="heading-electrical-main">Industrial Gear Motors</div>

      
        
     
      
          <Container className="no-padding">
        <div className="heading-electrical">Right-angle Gear units
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product92} alt="..." />
                  <CardBody>
                    <CardTitle>RAN Series</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product93} alt="..." />
                  <CardBody>
                    <CardTitle>VF/W series</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product94} alt="..." />
                  <CardBody>
                    <CardTitle>A Series</CardTitle>

                  </CardBody>
                </Card>
              </div>

            
            
          
            </div>
          </Container>
          <Container className="no-padding">
        <div className="heading-electrical">In-Line Geared units
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product95} alt="..." />
                  <CardBody>
                    <CardTitle>AS Series</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product96} alt="..." />
                  <CardBody>
                    <CardTitle>C Series</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product97} alt="..." />
                  <CardBody>
                    <CardTitle>EVOX CP Series</CardTitle>

                  </CardBody>
                </Card>
              </div>

            
            
          
            </div>
          </Container>
          <Container className="no-padding">
        <div className="heading-electrical">Parallel shaft Gearmotors
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product98} alt="..." />
                  <CardBody>
                    <CardTitle>S Series</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product99} alt="..." />
                  <CardBody>
                    <CardTitle>F Series</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product100} alt="..." />
                  <CardBody>
                    <CardTitle>TA Series</CardTitle>

                  </CardBody>
                </Card>
              </div>

            
            
          
            </div>
          </Container>
         
         
         

        

        </Container>
        
        <Container>
        <div className="heading-electrical-main">Travel drive</div>

      
        
     
      
          <Container className="no-padding">
        <div className="heading-electrical">Wheel drives
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product101} alt="..." />
                  <CardBody>
                    <CardTitle>600 Series</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product102} alt="..." />
                  <CardBody>
                    <CardTitle>600WE Series</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product103} alt="..." />
                  <CardBody>
                    <CardTitle>600WT Series</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product104} alt="..." />
                  <CardBody>
                    <CardTitle>600Y Series</CardTitle>

                  </CardBody>
                </Card>
              </div>

            
            
          
            </div>
          </Container>
          <Container className="no-padding">
        <div className="heading-electrical">Travel & track drives
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product105} alt="..." />
                  <CardBody>
                    <CardTitle>700CK Series</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product106} alt="..." />
                  <CardBody>
                    <CardTitle>700 XT</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product107} alt="..." />
                  <CardBody>
                    <CardTitle>700 CE</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product108} alt="..." />
                  <CardBody>
                    <CardTitle>700CT Series</CardTitle>

                  </CardBody>
                </Card>
              </div>

            
            
          
            </div>
          </Container>
       
         

        </Container>
        <Container>
        <div className="heading-electrical-main">Transit Mixer Drives</div>

          <Container className="no-padding">
        <div className="heading-electrical">Transit mixer drives
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product109} alt="..." />
                  <CardBody>
                    <CardTitle>500 Series - Hydraulic solution</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product110} alt="..." />
                  <CardBody>
                    <CardTitle>500 Series - Electric solution</CardTitle>

                  </CardBody>
                </Card>
              </div>
             
            </div>
          </Container>
        
         

        </Container>
        <Container>
        <div className="heading-electrical-main">Industrial Heavy-Duty Geared products</div>

          <Container className="no-padding">
        <div className="heading-electrical">Planetary Geared units
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product111} alt="..." />
                  <CardBody>
                    <CardTitle>300 M</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
          
             
            </div>
          </Container>
        
          <Container className="no-padding">
        <div className="heading-electrical">Right-angle gearmotors and geared units
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product112} alt="..." />
                  <CardBody>
                    <CardTitle>3/H</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
          
             
            </div>
          </Container>
          <Container className="no-padding">
        <div className="heading-electrical">Parallel shaft gear motors and geared units
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product113} alt="..." />
                  <CardBody>
                    <CardTitle>HDP</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
          
             
            </div>
          </Container>
        
      

        </Container>
        
        <Container>
        <div className="heading-electrical-main">Vibratory Feeders & Conveyors</div>

          <Container className="no-padding">
      
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product114} alt="..." />
                  <CardBody>
                    <CardTitle>Light and Medium Duty Feeders</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product115} alt="..." />
                  <CardBody>
                    <CardTitle> Heavy Duty Feeders</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product116} alt="..." />
                  <CardBody>
                    <CardTitle>Vibratory Conveyors</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product117} alt="..." />
                  <CardBody>
                    <CardTitle>Vibratory Screeners</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product118} alt="..." />
                  <CardBody>
                    <CardTitle>Vibratory Controls</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product119} alt="..." />
                  <CardBody>
                    <CardTitle>Bin Vibrators</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product119} alt="..." />
                  <CardBody>
                    <CardTitle>Vibratory Wet Sifting Machine</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product119} alt="..." />
                  <CardBody>
                    <CardTitle>Pneumatic Vibrators</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
          
          
             
            </div>
          </Container>
        
        
      

        </Container>

        <Container>
        <div className="heading-electrical-main">Material Handling Equipment
</div>

          <Container className="no-padding">
      
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product120} alt="..." />
                  <CardBody>
                    <CardTitle>Lifting Magnets</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product121} alt="..." />
                  <CardBody>
                    <CardTitle>Magnetic Chip and Parts Conveyors</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product122} alt="..." />
                  <CardBody>
                    <CardTitle>Finishing Mill Unloaders</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product123} alt="..." />
                  <CardBody>
                    <CardTitle>Magna-Rails and Rolls</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product124} alt="..." />
                  <CardBody>
                    <CardTitle>Magnetic Palletizer/Depalletizer</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product125} alt="..." />
                  <CardBody>
                    <CardTitle>Sheet Fanners</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product125} alt="..." />
                  <CardBody>
                    <CardTitle>Magnetic Belt Conveyors</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product125} alt="..." />
                  <CardBody>
                    <CardTitle>Bar Magnets</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
          
          
             
            </div>
          </Container>
        
        
      

        </Container>
        
        <Container>
        <div className="heading-electrical-main">Size Reduction and Laboratory Equipment
</div>

          <Container className="no-padding">
          <div className="heading-electrical">Crushing

</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product126} alt="..." />
                  <CardBody>
                    <CardTitle>Jaw Crushers</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product126} alt="..." />
                  <CardBody>
                    <CardTitle>Rolls Crushers</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
       
          
             
            </div>
          </Container>
        
          <Container className="no-padding">
          <div className="heading-electrical">Milling

</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product127} alt="..." />
                  <CardBody>
                    <CardTitle>Pulverizers</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product128} alt="..." />
                  <CardBody>
                    <CardTitle>Disintegrators</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
       
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product129} alt="..." />
                  <CardBody>
                    <CardTitle>Hammer Mills</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
       
             
            </div>
          </Container>
        
          <Container className="no-padding">
          <div className="heading-electrical">Grinding
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product130} alt="..." />
                  <CardBody>
                    <CardTitle>Barrel Mills</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product131} alt="..." />
                  <CardBody>
                    <CardTitle>Rod & Ball Mills</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
       
          
             
            </div>
          </Container>
            <Container className="no-padding">
          <div className="heading-electrical">        
          Blending and Mixing

</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product132} alt="..." />
                  <CardBody>
                    <CardTitle>Finger Crushers</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product133} alt="..." />
                  <CardBody>
                    <CardTitle>Tumble Mixers</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
       
          
             
            </div>
          </Container>
          <Container className="no-padding">
          <div className="heading-electrical">        
          Splitting, Sampling and Dividing

</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product134} alt="..." />
                  <CardBody>
                    <CardTitle>Drum Mixers</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product135} alt="..." />
                  <CardBody>
                    <CardTitle>Cascade Splitters</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product136} alt="..." />
                  <CardBody>
                    <CardTitle>Jones Rifflers</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product137} alt="..." />
                  <CardBody>
                    <CardTitle>Cone Blenders</CardTitle>
                  
                  </CardBody>
                </Card>C
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product138} alt="..." />
                  <CardBody>
                    <CardTitle>V-Blenders</CardTitle>
                  
                  </CardBody>
                </Card>C
              </div>
       
       
          
             
            </div>
          </Container>
          <Container className="no-padding">
          <div className="heading-electrical">        
          Filtering and Dewatering
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product139} alt="..." />
                  <CardBody>
                    <CardTitle>Tube Dividers</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product140} alt="..." />
                  <CardBody>
                    <CardTitle>Filter Presses</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
            
       
       
          
             
            </div>
          </Container>
        

        </Container>
        
        <Container>
        <div className="heading-electrical-main"> Rotating Equipment
</div>

          <Container className="no-padding">
          <div className="heading-electrical">Actuators
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product141} alt="..." />
                  <CardBody>
                    <CardTitle>Machine Operated Valve Actuators (MOV)</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product141} alt="..." />
                  <CardBody>
                    <CardTitle>Hydraulic Actuators</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product142} alt="..." />
                  <CardBody>
                    <CardTitle>Pneumatic Actuators</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
       
       
          
             
            </div>
          </Container>
        
          <Container className="no-padding">
          <div className="heading-electrical">Rotary Equipment
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product143} alt="..." />
                  <CardBody>
                    <CardTitle>Axial Fans</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product144} alt="..." />
                  <CardBody>
                    <CardTitle>Couplings</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
       
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product145} alt="..." />
                  <CardBody>
                    <CardTitle>Fan Blades</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product146} alt="..." />
                  <CardBody>
                    <CardTitle>Hubs</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
       
             
            </div>
          </Container>
        
        

        </Container>
        
        <Container>
        <div className="heading-electrical-main"> Portable cutting and machine tool
</div>

          <Container className="no-padding">
         
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product147} alt="..." />
                  <CardBody>
                    <CardTitle>Flange Facing Machine</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product148} alt="..." />
                  <CardBody>
                    <CardTitle>Pipe Cold cutting and bewelling machine</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product149} alt="..." />
                  <CardBody>
                    <CardTitle>Stationary Pipe Cutting & Beveling Machine</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product150} alt="..." />
                  <CardBody>
                    <CardTitle>ID-Mounted & OD-Mounted pipe bevelling machine</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product151} alt="..." />
                  <CardBody>
                    <CardTitle>Tube Facing Machine Orbital Pipe Cutting Machine</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product152} alt="..." />
                  <CardBody>
                    <CardTitle>Orbital cutting machine</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product153} alt="..." />
                  <CardBody>
                    <CardTitle> Pipe to pipe Orbital Welding Machine</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product154} alt="..." />
                  <CardBody>
                    <CardTitle> Plate Edge Milling Machine & Slag Remove Machine</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
       
          
             
            </div>
          </Container>
        
       
        

        </Container>
        

        <Container>
        <div className="heading-electrical-main"> Aerospace actuators and motion control
</div>

          <Container className="no-padding">
         
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product147} alt="..." />
                  <CardBody>
                    <CardTitle>Air-to-air eject launchers</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product148} alt="..." />
                  <CardBody>
                    <CardTitle>Air-to-air rail launchers</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product149} alt="..." />
                  <CardBody>
                    <CardTitle>Hydraulic actuator</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product150} alt="..." />
                  <CardBody>
                    <CardTitle>Pneumatic wing and tail actuators</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product151} alt="..." />
                  <CardBody>
                    <CardTitle>Pneumatic ejection valves</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product152} alt="..." />
                  <CardBody>
                    <CardTitle>Controller</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
            
          
             
            </div>
          </Container>
        
       
        

        </Container>
        

        
        <Container>
        <div className="heading-electrical-main"> Clutches and brakes
</div>

          <Container className="no-padding">
          <div className="heading-electrical">Commercial vehicle clutches
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product155} alt="..." />
                  <CardBody>
                    <CardTitle>Automated ECA Clutch</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product156} alt="..." />
                  <CardBody>
                    <CardTitle>Automated series clutch</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product157} alt="..." />
                  <CardBody>
                    <CardTitle>Self-Adjust Clutch</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product158} alt="..." />
                  <CardBody>
                    <CardTitle>Clutch installation kits</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product159} alt="..." />
                  <CardBody>
                    <CardTitle>Concentric Pneumatic Clutch Actuator</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product160} alt="..." />
                  <CardBody>
                    <CardTitle>Diaphragm Spring Clutch</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product161} alt="..." />
                  <CardBody>
                    <CardTitle>Easy Pedal Advantage clutch</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product162} alt="..." />
                  <CardBody>
                    <CardTitle>EverTough Manual Adjust Clutch</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product163} alt="..." />
                  <CardBody>
                    <CardTitle>EverTough self-adjust clutch</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product164} alt="..." />
                  <CardBody>
                    <CardTitle>UltraShift DM Clutch</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
          
             
            </div>
          </Container>
       
       
          <Container className="no-padding">
          <div className="heading-electrical">Magnetic and solenoid operated shoe brakes
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product165} alt="..." />
                  <CardBody>
                    <CardTitle>DC magnetic operated shoe brakes</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product166} alt="..." />
                  <CardBody>
                    <CardTitle>Industrial duty solenoid operated shoe brakes</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
             
          
             
            </div>
          </Container>
       
        

        </Container>
        

        <Container>
        <div className="heading-electrical-main"> Engine valves and valvetrain
</div>

          <Container className="no-padding">
         
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product167} alt="..." />
                  <CardBody>
                    <CardTitle>Deactivating Hydraulic Lash Adjuster</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product168} alt="..." />
                  <CardBody>
                    <CardTitle>Diesel variable valve actuation</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product169} alt="..." />
                  <CardBody>
                    <CardTitle>Engine Brake</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product170} alt="..." />
                  <CardBody>
                    <CardTitle>Engine valves</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product171} alt="..." />
                  <CardBody>
                    <CardTitle>Hydraulic lash adjusters</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product172} alt="..." />
                  <CardBody>
                    <CardTitle>Rocker arms</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product173} alt="..." />
                  <CardBody>
                    <CardTitle> Switching Roller Finger Follower</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
            
       
          
             
            </div>
          </Container>
        
       
        

        </Container>
        <Container>
        <div className="heading-electrical-main"> Fuel systems, emissions & components
</div>

          <Container className="no-padding">
          <div className="heading-electrical">Aerial refueling
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product174} alt="..." />
                  <CardBody>
                    <CardTitle>Air-to-air refueling probes</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product175} alt="..." />
                  <CardBody>
                    <CardTitle>Aircraft fuel tanks</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product176} alt="..." />
                  <CardBody>
                    <CardTitle>Boom nozzle</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product177} alt="..." />
                  <CardBody>
                    <CardTitle>Buddy refueling pods</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product178} alt="..." />
                  <CardBody>
                    <CardTitle>Composite space pressure vessel</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product179} alt="..." />
                  <CardBody>
                    <CardTitle>Flexible coupling</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product180} alt="..." />
                  <CardBody>
                    <CardTitle>Fuel flow meters</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product181} alt="..." />
                  <CardBody>
                    <CardTitle>Fuselage refueling units</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product182} alt="..." />
                  <CardBody>
                    <CardTitle>MA-2 refueiling nozzle</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product183} alt="..." />
                  <CardBody>
                    <CardTitle>MA-3 aerial refueling coupling </CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product184} alt="..." />
                  <CardBody>
                    <CardTitle>MA-4 aerial refueling coupling</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product185} alt="..." />
                  <CardBody>
                    <CardTitle>MXU multi-platform aircraft cargo pod</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product186} alt="..." />
                  <CardBody>
                    <CardTitle>Refueling drogues</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product187} alt="..." />
                  <CardBody>
                    <CardTitle>Wing air refueling pods</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
             
            </div>
          </Container>
       
       
          <Container className="no-padding">
          <div className="heading-electrical">Fuel inerting
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product188} alt="..." />
                  <CardBody>
                    <CardTitle>Air separation module </CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product189} alt="..." />
                  <CardBody>
                    <CardTitle>Enclosed electronics inerting systems</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product190} alt="..." />
                  <CardBody>
                    <CardTitle>Inerting controller</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product191} alt="..." />
                  <CardBody>
                    <CardTitle>NGS oxygen sensor</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product192} alt="..." />
                  <CardBody>
                    <CardTitle>On-board inert gas generating system</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
          
             
            </div>
          </Container>
       
          <Container className="no-padding">
          <div className="heading-electrical">Fuel measurement and management
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product193} alt="..." />
                  <CardBody>
                    <CardTitle>Digital capacitance probes </CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product194} alt="..." />
                  <CardBody>
                    <CardTitle>Digital compensator probe</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product195} alt="..." />
                  <CardBody>
                    <CardTitle>Digital densitometer</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product196} alt="..." />
                  <CardBody>
                    <CardTitle>Fuel measurement computer</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product197} alt="..." />
                  <CardBody>
                    <CardTitle>Gauging wiring harness</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product198} alt="..." />
                  <CardBody>
                    <CardTitle>Refuel defuel panel</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product199} alt="..." />
                  <CardBody>
                    <CardTitle>Tank wall interface</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
          
             
            </div>
          </Container>
       
          <Container className="no-padding">
          <div className="heading-electrical">Ground fueling
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product200} alt="..." />
                  <CardBody>
                    <CardTitle>3-inch bottom loading components </CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product201} alt="..." />
                  <CardBody>
                    <CardTitle>3-inch hydrant couplers</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product202} alt="..." />
                  <CardBody>
                    <CardTitle>4-inch bottom loading components</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product203} alt="..." />
                  <CardBody>
                    <CardTitle>4-inch hydrant couplers</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product204} alt="..." />
                  <CardBody>
                    <CardTitle>Bottom loading adapters</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product205} alt="..." />
                  <CardBody>
                    <CardTitle>Bypass control valves</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product206} alt="..." />
                  <CardBody>
                    <CardTitle>Gravity fill nozzles</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product207} alt="..." />
                  <CardBody>
                    <CardTitle>Helicopter closed circuit refueling nozzles</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product208} alt="..." />
                  <CardBody>
                    <CardTitle>Helicopter in-flight refueling</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product209} alt="..." />
                  <CardBody>
                    <CardTitle>Hydrant pit valves</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product210} alt="..." />
                  <CardBody>
                    <CardTitle>Nozzle disconnects</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product211} alt="..." />
                  <CardBody>
                    <CardTitle>Underwing nozzles</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product212} alt="..." />
                  <CardBody>
                    <CardTitle>Unisex couplings</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product213} alt="..." />
                  <CardBody>
                    <CardTitle>Vent valves</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product214} alt="..." />
                  <CardBody>
                    <CardTitle>Venturis</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
        
          <Container className="no-padding">
          <div className="heading-electrical">Fuel emission control
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product215} alt="..." />
                  <CardBody>
                    <CardTitle>Active Drain Liquid Trap</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product216} alt="..." />
                  <CardBody>
                    <CardTitle>Compact combo valve</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product217} alt="..." />
                  <CardBody>
                    <CardTitle>eVaptive electronic venting system</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product218} alt="..." />
                  <CardBody>
                    <CardTitle>Fill limit vent valve</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product219} alt="..." />
                  <CardBody>
                    <CardTitle>Fuel Tank Isolation Valve</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product220} alt="..." />
                  <CardBody>
                    <CardTitle>Fuel Vapour Combo Valve</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product221} alt="..." />
                  <CardBody>
                    <CardTitle>Grade vent valve</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product222} alt="..." />
                  <CardBody>
                    <CardTitle>Inlet Check Valve</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product223} alt="..." />
                  <CardBody>
                    <CardTitle>Pulse damper</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product224} alt="..." />
                  <CardBody>
                    <CardTitle>Roll over valve</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
             
            </div>
          </Container>
        </Container>
        
        <Container>
        <div className="heading-electrical-main"> Centrifugal pumps and control valves

</div>

          <Container className="no-padding">
         
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product225} alt="..." />
                  <CardBody>
                    <CardTitle>diaphragm control valve</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product226} alt="..." />
                  <CardBody>
                    <CardTitle>Vortex Pumps T/TA</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product227} alt="..." />
                  <CardBody>
                    <CardTitle>Process Pumps EO/EOS</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product228} alt="..." />
                  <CardBody>
                    <CardTitle>Reactor Pump HT | HPT</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product229} alt="..." />
                  <CardBody>
                    <CardTitle>Elbow Pumps RPP/RPG</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product230} alt="..." />
                  <CardBody>
                    <CardTitle>Zone 0 Pump</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
               <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product231} alt="..." />
                  <CardBody>
                    <CardTitle>Pilot Pump S</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product232} alt="..." />
                  <CardBody>
                    <CardTitle>Hybrid Pumps</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
          
             
            </div>
          </Container>
        
       
        

        </Container>
 



      </div>
      
      <DemoFooter />
    </>
  );
}

export default ProductsMechanical;

/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProductsSafetyHeader from "components/Headers/ProductsSafetyHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "views/examples/ProductsSafety.scss";

import {
  Card,
  CardBody,
  CardTitle,
  CardImg,
} from "reactstrap";

import product1 from "../../assets/img/Electronics components/Capacitors/DC-Link capacitors.jpg";
import product2 from "../../assets/img/Electronics components/Capacitors/Safety film capacitors.jpg";
import product3 from "../../assets/img/Electronics components/Overvoltage protection/AMLV Multilayer Varistors.jpeg";
import product4 from "../../assets/img/Electronics components/Overvoltage protection/MLV Overvoltage protection.jpeg";
import product5 from "../../assets/img/Electronics components/Overvoltage protection/Radial metal oxide varistors.jpg";
import product6 from "../../assets/img/Electronics components/Overvoltage protection/MOVS Metal oxide varistors.jpg";
import product7 from "../../assets/img/Electronics components/Overvoltage protection/MOVTP Metal oxide varistor Thermally protected.jpeg";
import product8 from "../../assets/img/Electronics components/Overvoltage protection/TVS Diodes.jpg";
import product9 from "../../assets/img/Electronics components/Overvoltage protection/TVS Diodes  ESD suppressors.jpeg";


import product10 from "../../assets/img/Electronics components/inductors/Class D audio inductor.jpg";
import product11 from "../../assets/img/Electronics components/inductors/ACE Inductor.jpg";
import product12 from "../../assets/img/Electronics components/inductors/CL Inductor.jpg";
import product13 from "../../assets/img/Electronics components/inductors/CPL Inductor.jpg";
import product14 from "../../assets/img/Electronics components/inductors/Current sense transformers.jpg";
import product15 from "../../assets/img/Electronics components/inductors/Econo-pac (EP) Inductor.jpg";
import product16 from "../../assets/img/Electronics components/inductors/EPM DC-DC Converter.jpeg";
import product17 from "../../assets/img/Electronics components/inductors/FP Inductor.jpeg";
import product18 from "../../assets/img/Electronics components/inductors/LD Inductor.jpeg";
import product19 from "../../assets/img/Electronics components/inductors/VP Transformer.jpg";

import product20 from "../../assets/img/Electronics components/sensors/Current sense transformers.jpg";
import product21 from "../../assets/img/Electronics components/sensors/Epoxy coated NTC thermistors.jpg";
import product22 from "../../assets/img/Electronics components/sensors/Glass coated NTC thermistors.jpg";
import product23 from "../../assets/img/Electronics components/sensors/Metal film resistors.jpg";
import product24 from "../../assets/img/Electronics components/sensors/PCB surface mount NTC thermistors.jpg";
import product25 from "../../assets/img/Electronics components/sensors/Ring lug NTC thermistors.jpg";

import product26 from "../../assets/img/Electronics components/terminal block/1384X Edge connector.jpeg";
import product27 from "../../assets/img/Electronics components/terminal block/A0 _ CB0 Barrier strip terminal block.jpg";
import product28 from "../../assets/img/Electronics components/terminal block/A1 _ CB1 Barrier strip terminal block.jpg";
import product29 from "../../assets/img/Electronics components/terminal block/A2, LP2 _ CB2 Barrier strip terminal block.jpg";
import product30 from "../../assets/img/Electronics components/terminal block/A4 _ B4 Barrier strip terminal block.jpg";
import product31 from "../../assets/img/Electronics components/terminal block/C220x junction box.jpg";
import product32 from "../../assets/img/Electronics components/terminal block/Cage _ Screw Eurostyle PCB terminal block.jpg";
import product33 from "../../assets/img/Electronics components/terminal block/Plugs Eurostyle PCB terminal block.jpg";
import product34 from "../../assets/img/Electronics components/terminal block/Tiered Eurostyle PCB terminal block.jpg";

import product35 from "../../assets/img/Aerospace sensors/Advanced debris monitor.jpg";
import product36 from "../../assets/img/Aerospace sensors/Chip collector.jpg";
import product37 from "../../assets/img/Aerospace sensors/Chip detector.jpg";
import product38 from "../../assets/img/Aerospace sensors/Fiber optic level sensorr.jpg";
import product39 from "../../assets/img/Aerospace sensors/Oil condition monitor.jpg";
import product40 from "../../assets/img/Aerospace sensors/oil-debris-monitor.jpg";
import product41 from "../../assets/img/Aerospace sensors/optical-fluid-level-sensor.jpg";
import product42 from "../../assets/img/Aerospace sensors/pressure-sensors.jpg";
import product43 from "../../assets/img/Aerospace sensors/Quantitative debris monitoring system.jpg";
import product44 from "../../assets/img/Aerospace sensors/zapper.jpg";


import product45 from "../../assets/img/Programmable logic controllers (PLCs)/easyE4 nano programmable logic controllers.jpg";
import product46 from "../../assets/img/Programmable logic controllers (PLCs)/programmable relays.jpg";
import product47 from "../../assets/img/Programmable logic controllers (PLCs)/XC compact programmable logic controllers (PLCs).jpg";
import product48 from "../../assets/img/Programmable logic controllers (PLCs)/XC modular programmable logic controllers (PLCs).jpg";
import product49 from "../../assets/img/Programmable logic controllers (PLCs)/XN300 I O system.jpg";
import product50 from "../../assets/img/semiconductor modules/High Voltage Modules/Diode.png";
import product51 from "../../assets/img/semiconductor modules/High Voltage Modules/Thyristor.png";
import product52 from "../../assets/img/semiconductor modules/High Voltage Modules/Thyristor - Diode.png";
import product53 from "../../assets/img/semiconductor modules/High Voltage Modules/Single Diode.png";
import product54 from "../../assets/img/semiconductor modules/High Voltage Modules/Single Thyristor.png";
import product55 from "../../assets/img/semiconductor modules/Diode Bridge Rectifiers/Three Phase.png";
import product56 from "../../assets/img/semiconductor modules/Diode Bridge Rectifiers/Single Phase.png";
import product57 from "../../assets/img/semiconductor modules/Diode Bridge Modules/Single Phase Diode Bridge Module.png";

import product58 from "../../assets/img/semiconductor modules/Diode Bridge Modules/Single Phase Half Controlled Bridge Modules.png";
import product59 from "../../assets/img/semiconductor modules/Diode Bridge Modules/Three Phase Diode Bridge Module.png";
import product60 from "../../assets/img/semiconductor modules/Diode Bridge Modules/Ultra Fast Recovery Module Diode Module.png";
import product61 from "../../assets/img/Wireless Modules/CBTMN05.jpg";
import product62 from "../../assets/img/Wireless Modules/CBTMN10.jpg";
import product63 from "../../assets/img/Wireless Modules/CBTMN11.jpg";
import product64 from "../../assets/img/Wireless Modules/CBTMN32.jpg";
import product65 from "../../assets/img/Wireless Modules/CBTMN33.jpg";
import product66 from "../../assets/img/Wireless Modules/CBTMN40.png";
import product67 from "../../assets/img/Wireless Modules/CBTLRM01.png";
import product68 from "../../assets/img/Wireless Modules/CBTLRM02.png";
import product69 from "../../assets/img/Wireless Modules/CNBMM01.jpg";
import product70 from "../../assets/img/Evaluation Kits//CBTMN-EVK10.png";
import product72 from "../../assets/img/Evaluation Kits/CBTMN-EVK33.png";
import product71 from "../../assets/img/Evaluation Kits/CBTMN-EVK11.png";
import product73 from "../../assets/img/Evaluation Kits/CBTMN-EVK40.png";
import product74 from "../../assets/img/Evaluation Kits/CBTMN-EVK40.png";
import product75 from "../../assets/img/Evaluation Kits/CBTMN-EVK40.png";
import product76 from "../../assets/img/Evaluation Kits/CBTMN-EVK40.png";
import product77 from "../../assets/img/Sensors/SpaceLogic Temperature Sensors.JPG";
import product78 from "../../assets/img/Sensors/Humidity Sensors.JPG";
import product79 from "../../assets/img/Sensors/SpaceLogic Plant Room Air Quality Sensors.JPG";
import product80 from "../../assets/img/Sensors/current-sensors.JPG";
import product81 from "../../assets/img/Motion control/Servo-Drives-Stylized-Photo.png";
import product82 from "../../assets/img/Motion control/Servo-Motors-and-Cables-Stylized-Photo.png";
import product83 from "../../assets/img/Transfer Switch/Automatic-Transfer-Switches.png";
import product84 from "../../assets/img/Transfer Switch/Manual-Transfer-Switches.png";
import product85 from "../../assets/img/Transfer Switch/Remote-Transfer-Switches.png";
import product86 from "../../assets/img/Signaling Devices/Beacons.png";
import product87 from "../../assets/img/Signaling Devices/Buzzers-and-Hooters-and-Bells.png";
import product88 from "../../assets/img/Signaling Devices/Buzzers-and-Hooters-and-Bells.png";
import product89 from "../../assets/img/Signaling Devices/Buzzers-and-Hooters-and-Bells.png";
import product90 from "../../assets/img/Signaling Devices/Buzzers-and-Hooters-and-Bells.png";
import product91 from "../../assets/img/Signaling Devices/Stack-Lights.png";
import product92 from "../../assets/img/Signaling Devices/Wide-Area-Sirens.png";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function ProductsElectronics() {
  

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProductsSafetyHeader />
      <div className="section  ">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p className="safety-intro">
              “Life matters” - we offer wide range of specialized head to foot safety without compromise on quality. 
              </p>
              <br />
            </Col>
          </Row>
          <br />
        </Container>
      </div>
      <div className="section container safety-content pt-0">
        <div
          className="safety-bg"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg2-safety.webp").default + ")",
          }}
        >
          <div className="safety-heading">
            <div className="heading-safety">Electronics</div>
          </div>
          <div className="safety-product-list">
            <ul>
              <li className="safety-item">Electronics components</li>
              <li className="safety-item">Aerospace sensors</li>
              <li className="safety-item">Programmable logic controllers (PLCs)</li>
              <li className="safety-item">Semiconductor modules</li>
              <li className="safety-item">Wireless Modules</li>  
               <li className="safety-item">Evaluation Kits</li>
               <li className="safety-item">Drives and automation (CG power)</li>
               <li className="safety-item">Sensors and Meters</li>
               <li className="safety-item"> Motion control</li>
               <li className="safety-item"> Transfer Switch</li>
               <li className="safety-item">Busbar Chassis</li>
               <li className="safety-item">Signaling Devices</li>
            </ul>
          </div>
        </div>
      </div>

    

      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Electronics components</div>

          <Container className="no-padding">
            <div className="heading-electrical">Capacitors
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>DC-Link capacitors</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product2} alt="..." />
                  <CardBody>
                    <CardTitle>Safety film capacitors</CardTitle>

                  </CardBody>
                </Card>
              </div>
             
            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Overvoltage protection
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product3} alt="..." />
                  <CardBody>
                    <CardTitle>AMLV Multilayer Varistors</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product4} alt="..." />
                  <CardBody>
                    <CardTitle>MLV Overvoltage protection</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product5} alt="..." />
                  <CardBody>
                    <CardTitle>Radial metal oxide varistors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product6} alt="..." />
                  <CardBody>
                    <CardTitle>MOVS Metal oxide varistors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product7} alt="..." />
                  <CardBody>
                    <CardTitle>MOVTP Metal oxide varistor | Thermally protected</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product8} alt="..." />
                  <CardBody>
                    <CardTitle>TVS Diodes</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product9} alt="..." />
                  <CardBody>
                    <CardTitle>TVS Diodes | ESD suppressors</CardTitle>

                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Inductors
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product10} alt="..." />
                  <CardBody>
                    <CardTitle>Class D audio inductor</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product11} alt="..." />
                  <CardBody>
                    <CardTitle>ACE Inductor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product12} alt="..." />
                  <CardBody>
                    <CardTitle>CL Inductor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product13} alt="..." />
                  <CardBody>
                    <CardTitle>CPL Inductor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product14} alt="..." />
                  <CardBody>
                    <CardTitle>Current sense transformers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product15} alt="..." />
                  <CardBody>
                    <CardTitle>Econo-pac (EP) Inductor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product16} alt="..." />
                  <CardBody>
                    <CardTitle>EPM DC-DC Converter</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product17} alt="..." />
                  <CardBody>
                    <CardTitle>FP Inductor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product18} alt="..." />
                  <CardBody>
                    <CardTitle>LD Inductor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product19} alt="..." />
                  <CardBody>
                    <CardTitle>VP Transformer</CardTitle>

                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Sensors
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product20} alt="..." />
                  <CardBody>
                    <CardTitle>Current sense transformers</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product21} alt="..." />
                  <CardBody>
                    <CardTitle>Epoxy coated NTC thermistors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product22} alt="..." />
                  <CardBody>
                    <CardTitle>Glass coated NTC thermistors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product23} alt="..." />
                  <CardBody>
                    <CardTitle>Metal film resistors</CardTitle>

                  </CardBody>
                </Card>
              </div>  
               <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product24} alt="..." />
                  <CardBody>
                    <CardTitle>PCB surface mount NTC thermistors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product25} alt="..." />
                  <CardBody>
                    <CardTitle>Ring lug NTC thermistors</CardTitle>

                  </CardBody>
                </Card>
              </div>
            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Terminal block
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product26} alt="..." />
                  <CardBody>
                    <CardTitle>1384X Edge connector</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product27} alt="..." />
                  <CardBody>
                    <CardTitle>A0 & CB0 Barrier strip terminal block</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product28} alt="..." />
                  <CardBody>
                    <CardTitle>A1 & CB1 Barrier strip terminal block</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product29} alt="..." />
                  <CardBody>
                    <CardTitle>A2, LP2 & CB2 Barrier strip terminal block</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product30} alt="..." />
                  <CardBody>
                    <CardTitle>A4 & B4 Barrier strip terminal block</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product31} alt="..." />
                  <CardBody>
                    <CardTitle>C220x junction box</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product32} alt="..." />
                  <CardBody>
                    <CardTitle>Cage & Screw Eurostyle PCB terminal block</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product33} alt="..." />
                  <CardBody>
                    <CardTitle>Plugs Eurostyle PCB terminal block</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product34} alt="..." />
                  <CardBody>
                    <CardTitle>Tiered Eurostyle PCB terminal block</CardTitle>

                  </CardBody>
                </Card>
              </div>
         
           
           
            </div>
          </Container>

         
          
        </Container>

      </div>


      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Aerospace sensors
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product35} alt="..." />
                  <CardBody>
                    <CardTitle>Advanced debris monitor</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product36} alt="..." />
                  <CardBody>
                    <CardTitle>Chip collector</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product37} alt="..." />
                  <CardBody>
                    <CardTitle>Chip detector</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product38} alt="..." />
                  <CardBody>
                    <CardTitle>Fiber optic level sensor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product39} alt="..." />
                  <CardBody>
                    <CardTitle>Oil condition monitor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product40} alt="..." />
                  <CardBody>
                    <CardTitle>Oil debris monitor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product41} alt="..." />
                  <CardBody>
                    <CardTitle>Optical fluid level sensor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product42} alt="..." />
                  <CardBody>
                    <CardTitle>Pressure sensors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product43} alt="..." />
                  <CardBody>
                    <CardTitle>Quantitative debris monitoring system</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product44} alt="..." />
                  <CardBody>
                    <CardTitle>Zapper</CardTitle>

                  </CardBody>
                </Card>
              </div>

             

            </div>
          </Container>
       
          
        </Container>

      </div>

      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Programmable logic controllers (PLCs)
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product45} alt="..." />
                  <CardBody>
                    <CardTitle>easyE4 nano programmable logic controllers</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product46} alt="..." />
                  <CardBody>
                    <CardTitle>programmable relays</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product47} alt="..." />
                  <CardBody>
                    <CardTitle>XC compact programmable logic controllers (PLCs)</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product48} alt="..." />
                  <CardBody>
                    <CardTitle>XC modular programmable logic controllers (PLCs)</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product49} alt="..." />
                  <CardBody>
                    <CardTitle>XN300 I/O system</CardTitle>

                  </CardBody>
                </Card>
              </div>
           

            </div>
          </Container>
       
          
        </Container>

      </div>

      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Semiconductor modules
</div>

          <Container className="no-padding">
          <div className="heading-electrical">High Voltage Modules
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product50} alt="..." />
                  <CardBody>
                    <CardTitle>Diode 
</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product51} alt="..." />
                  <CardBody>
                    <CardTitle>Thyristor
</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product52} alt="..." />
                  <CardBody>
                    <CardTitle>Thyristor - Diode</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product53} alt="..." />
                  <CardBody>
                    <CardTitle>Single Diode</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product54} alt="..." />
                  <CardBody>
                    <CardTitle>Single Thyristor</CardTitle>

                  </CardBody>
                </Card>
              </div>

            </div>
          </Container>
          <Container className="no-padding">
          <div className="heading-electrical">Diode Bridge Rectifiers
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product55} alt="..." />
                  <CardBody>
                    <CardTitle>Three Phase
</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product56} alt="..." />
                  <CardBody>
                    <CardTitle>Single Phase
</CardTitle>

                  </CardBody>
                </Card>
              </div>
             

            </div>
          </Container>
          <Container className="no-padding">
          <div className="heading-electrical">Diode Bridge Modules
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product57} alt="..." />
                  <CardBody>
                    <CardTitle>Single Phase Diode Bridge Module
</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product58} alt="..." />
                  <CardBody>
                    <CardTitle>Single Phase Half Controlled Bridge Modules
</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product59} alt="..." />
                  <CardBody>
                    <CardTitle>Three Phase Diode Bridge Module
</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product60} alt="..." />
                  <CardBody>
                    <CardTitle>Ultra Fast Recovery Module Diode Module
</CardTitle>

                  </CardBody>
                </Card>
              </div>
             

            </div>
          </Container>
       
          
        </Container>

      </div>


      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Wireless Modules
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product61} alt="..." />
                  <CardBody>
                    <CardTitle>CBTMN05</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product62} alt="..." />
                  <CardBody>
                    <CardTitle>CBTMN10</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product63} alt="..." />
                  <CardBody>
                    <CardTitle>CBTMN11</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product64} alt="..." />
                  <CardBody>
                    <CardTitle>CBTMN32</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product65} alt="..." />
                  <CardBody>
                    <CardTitle>CBTMN33</CardTitle>

                  </CardBody>
                </Card>
              </div>
            
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product66} alt="..." />
                  <CardBody>
                    <CardTitle>CBTMN40</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product67} alt="..." />
                  <CardBody>
                    <CardTitle>CBTLRM01</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product68} alt="..." />
                  <CardBody>
                    <CardTitle>CBTLRM02</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product69} alt="..." />
                  <CardBody>
                    <CardTitle>CNBMM01</CardTitle>

                  </CardBody>
                </Card>
              </div>
            
            </div>
          </Container>
       
          
        </Container>

      </div>
      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Evaluation Kits
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product70} alt="..." />
                  <CardBody>
                    <CardTitle>CBTMN-EVK10</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product71} alt="..." />
                  <CardBody>
                    <CardTitle>CBTMN-EVK11</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product72} alt="..." />
                  <CardBody>
                    <CardTitle>CBTMN-EVK33</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product73} alt="..." />
                  <CardBody>
                    <CardTitle>CBTMN-EVK40</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
            

            </div>
          </Container>
       
          
        </Container>

      </div>
      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Drives and automation (CG power)
</div>

          <Container className="no-padding">
          <div className="heading-electrical">Soft starter
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product74} alt="..." />
                  <CardBody>
                    <CardTitle>Varible Frequency Starter (VFS)</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product75} alt="..." />
                  <CardBody>
                    <CardTitle>Emotron MSF 2.0 Softstarter</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product76} alt="..." />
                  <CardBody>
                    <CardTitle>Emotron TSA</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product73} alt="..." />
                  <CardBody>
                    <CardTitle>CBTMN-EVK40</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
            

            </div>
          </Container>
          <Container className="no-padding">
          <div className="heading-electrical">Shaft Power Monitors
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product74} alt="..." />
                  <CardBody>
                    <CardTitle>Emotron M10 Shaft Power Monitor</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product75} alt="..." />
                  <CardBody>
                    <CardTitle>Emotron M20 Shaft Power Monitor</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product76} alt="..." />
                  <CardBody>
                    <CardTitle>Emotron DCM Control Unit</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product73} alt="..." />
                  <CardBody>
                    <CardTitle>CBTMN-EVK40</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
            

            </div>
          </Container>
          <Container className="no-padding">
          <div className="heading-electrical">Heat Exchanger drive
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product74} alt="..." />
                  <CardBody>
                    <CardTitle>Emotron EMX Heat Exchanger Drive</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
              
           
            

            </div>
          </Container>
       
          
        </Container>

      </div>
      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Sensors and Meters
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product77} alt="..." />
                  <CardBody>
                    <CardTitle>SpaceLogic Temperature Sensors</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product78} alt="..." />
                  <CardBody>
                    <CardTitle>Humidity Sensors</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product79} alt="..." />
                  <CardBody>
                    <CardTitle>SpaceLogic Plant Room Air Quality Sensors</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product80} alt="..." />
                  <CardBody>
                    <CardTitle>Current sensor</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
            

            </div>
          </Container>
         
          
        </Container>

      </div>
      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Motion control
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product81} alt="..." />
                  <CardBody>
                    <CardTitle>Servo Drives</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product81} alt="..." />
                  <CardBody>
                    <CardTitle>Servo Gearboxes</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product82} alt="..." />
                  <CardBody>
                    <CardTitle>Servo Motors and Cables</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              
            

            </div>
          </Container>
         
          
        </Container>

      </div>
      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Transfer Switch
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product83} alt="..." />
                  <CardBody>
                    <CardTitle>Automatic Transfer Switches</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product84} alt="..." />
                  <CardBody>
                    <CardTitle>Manual Transfer Switches</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product85} alt="..." />
                  <CardBody>
                    <CardTitle>Remote Transfer Switches</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              
            

            </div>
          </Container>
         
          
        </Container>

      </div>
      <div className="section mechanical-products mechanical-products-items">
      <Container>
          <div className="heading-electrical-main">Busbar Chassis
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product74} alt="..." />
                  <CardBody>
                    <CardTitle>Miniature Circuit Breaker Chassis</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product75} alt="..." />
                  <CardBody>
                    <CardTitle>Moulded Case Circuit Breaker Chassis</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
            
              
            

            </div>
          </Container>
         
          
        </Container>

      </div>
      <div className="section mechanical-products mechanical-products-items">
      <Container>
          
          <Container className="no-padding">
          
            <div className="item-grid-container">
            <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product86} alt="..." />
                  <CardBody>
                    <CardTitle>Beacons</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product87} alt="..." />
                  <CardBody>
                    <CardTitle>Buzzers and Hooters and Bells</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
           
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product88} alt="..." />
                  <CardBody>
                    <CardTitle>Electronic Sounders</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
            
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product89} alt="..." />
                  <CardBody>
                    <CardTitle>Hazardous Area Beacons</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product91} alt="..." />
                  <CardBody>
                    <CardTitle>Stack Lights</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product92} alt="..." />
                  <CardBody>
                    <CardTitle>Wide Area Sirens</CardTitle>
                 
                  </CardBody>
                </Card>
              </div>
            
            

            </div>
          </Container>
         
          
        </Container>

      </div>
      <DemoFooter />
    </>
  );
}

export default ProductsElectronics;


import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProductsHeavyHeader from "components/Headers/ProductsHeavyHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "views/examples/ProductsHeavy.scss";

import {
  Card,
  CardBody,
  CardTitle,
  CardImg,
} from "reactstrap";

import product1 from "../../assets/img/Hydraulic Torqueing Tools/Hydraulic Torque Wrench.png";
import product2 from "../../assets/img/Hydraulic Torqueing Tools/Pneumatic Pump.jpg";
import product3 from "../../assets/img/Hydraulic Torqueing Tools/Pneumatic torque wrench.png";

import product4 from "../../assets/img/Hydraulic cylinders/Electrohydraulic cylinders.png";

import product5 from "../../assets/img/Hydraulic cylinders/Heavy-duty tie-rod ISO metric cylinders.png";
import product6 from "../../assets/img/Hydraulic cylinders/Heavy-duty tie-rod NFPA cylinders - N series.png";
import product7 from "../../assets/img/Hydraulic cylinders/Hydraulic threaded cylinders - T series.png";
import product8 from "../../assets/img/Hydraulic cylinders/Large-bore tie-rod NFPA cylinders – G series.png";
import product9 from "../../assets/img/Hydraulic cylinders/Medium-duty hydraulic tie-rod NFPA cylinders – R series.png";
import product10 from "../../assets/img/Hydraulic cylinders/Heavy-duty tie-rod ISO metric cylinders.png";
import product11 from "../../assets/img/Hydraulic cylinders/Welded cylinders - W series.jpg";
import product12 from "../../assets/img/Hydraulic cylinders/XL custom hydraulic cylinders.png";

import product13 from "../../assets/img/Hydraulic/hydraulic-cylinder.jpg";



import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function ProductsHydraulic() {
  

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProductsHeavyHeader />
      <div className="section  ">
        <Container>
          
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p className="heavy-intro">
              Tailer made heavy equipment’s and tools supply based on client needs. Starting from engineering till finished product supply is our commitments 

              </p>
              <br />
              
            </Col>
          </Row>
          <br />
        </Container>
      </div>
      <div className="section heavy-content pt-0 container">
          <div className="heavy-bg" style={{
          backgroundImage:
            "url(" + require("assets/img/bg2-safety.webp").default + ")",
        }}>
            <div className="heavy-heading"><div className="heading-heavy">
          Hydraulics</div></div>
            <div className="heavy-product-list">
                <ul>
                <li className="heavy-item">Hydraulic Torqueing Tools

</li>
                    <li className="heavy-item">Hydraulic cylinders</li>
                    <li className="heavy-item">Hydraulic</li>
                    <li className="heavy-item">Cable Handling Equipments</li>
                    <li className="heavy-item">Transformers</li>
                  
                    
                </ul>
            </div>
          </div>
      </div>

      
      <div className="section mechanical-products heavy-equipment-items">
     
        <Container>
        <div className="heading-electrical-main"> Hydraulic Torqueing Tools
</div>
<Container className="no-padding">
         
         <div className="item-grid-container">
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product1} alt="..." />
               <CardBody>
                 <CardTitle>Hydraulic Torque Wrench</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product2} alt="..." />
               <CardBody>
                 <CardTitle>Pneumatic Pump</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product3} alt="..." />
               <CardBody>
                 <CardTitle>Torqueing Pump</CardTitle>
               
               </CardBody>
             </Card>
           </div>
          
       
          
         </div>
       </Container>
     
        </Container>

        <Container>
        <div className="heading-electrical-main"> Hydraulic cylinders
</div>
<Container className="no-padding">
         
         <div className="item-grid-container">
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product4} alt="..." />
               <CardBody>
                 <CardTitle>Electrohydraulic cylinders</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product5} alt="..." />
               <CardBody>
                 <CardTitle>Heavy-duty tie-rod ISO metric cylinders</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product6} alt="..." />
               <CardBody>
                 <CardTitle>Heavy-duty tie-rod NFPA cylinders - N series</CardTitle>
               
               </CardBody>
             </Card>
           </div>
          
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product7} alt="..." />
               <CardBody>
                 <CardTitle>Hydraulic threaded cylinders - T series</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product8} alt="..." />
               <CardBody>
                 <CardTitle>Large-bore tie-rod NFPA cylinders – G series</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product9} alt="..." />
               <CardBody>
                 <CardTitle>Medium-duty hydraulic tie-rod NFPA cylinders – R series</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product10} alt="..." />
               <CardBody>
                 <CardTitle>Mill-duty hydraulic cylinders – imperial EM and metric IM series</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product11} alt="..." />
               <CardBody>
                 <CardTitle>Welded cylinders - W series</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product12} alt="..." />
               <CardBody>
                 <CardTitle>XL custom hydraulic cylinders</CardTitle>
               
               </CardBody>
             </Card>
           </div>
          
         </div>
       </Container>
     
        </Container>

        <Container>
        <div className="heading-electrical-main"> Hydraulic
</div>
<Container className="no-padding">
         
         <div className="item-grid-container">
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product13} alt="..." />
               <CardBody>
                 <CardTitle>Swivel / Rotary Joints</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product13} alt="..." />
               <CardBody>
                 <CardTitle>High Torque Low Speed Motors</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product13} alt="..." />
               <CardBody>
                 <CardTitle>Radial Piston Pumps</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product13} alt="..." />
               <CardBody>
                 <CardTitle>Customised Hydraulic System</CardTitle>
               
               </CardBody>
             </Card>
           </div>
           <div className="item-grid-container-item">
             <Card >
               <CardImg top src={product13} alt="..." />
               <CardBody>
                 <CardTitle>Hydraulic Cylinders</CardTitle>
               
               </CardBody>
             </Card>
           </div>
          
      
          
         </div>
       </Container>
     
        </Container>
      </div>

     
      <DemoFooter />
    </>
  );
}

export default ProductsHydraulic;

import React from 'react';
import axios from 'axios';
import './MailForm.scss'

class MailForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      submitted:false,
      phone:"",
      location:""

    }
  }

 
  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST",
      url:"http://localhost:3002/send",
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success') {
        //alert("Message Sent.");
        this.resetForm();
        this.setState({submitted: true});
       
      } else if (response.data.status === 'fail') {
        alert("Message failed to send.")
      }
    })
  }

  resetForm(){
    this.setState({name: "", email: "", message: "",phone:"",location:"",submitted:false})
  }
  resetSubmit(){
    this.setState({submitted:false})
  }

  onNameChange(event) {
	  this.setState({name: event.target.value,submitted:false});
  }
  onPhoneChange(event) {
	  this.setState({phone: event.target.value,submitted:false})
  }

  onEmailChange(event) {
	  this.setState({email: event.target.value,submitted:false})
  }
  onLocationChange(event) {
	  this.setState({location: event.target.value,submitted:false})
  }

  onMessageChange(event) {
	  this.setState({message: event.target.value,submitted:false})
  }
  render() {
    return(
      <div className="App-contact-form">
        <form id="contact-form" className="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
          <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" className="form-control" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
          </div>
          <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input type="text" className="form-control" id="phone" value={this.state.phone} onChange={this.onPhoneChange.bind(this)} />
          </div>
          <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email address</label>
              <input type="email" className="form-control" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
          </div>
          <div className="form-group">
              <label htmlFor="exampleInputEmail1">Location</label>
              <input type="text" className="form-control" id="location" aria-describedby="location" value={this.state.location} onChange={this.onLocationChange.bind(this)} />
          </div>
          <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea className="form-control" rows="5" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>  <h3 className = {this.state.submitted ? "success-text d-block" : " success-text d-none"}> Your Query has been submitted, We'll be contacting you soon. Thank you!</h3>
        </form>
       
      </div>
    );
  }

 
}

export default MailForm;
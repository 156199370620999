/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages


import LandingPage from "views/examples/LandingPage.js";

import ProductsElectrical from "views/examples/ProductsElectrical.js"
import ProductsMechanical from "views/examples/ProductsMechanical.js"
import ProductsPneuamtics from "views/examples/ProductsPneumatics.js"
import ProductsInstrument from "views/examples/ProductsInstrument.js"
import ProductsSafety from "views/examples/ProductsSafety.js"
import ProductsHeavy from "views/examples/ProductsHeavy.js"
import ContactsPage from "views/examples/ContactsPage.js"
import ProductsElectronics from "views/examples/ProductsElectronics";
import ProductsElectroMechanical from "views/examples/productsElectroMechanincal";
import ProductsHydraulic from "views/examples/ProductsHydraulic";
import ProductsOil from "views/examples/ProductsOil";
import ProductsTesting from "views/examples/ProductsTesting";
import AbouUsPage from "views/examples/AboutUsPage";


// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/Home" render={(props) => <LandingPage {...props} />} />
      <Route path="/about-us" render={(props) => <AbouUsPage {...props} />} />
      
      <Route
        path="/landing-page"
        render={(props) => <LandingPage {...props} />}
      />
      <Route
        path="/products-electrical"
        render={(props) => <ProductsElectrical {...props} />}
      />
        <Route
        path="/products-electronics"
        render={(props) => <ProductsElectronics {...props} />}
      />
      <Route
        path="/products-mechanical"
        render={(props) => <ProductsMechanical {...props} />}
      />
      <Route
        path="/products-pneumatic"
        render={(props) => <ProductsPneuamtics {...props} />}
      />
      
      <Route
        path="/products-instrument"
        render={(props) => <ProductsInstrument {...props} />}
      />
      <Route
        path="/products-safety"
        render={(props) => <ProductsSafety {...props} />}
      />
       <Route
        path="/products-Heavy"
        render={(props) => <ProductsHeavy {...props} />}
      />
       <Route
        path="/products-electromechanical"
        render={(props) => <ProductsElectroMechanical {...props} />}
      />
       <Route
        path="/products-hydraulics"
        render={(props) => <ProductsHydraulic {...props} />}
      />
       <Route
        path="/products-oil"
        render={(props) => <ProductsOil {...props} />}
      />
        <Route
        path="/products-testing"
        render={(props) => <ProductsTesting {...props} />}
      />

      <Route
        path="/contact"
        render={(props) => <ContactsPage {...props} />}
      />
      <Redirect to="/Home" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import {
  Card,
  CardBody,
  CardTitle,
  CardImg
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProductsElectricalHeader from "components/Headers/ProductsElectricalHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "views/examples/ProductsElectrical.scss";
import product1 from '../../assets/img/ele-power-meter-1.webp';
import product2 from '../../assets/img/ele-clamp-meter-1.webp';
import product3 from '../../assets/img/ele-ground-meter-1.jpg';
import product4 from '../../assets/img/ele-phase-identifiers.webp';
import product5 from '../../assets/img/ele-multimeters.webp';
import product6 from '../../assets/img/ele-power-cables.webp';

import product7 from '../../assets/img/electric motor/BMD - Servo motors.webp';
import product8 from '../../assets/img/electric motor/BN Series.webp';
import product9 from '../../assets/img/electric motor/BE  Series_1.webp';
import product10 from '../../assets/img/electric motor/heavy duty series.webp';
import product11 from '../../assets/img/electric motor/BSR Series.webp';

import product12 from '../../assets/img/inverters and servo drive/ACTIVE_Series_.webp';
import product13 from '../../assets/img/inverters and servo drive/Active_Cube_0000.webp';
import product14 from '../../assets/img/inverters and servo drive/AxiaVert (1) (1).webp';
import product15 from '../../assets/img/inverters and servo drive/AGILE Series.webp';
import product16 from '../../assets/img/inverters and servo drive/iBMD Series_2.webp';
import product17 from '../../assets/img/inverters and servo drive/ANG.webp';
import product18 from '../../assets/img/inverters and servo drive/S2U_.webp';
import product19 from '../../assets/img/inverters and servo drive/S2U IP66.webp';
import product20 from '../../assets/img/inverters and servo drive/AEC_.webp';
import product21 from '../../assets/img/inverters and servo drive/dgm_series.webp';
import product22 from '../../assets/img/inverters and servo drive/dgm_modular.webp';

import product23 from '../../assets/img/Automatic transfer switches/Automatic transfer switch controllers and remote annunciators.jpg';
import product24 from '../../assets/img/Automatic transfer switches/Bypass isolation contactor type automatic transfer switches.jpg';
import product25 from '../../assets/img/Automatic transfer switches/Bypass isolation power frame type automatic transfer switches.jpg';
import product26 from '../../assets/img/Automatic transfer switches/Contactor type automatic transfer switches.jpg';
import product27 from '../../assets/img/Automatic transfer switches/Molded case type automatic transfer switches.jpg';
import product28 from '../../assets/img/Automatic transfer switches/Power frame type automatic transfer switches.jpg';

import product29 from '../../assets/img/Network Connectivity/BestLink Web SNMP Adapter.jpg';
import product30 from '../../assets/img/Network Connectivity/Environmental Monitoring Probe Gen 2.jpg';
import product31 from '../../assets/img/Network Connectivity/expansion-chassis.jpg';
import product32 from '../../assets/img/Network Connectivity/Gigabit Industrial Gateway X2 Card.jpg';
import product33 from '../../assets/img/Network Connectivity/Industrial Gateway Card.jpg';
import product34 from '../../assets/img/Network Connectivity/Industrial Relay Card-MS.jpg';
import product35 from '../../assets/img/Network Connectivity/Relay Card-MS.jpg';
import product36 from '../../assets/img/Network Connectivity/X-Slot Industrial Relay Card.jpg';
import product37 from '../../assets/img/Network Connectivity/Power Xpert Gateway PDP X-Slot Card.jpg';

import product38 from '../../assets/img/power conditioners/Power-Suppress 100.png';
import product39 from '../../assets/img/power conditioners/Power-Suppress 600.jpg';
import product40 from '../../assets/img/power conditioners/Power-Sure 700.jpg';
import product41 from '../../assets/img/power conditioners/Sag Ride-Through power conditioner.jpg';

import product42 from '../../assets/img/Power distribution units for IT equipment/ATS rack PDU.jpeg';
import product43 from '../../assets/img/Power distribution units for IT equipment/Bypass Power Module (BPM).jpg';
import product44 from '../../assets/img/Power distribution units for IT equipment/fuse disconnect rack PDU.jpg';
import product45 from '../../assets/img/Power distribution units for IT equipment/High Density rack PDU.jpg';
import product46 from '../../assets/img/Power distribution units for IT equipment/Managed Rack PDU.jpg';
import product47 from '../../assets/img/Power distribution units for IT equipment/Metered Input rack PDU.jpeg';
import product48 from '../../assets/img/Power distribution units for IT equipment/Metered Outlet rack PDU.jpg';
import product49 from '../../assets/img/Power distribution units for IT equipment/PDI BCMS Hub.jpg';
import product50 from '../../assets/img/Power distribution units for IT equipment/PDI BCMS kit.jpg';
import product51 from '../../assets/img/Power distribution units for IT equipment/PDI JCOMM.jpg';
import product52 from '../../assets/img/Power distribution units for IT equipment/PDI PowerHub 2 PDU.jpg';
import product53 from '../../assets/img/Power distribution units for IT equipment/PDI PowerPak 2 PDU.jpg';
import product54 from '../../assets/img/Power distribution units for IT equipment/PDI PowerPak PDU.jpg';
import product55 from '../../assets/img/Power distribution units for IT equipment/PDI PowerPak RPP.jpg';
import product56 from '../../assets/img/Power distribution units for IT equipment/PDI Static Transfer Switch.jpg';
import product57 from '../../assets/img/Power distribution units for IT equipment/PDI TFA Static Transfer Switch.jpg';
import product58 from '../../assets/img/Power distribution units for IT equipment/PDI Wall Mount RPP.jpg';
import product59 from '../../assets/img/Power distribution units for IT equipment/PDU cables and accessories.jpg';
import product60 from '../../assets/img/Power distribution units for IT equipment/REPO Rack PDU.jpg';

import product61 from '../../assets/img/Surge protection/Aegis series.jpeg';
import product62 from '../../assets/img/Surge protection/CVX series.jpg';
import product63 from '../../assets/img/Surge protection/Eclipse surge suppressors.jpg';
import product64 from '../../assets/img/Surge protection/Retrofit surge protector (RSPF).jpeg';
import product65 from '../../assets/img/Surge protection/SP1 Series.jpg';
import product66 from '../../assets/img/Surge protection/SPC series.jpg';
import product67 from '../../assets/img/Surge protection/SPD Max series surge protective device.jpg';
import product68 from '../../assets/img/Surge protection/SPD Max series surge protective device.jpg';
import product69 from '../../assets/img/Surge protection/SPD series sidemounted surge protective device.jpg';
import product70 from '../../assets/img/Surge protection/Airguard Radio Frequency surge protector.jpg';
import product71 from '../../assets/img/Surge protection/CPAC range for video surveillance systems.jpg';
import product72 from '../../assets/img/Surge protection/DP200 BT _ RJ range for modems and telemetry.jpg';
import product73 from '../../assets/img/Surge protection/FP32 fieldbus trunk and spur surge protection.jpg';
import product74 from '../../assets/img/Surge protection/MTL FS32 fieldbus trunk and spur surge protection.jpg';
import product75 from '../../assets/img/Surge protection/MTL IOP digital _ analogue IO surge protector.jpg';
import product76 from '../../assets/img/Surge protection/MTL LC30 load cell and weighing system surge protector.jpg';
import product77 from '../../assets/img/Surge protection/MTL LS range LED surge protector.jpg';
import product78 from '../../assets/img/Surge protection/MTL MA05 10 30 equipment surge protector.jpg';
import product79 from '../../assets/img/Surge protection/MTL MA15 AC and DC mains filter and surge protector.jpg';
import product80 from '../../assets/img/Surge protection/MTL MA3100 Type 2 surge protector.jpg';
import product81 from '../../assets/img/Surge protection/MTL MA3350 distribution board compact surge protector.jpg';
import product82 from '../../assets/img/Surge protection/MTL MA4000 Hazardous Area AC DC surge protector.jpg';
import product83 from '../../assets/img/Surge protection/MTL mSA remote surge protection for signal, data cabling and telecom.jpg';
import product84 from '../../assets/img/Surge protection/MTL RackPro 12-outlet rack-mounted surge protector.jpg';
import product85 from '../../assets/img/Surge protection/MTL SD surge protector for data _ signal applications.jpg';
import product86 from '../../assets/img/Surge protection/MTL SDM modular surge protector for data _ signal applications.jpg';
import product87 from '../../assets/img/Surge protection/MTL SLP dual-channel hybrid surge protector for data and signal.jpg';
import product88 from '../../assets/img/Surge protection/MTL SSP self-healing surge protection.jpg';
import product89 from '../../assets/img/Surge protection/MTL TP-Pipe surge protection for 2 wire transmitters.jpg';
import product90 from '../../assets/img/Surge protection/MTL TP24 7 intrinsically safe and flameproof surge protection.jpg';
import product91 from '../../assets/img/Surge protection/TP32 _ TP32-T for process transmitters and devices.jpg';
import product92 from '../../assets/img/Surge protection/MTL Zonemaster All Mode modular power surge protectors.jpg';
import product93 from '../../assets/img/Surge protection/PSPD series surge protective device.jpg';

import product94 from '../../assets/img/circuit breaker/BR circuit breakers.jpeg';
import product95 from '../../assets/img/circuit breaker/Magnum with Power Xpert Release trip units.jpg';
import product96 from '../../assets/img/circuit breaker/Power Defense molded case circuit breaker.jpg';
import product97 from '../../assets/img/circuit breaker/ADR Series.jpg';
import product98 from '../../assets/img/circuit breaker/BAB bolt-on miniature circuit breakers.jpeg';
import product99 from '../../assets/img/circuit breaker/BR circuit breakers.jpeg';
import product100 from '../../assets/img/circuit breaker/E2 mining molded case circuit breaker.jpeg';
import product101 from '../../assets/img/circuit breaker/Navy and Marine circuit breakers.jpeg';
import product102 from '../../assets/img/circuit breaker/EFD and EFDC Explosionproof Circuit Breakers.jpg';
import product103 from '../../assets/img/circuit breaker/Encapsulated NEC Miniature Circuit Breakers.jpg';
import product104 from '../../assets/img/circuit breaker/Encapsulated NEC Molded Case Circuit Breakers.jpg';

import product105 from '../../assets/img/fuse and fuse holder/Battery terminal fuses.jpeg';
import product106 from '../../assets/img/fuse and fuse holder/Blade Fuses.jpeg';
import product107 from '../../assets/img/fuse and fuse holder/British Standard IEC fuses.jpg';
import product108 from '../../assets/img/fuse and fuse holder/Cable Limiters.jpg';
import product109 from '../../assets/img/fuse and fuse holder/CCP disconnect switches.jpeg';
import product110 from '../../assets/img/fuse and fuse holder/Class CC fuse blocks and holders.jpeg';
import product111 from '../../assets/img/fuse and fuse holder/Class CC Fuses.jpeg';
import product112 from '../../assets/img/fuse and fuse holder/Class CF fuse blocks and holders.jpeg';
import product113 from '../../assets/img/fuse and fuse holder/Class T fuse blocks and holders.jpeg';
import product114 from '../../assets/img/fuse and fuse holder/double row connectors.jpeg';
import product115 from '../../assets/img/fuse and fuse holder/Power Module Panels.jpg';
import product116 from '../../assets/img/fuse and fuse holder/Power Module Switches.jpg';
import product117 from '../../assets/img/fuse and fuse holder/Pullout Telcom disconnects and fuses.jpg';
import product118 from '../../assets/img/fuse and fuse holder/supplemental fuse blocks, holders, and clips.jpeg';
import product119 from '../../assets/img/fuse and fuse holder/Telcom fuse holders.jpg';

import product120 from '../../assets/img/Protective relays _ predictive diagnostics/Arc-flash-relay.jpg';
import product121 from '../../assets/img/Protective relays _ predictive diagnostics/Bus differential relay.jpeg';
import product122 from '../../assets/img/Protective relays _ predictive diagnostics/distribution relay.jpg';
import product123 from '../../assets/img/Protective relays _ predictive diagnostics/generator relay.jpg';
import product124 from '../../assets/img/Protective relays _ predictive diagnostics/motor relay.jpg';
import product125 from '../../assets/img/Protective relays _ predictive diagnostics/MPrelay.jpg';
import product126 from '../../assets/img/Protective relays _ predictive diagnostics/transformer relay.jpg';
import product127 from '../../assets/img/Protective relays _ predictive diagnostics/temperature controller.jpg';

import product128 from '../../assets/img/Contactors and starters/Freedom NEMA motor control.jpg';
import product129 from '../../assets/img/Contactors and starters/A200 NEMA motor control.jpg';
import product130 from '../../assets/img/Contactors and starters/A202 lighting contactors.jpg';
import product131 from '../../assets/img/Contactors and starters/C30CN mechanically held lighting contactors.jpg';
import product132 from '../../assets/img/Contactors and starters/C30CN mechanically held lighting contactors.jpg';
import product133 from '../../assets/img/Contactors and starters/DC contactors.jpg';
import product134 from '../../assets/img/Contactors and starters/Definite purpose motor control.jpg';
import product135 from '../../assets/img/Contactors and starters/Enclosed HVAC starters.jpg';
import product136 from '../../assets/img/Contactors and starters/Enclosed IEC contactors and starters.jpg';
import product137 from '../../assets/img/Contactors and starters/Enclosed lighting contactors.jpg';
import product138 from '../../assets/img/Contactors and starters/Mill-type DC contactors.jpg';
import product139 from '../../assets/img/Contactors and starters/Vacuum contactors.jpg';
import product140 from '../../assets/img/Contactors and starters/XT IEC mini contactors.jpg';

import product141 from '../../assets/img/Control relays and timers/Timing relays.jpg';
import product142 from '../../assets/img/Control relays and timers/XT IEC control relays.jpg';
import product143 from '../../assets/img/Control relays and timers/Safety relays.jpg';
import product144 from '../../assets/img/Control relays and timers/Alternating relays.jpg';
import product145 from '../../assets/img/Control relays and timers/Mill-type DC relays and overloads.jpg';
import product146 from '../../assets/img/Control relays and timers/Machine tool relays.jpg';
import product147 from '../../assets/img/Control relays and timers/Solid state relays.jpg';
import product148 from '../../assets/img/Control relays and timers/Terminal block relays.jpg';

import product149 from '../../assets/img/MV LV Segment/Metered Ring Main Unit (MRMU).png';
import product150 from '../../assets/img/MV LV Segment/MV Switchgear.png';
import product151 from '../../assets/img/MV LV Segment/MV  LV Soft Starter.png';
import product152 from '../../assets/img/MV LV Segment/LV VFDs.png';
import product153 from '../../assets/img/MV LV Segment/LV Switchgear _ Control Gear.png';
import product154 from '../../assets/img/MV LV Segment/LV Control KIOSK.png';
import product155 from '../../assets/img/MV LV Segment/Pad Mounted Switchgear.png';
import product156 from '../../assets/img/MV LV Segment/Pad Mounted Metering Unit.png';
import product157 from '../../assets/img/MV LV Segment/Power Quality Management.png';
import product158 from '../../assets/img/MV LV Segment/Package Substations.png';
import product159 from '../../assets/img/MV LV Segment/Unit Substations.png';

import product160 from '../../assets/img/CABLE ACCESSORIES/Premolded Cable Joints.jpg';
import product161 from '../../assets/img/CABLE ACCESSORIES/Pre-molded Cable Termination.jpg';
import product162 from '../../assets/img/CABLE ACCESSORIES/Procelain Outdoor Sealing End.jpg';
import product163 from '../../assets/img/CABLE ACCESSORIES/dead break connector.jpg';
import product164 from '../../assets/img/CABLE ACCESSORIES/Link Box.jpg';

import product165 from '../../assets/img/INSULATORS/Porcelain Insulators.jpg';
import product166 from '../../assets/img/INSULATORS/polymer insulator.jpg';
import product167 from '../../assets/img/INSULATORS/SF Insulator String Fitting.jpg';

import product168 from '../../assets/img/protection relays/MCA4 – Directional Feeder Protection.png';
import product169 from '../../assets/img/protection relays/MRA4 – Directional Feeder Protection.png';
import product170 from '../../assets/img/protection relays/MRI4 – Non-Directional Feeder Protection Relay.png';
import product171 from '../../assets/img/protection relays/MRU4 — Voltage and Frequency Protection.png';
import product172 from '../../assets/img/protection relays/MRM4 – Motor Protection.png';
import product173 from '../../assets/img/protection relays/MCDTV4 – Enhanced Transformer Differential Protectio.png';
import product174 from '../../assets/img/protection relays/MRDT4 — Non‑Directional Transformer Differential Protection.png';
import product175 from '../../assets/img/protection relays/MCDGV4-2 – Generator Differential Protection.png';
import product176 from '../../assets/img/protection relays/MCDLV4 – Line Differential Protection.png';
import product177 from '../../assets/img/protection relays/MRMV4 – Motor Protection with Voltage Measurement.png';
import product178 from '../../assets/img/protection relays/HighTech Line.png';
import product179 from '../../assets/img/protection relays/Professional Line.png';
import product180 from '../../assets/img/protection relays/Basic Line.png';
import product181 from '../../assets/img/protection relays/WI Line.png';


import product182 from '../../assets/img/cables/EXTRA HIGH VOLTAGE CABLES.jpg';
import product183 from '../../assets/img/cables/HIGH VOLTAGE CABLES.jpg';
import product184 from '../../assets/img/cables/LOW VOLTAGE CABLES.jpg';
import product185 from '../../assets/img/cables/ESP CABLES.jpg';
import product186 from '../../assets/img/cables/control-cables.jpg';
import product187 from '../../assets/img/cables/SINGLE CORE  MULTICORE FLEXIBLE CABLES.jpg';
import product188 from '../../assets/img/cables/COMMUNICATION CABLES.jpg';
import product189 from '../../assets/img/cables/INSTRUMENTATION CABLES.jpg';
import product190 from '../../assets/img/cables/THERMOCOUPLE EXTENSION.jpg';
import product191 from '../../assets/img/cables/SOLAR CABLES.jpg';
import product192 from '../../assets/img/cables/RUBBER CABLES.jpg';
import product193 from '../../assets/img/cables/FIRE SURVIVAL  RESISTANT CABLES.png';
import product194 from '../../assets/img/cables/MARINE _ OFFSHORE CABLES.png';
import product195 from '../../assets/img/cables/PVC WINDING WIRES.png';
import product196 from '../../assets/img/cables/STAINLESS STEEL WIRES.png';


import product197 from '../../assets/img/LOW VOLTAGE PRODUCTS/contactors/robusTa Contactors _ Overload Relays.jpg';
import product198 from '../../assets/img/LOW VOLTAGE PRODUCTS/contactors/robusTa2 Contactors.jpg';
import product199 from '../../assets/img/LOW VOLTAGE PRODUCTS/contactors/Mini Contactor.jpg';
import product200 from '../../assets/img/LOW VOLTAGE PRODUCTS/contactors/2 _ 4 Pole Contactors.jpg';
import product201 from '../../assets/img/LOW VOLTAGE PRODUCTS/contactors/D Range Contactors.jpg';
import product202 from '../../assets/img/LOW VOLTAGE PRODUCTS/contactors/exceeD Contactors.jpg';
import product203 from '../../assets/img/LOW VOLTAGE PRODUCTS/contactors/Capacitor Duty Contactor.jpg';
import product204 from '../../assets/img/LOW VOLTAGE PRODUCTS/switches/Switch Disconnectors.jpg';
import product205 from '../../assets/img/LOW VOLTAGE PRODUCTS/switches/Switch Disconnector Fuse.jpg';
import product206 from '../../assets/img/LOW VOLTAGE PRODUCTS/switches/Safety Switches.jpg';
import product207 from '../../assets/img/LOW VOLTAGE PRODUCTS/switches/On-Load By Pass Switches.jpg';
import product208 from '../../assets/img/LOW VOLTAGE PRODUCTS/switches/Onload Changeover Switches.jpg';
import product209 from '../../assets/img/LOW VOLTAGE PRODUCTS/switches/Offload Changeover Switches.jpg';
import product210 from '../../assets/img/LOW VOLTAGE PRODUCTS/switches/By-Pass-Switches.jpg';
import product211 from '../../assets/img/LOW VOLTAGE PRODUCTS/Motor starters/Industrial Motor Starters.jpg';
import product212 from '../../assets/img/LOW VOLTAGE PRODUCTS/Motor starters/Anmol Motor Starter.jpg';
import product213 from '../../assets/img/LOW VOLTAGE PRODUCTS/Motor starters/Anmol Smart Mobile Pump Controller.jpg';


import product214 from '../../assets/img/LV _ MV Busducts/Isolated Phase Busduct (IPB).jpg';
import product215 from '../../assets/img/LV _ MV Busducts/Segregated Phase Busduct.jpg';
import product216 from '../../assets/img/LV _ MV Busducts/Non-Segregated Phase Busduct (NSPB).png';
import product217 from '../../assets/img/LV _ MV Busducts/Auxillary Equipments.jpg';

import product218 from '../../assets/img/Power products/earth-fault-indicator.jpg';
import product219 from '../../assets/img/Power products/Pole line hardware.png';
import product220 from '../../assets/img/Power products/Capacitor bank.png';
import product221 from '../../assets/img/Power products/Auto recloser.png';
import product222 from '../../assets/img/Power products/Lugs and conductor.png';
import product223 from '../../assets/img/Power products/PQ meter.png';
import product224 from '../../assets/img/Power products/Voltage regulator.png';
import product225 from '../../assets/img/Power products/Load break switcg.png';

import product226 from '../../assets/img/LV Distribution products/MSDB horizontal busbar 3 phase 400 V 315 A rated.png';
import product227 from '../../assets/img/LV Distribution products/Insulated 10-way MSDB board.png';
import product228 from '../../assets/img/LV Distribution products/Multi-service distribution boards.png';
import product229 from '../../assets/img/LV Distribution products/Sub-Mains Distribution Boards.png';

import product230 from '../../assets/img/LV Distribution products/Indoor Cut out.png';
import product231 from '../../assets/img/LV Distribution products/Fuse Handle.png';

import product232 from '../../assets/img/Switchgear Components/EvoPact LF.JPG';
import product233 from '../../assets/img/Switchgear Components/EasyPact EXE.JPG';
import product234 from '../../assets/img/Switchgear Components/Evolis.JPG';
import product235 from '../../assets/img/Switchgear Components/SF6 CB.JPG';
import product236 from '../../assets/img/Switchgear Components/LBSkit.JPG';
import product237 from '../../assets/img/Switchgear Components/Larc.JPG';


import product238 from '../../assets/img/Energy meter/Bidirectional and net/1 Phase whole current em.jpg';
import product239 from '../../assets/img/Energy meter/Bidirectional and net/Three Phase Whole Current Trivector Energy.jpg';

// import product24 from '../../assets/img/Energy meter/EMS/';
// import product24 from '../../assets/img/Energy meter/EMS';
// import product24 from '../../assets/img/Energy meter/EMS';
// import product24 from '../../assets/img/Energy meter/EMS';
// import product24 from '../../assets/img/Energy meter/EMS';
// import product24 from '../../assets/img/Energy meter/EMS';
// import product24 from '../../assets/img/Energy meter/EMS';
// import product24 from '../../assets/img/Energy meter/EMS';
// import product24 from '../../assets/img/Energy meter/EMS';
// import product24 from '../../assets/img/Energy meter/EMS';



import product240 from '../../assets/img/Panel Accessories/cable-ducts.jpg';
import product241 from '../../assets/img/Panel Accessories/led-indicators-push-button-actuators-and-stations_thumb.jpg';
import product242 from '../../assets/img/Panel Accessories/rotary-and-load-break-switches_thumb.jpg';
import product243 from '../../assets/img/Panel Accessories/timers-time-switches-supply-monitors-counters_thumb.jpg';
// import product24 from '../../assets/img/Panel Accessories';


import product244 from '../../assets/img/final distribution products/au-solution.png';
import product245 from '../../assets/img/final distribution products/au-solution.png';
import product246 from '../../assets/img/final distribution products/exora1.jpg';
import product247 from '../../assets/img/final distribution products/level3_tripper.jpg';




import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function ProductsElectrical() {


  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <ProductsElectricalHeader />
      <div className="section  ">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <p className="electrical-intro">
                We offer a complete range of electrical measurement equipment,
                hand-held instrumentation and testing and diagnostic equipments
                for substations,lines and transformers. We provide a wide range
                of test solutions with unique combinations of features adapted
                to all the users and applications.
              </p>
              <br />
            </Col>
          </Row>
          <br />
        </Container>
      </div>
      <div className="section electrical-content pt-0 container">
        <div
          className="electrical-bg"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg2-electrical.webp").default + ")",
          }}
        >
          <div className="electrical-heading">
            <div className="heading-electrical white">Electrical</div>
          </div>
          <div className="electrical-product-list">
            <ul>
              <li className="electric-item">Power quality Meters</li>
              <li className="electric-item">AC nd DC clamp Meters</li>
              <li className="electric-item">Groundning Meters</li>
              <li className="electric-item">Phase Identifiers</li>
              <li className="electric-item">Digital Multimeters</li>
              <li className="electric-item">Insulation Testers</li>
              <li className="electric-item">Wire and Power Cables</li>
              <li className="electric-item">Motors</li>
              <li className="electric-item">Inverters and Servo drive</li>
              <li className="electric-item">Automatic transfer switches</li>
              <li className="electric-item">Network Connectivity</li>
              <li className="electric-item">Power conditioners </li>
              <li className="electric-item">Power distribution units for IT equipment</li>
              <li className="electric-item">Surge Protection</li>
              <li className="electric-item">Circuit Protection</li>
              <li className="electric-item">Contactors and Starters</li>
              <li className="electric-item">Control relays and Timers</li>
              <li className="electric-item">MV/ LV Segment</li>
              <li className="electric-item">Cable Accessories</li>
              <li className="electric-item">Insulators</li>
              <li className="electric-item">Protection Relays</li>
              <li className="electric-item">Cables</li>
              <li className="electric-item">Low Voltage Products</li>
              <li className="electric-item"> LV & MV Busducts</li>
              <li className="electric-item">LV Bustrunking</li>
              <li className="electric-item">Terminations</li>
              <li className="electric-item">Power Products</li>
              <li className="electric-item">LV Distribution </li>
              <li className="electric-item">Switchgear Components (schnider electric)</li>
              <li className="electric-item">Cable connectors</li>
              <li className="electric-item">Energy meter</li>
              <li className="electric-item">Panel Accessories</li>
              <li className="electric-item">Changeover Switch</li>
              <li className="electric-item">Final Distribution Products </li>




            </ul>
          </div>
        </div>
      </div>



    


      <div className="section mechanical-products electrical-items">
        <Container>
          <div className="heading-electrical-main">Motors</div>

          <Container className="no-padding">
            <div className="heading-electrical">Synchronous Motors</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product7} alt="..." />
                  <CardBody>
                    <CardTitle>BCR Series</CardTitle>
                    {/* <CardSubtitle className="mb-2 text-muted">
                  Card subtitle
                </CardSubtitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <CardLink href="/#/">Card link</CardLink>
                <CardLink href="/#/">Another link</CardLink> */}
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product7} alt="..." />
                  <CardBody>
                    <CardTitle>BMD - Servomotors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product7} alt="..." />
                  <CardBody>
                    <CardTitle>Electric Drives for Mobile Pumps</CardTitle>

                  </CardBody>
                </Card>
              </div>

            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Asynchronous Motors</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product8} alt="..." />
                  <CardBody>
                    <CardTitle>BN Series</CardTitle>
                  
                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product9} alt="..." />
                  <CardBody>
                    <CardTitle>BE Series</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product9} alt="..." />
                  <CardBody>
                    <CardTitle>BX Series</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product10} alt="..." />
                  <CardBody>
                    <CardTitle>Heavy Duty Series</CardTitle>

                  </CardBody>
                </Card>
              </div>

            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Synchronous Reluctance Motors</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product11} alt="..." />
                  <CardBody>
                    <CardTitle>BSR Series</CardTitle>

                  </CardBody>
                </Card>
              </div>


            </div>
          </Container>
        </Container>



        <Container>
          <div className="heading-electrical-main">Inverters  & Servo Drives</div>

          <Container className="no-padding">
            <div className="heading-electrical">Premium Inverters</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product12} alt="..." />
                  <CardBody>
                    <CardTitle>Active Series</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product13} alt="..." />
                  <CardBody>
                    <CardTitle>Active Cube Series</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product14} alt="..." />
                  <CardBody>
                    <CardTitle>AxiaVert Series</CardTitle>

                  </CardBody>
                </Card>
              </div>

            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Standard Inverters</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product15} alt="..." />
                  <CardBody>
                    <CardTitle>AGILE Series</CardTitle>

                  </CardBody>
                </Card>
              </div>

            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Servo Drives</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product16} alt="..." />
                  <CardBody>
                    <CardTitle>iBMD Series</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product17} alt="..." />
                  <CardBody>
                    <CardTitle>ANG Series</CardTitle>

                  </CardBody>
                </Card>
              </div>


            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Compact inversters</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product18} alt="..." />
                  <CardBody>
                    <CardTitle>S2U standard</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product19} alt="..." />
                  <CardBody>
                    <CardTitle>S2U IP66</CardTitle>

                  </CardBody>
                </Card>
              </div>


            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Regenerative Units</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product20} alt="..." />
                  <CardBody>
                    <CardTitle>AEC Series</CardTitle>

                  </CardBody>
                </Card>
              </div>



            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Decentralized Inverters</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product21} alt="..." />
                  <CardBody>
                    <CardTitle>DGM </CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product22} alt="..." />
                  <CardBody>
                    <CardTitle>DGM Modular </CardTitle>

                  </CardBody>
                </Card>
              </div>


            </div>
          </Container>
        </Container>




        <Container>
          <div className="heading-electrical-main">Circuit Protection</div>

          <Container className="no-padding">
            <div className="heading-electrical">Circuit Breakers</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product94} alt="..." />
                  <CardBody>
                    <CardTitle>New digital based two-pole BR GFCI</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product95} alt="..." />
                  <CardBody>
                    <CardTitle>Magnum with Power Xpert Release trip units</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product96} alt="..." />
                  <CardBody>
                    <CardTitle>Power Defense molded case circuit breaker</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product97} alt="..." />
                  <CardBody>
                    <CardTitle>ADR Series</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product98} alt="..." />
                  <CardBody>
                    <CardTitle>BAB bolt-on miniature circuit breakers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product99} alt="..." />
                  <CardBody>
                    <CardTitle>BR circuit breakers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product100} alt="..." />
                  <CardBody>
                    <CardTitle>E2 mining molded case circuit breaker</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product101} alt="..." />
                  <CardBody>
                    <CardTitle>Navy and Marine circuit breakers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product102} alt="..." />
                  <CardBody>
                    <CardTitle>EFD and EFDC Explosionproof Circuit Breakers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product103} alt="..." />
                  <CardBody>
                    <CardTitle>Encapsulated NEC Miniature Circuit Breakers</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product104} alt="..." />
                  <CardBody>
                    <CardTitle>Encapsulated NEC Molded Case Circuit Breakers</CardTitle>

                  </CardBody>
                </Card>
              </div>



            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Fuses and Fuse Holders</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product105} alt="..." />
                  <CardBody>
                    <CardTitle>Battery terminal fuses</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product106} alt="..." />
                  <CardBody>
                    <CardTitle>Blade Fuses</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product107} alt="..." />
                  <CardBody>
                    <CardTitle>British Standard IEC fuses</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product108} alt="..." />
                  <CardBody>
                    <CardTitle>Cable Limiters</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product109} alt="..." />
                  <CardBody>
                    <CardTitle>CCP disconnect switches</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product110} alt="..." />
                  <CardBody>
                    <CardTitle>Class CC fuse blocks and holders</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product111} alt="..." />
                  <CardBody>
                    <CardTitle>Class CC Fuses</CardTitle>

                  </CardBody>
                </Card>
              </div>


              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product112} alt="..." />
                  <CardBody>
                    <CardTitle>Class CF fuse blocks and holders</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product113} alt="..." />
                  <CardBody>
                    <CardTitle>Class T fuse blocks and holders</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product114} alt="..." />
                  <CardBody>
                    <CardTitle>double row connectors</CardTitle>

                  </CardBody>
                </Card>
              </div>
             
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product115} alt="..." />
                  <CardBody>
                    <CardTitle>Power Module Panels</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product116} alt="..." />
                  <CardBody>
                    <CardTitle>Power Module Switches</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product117} alt="..." />
                  <CardBody>
                    <CardTitle>Pullout Telcom disconnects and fuses</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product118} alt="..." />
                  <CardBody>
                    <CardTitle>Supplemental fuse blocks, holders, and clips</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product119} alt="..." />
                  <CardBody>
                    <CardTitle>Telcom fuse holders</CardTitle>

                  </CardBody>
                </Card>
              </div>






            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Protective relays & predictive diagnostics</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product120} alt="..." />
                  <CardBody>
                    <CardTitle>arc flash relay</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product121} alt="..." />
                  <CardBody>
                    <CardTitle>Bus differential relay</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product122} alt="..." />
                  <CardBody>
                    <CardTitle>distribution relay</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product123} alt="..." />
                  <CardBody>
                    <CardTitle>generator relay</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product124} alt="..." />
                  <CardBody>
                    <CardTitle>motor relay</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product125} alt="..." />
                  <CardBody>
                    <CardTitle>MPrelay</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product126} alt="..." />
                  <CardBody>
                    <CardTitle>transformer relay</CardTitle>

                  </CardBody>
                </Card>
              </div>


              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product127} alt="..." />
                  <CardBody>
                    <CardTitle>temperature controller</CardTitle>

                  </CardBody>
                </Card>
              </div>
            





            </div>
          </Container>


        </Container>


        <Container>
          <div className="heading-electrical-main">Low Voltage Products</div>

          <Container className="no-padding">
            <div className="heading-electrical">Contactors</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product197} alt="..." />
                  <CardBody>
                    <CardTitle>robusTa Contactors & Overload Relays</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product198} alt="..." />
                  <CardBody>
                    <CardTitle>robusTa2 Contactors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product199} alt="..." />
                  <CardBody>
                    <CardTitle>Mini Contactor</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product200} alt="..." />
                  <CardBody>
                    <CardTitle>2 & 4 Pole Contactors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product201} alt="..." />
                  <CardBody>
                    <CardTitle>D Range Contactors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product202} alt="..." />
                  <CardBody>
                    <CardTitle>exceeD Contactors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product203} alt="..." />
                  <CardBody>
                    <CardTitle>Capacitor Duty Contactor</CardTitle>

                  </CardBody>
                </Card>
              </div>



            </div>
          </Container>
          <Container className="no-padding">
            <div className="heading-electrical">Switches</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product204} alt="..." />
                  <CardBody>
                    <CardTitle>Switch Disconnectors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product205} alt="..." />
                  <CardBody>
                    <CardTitle>Switch Disconnector Fuse</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product206} alt="..." />
                  <CardBody>
                    <CardTitle>Safety Switches</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product207} alt="..." />
                  <CardBody>
                    <CardTitle>On-Load By Pass Switches</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product208} alt="..." />
                  <CardBody>
                    <CardTitle>Onload Changeover Switches</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product209} alt="..." />
                  <CardBody>
                    <CardTitle>Offload Changeover Switches</CardTitle>

                  </CardBody>
                </Card>
              </div>

              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product210} alt="..." />
                  <CardBody>
                    <CardTitle>ByPass Switches</CardTitle>

                  </CardBody>
                </Card>
              </div>






            </div>
          </Container>

          <Container className="no-padding">
            <div className="heading-electrical">Motor Starters
            </div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product211} alt="..." />
                  <CardBody>
                    <CardTitle>Industrial Motor Starters</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product212} alt="..." />
                  <CardBody>
                    <CardTitle>Anmol Motor Starter</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product213} alt="..." />
                  <CardBody>
                    <CardTitle>Anmol Smart Mobile Pump Controller</CardTitle>

                  </CardBody>
                </Card>
              </div>


            </div>
          </Container>

        </Container>


        <Container>
          <div className="heading-electrical-main">Power Products
          </div>

          <Container className="no-padding">
           
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product218} alt="..." />
                  <CardBody>
                    <CardTitle>Earth fault indicator</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product219} alt="..." />
                  <CardBody>
                    <CardTitle>Pole Line Hardware & Accessories Adjustable Pole Bands</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product220} alt="..." />
                  <CardBody>
                    <CardTitle>Capacitor Bank</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product221} alt="..." />
                  <CardBody>
                    <CardTitle>Auto recloser</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product222} alt="..." />
                  <CardBody>
                    <CardTitle>Lugs and Connector</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product223} alt="..." />
                  <CardBody>
                    <CardTitle>PQ Meter</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product224} alt="..." />
                  <CardBody>
                    <CardTitle>Voltage Regulator</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product225} alt="..." />
                  <CardBody>
                    <CardTitle>Load Break Switch</CardTitle>

                  </CardBody>
                </Card>
              </div>



            </div>
          </Container>
         
        </Container>

        <Container>
          <div className="heading-electrical-main">LV Distribution Products</div>

          <Container className="no-padding">
          <div className="heading-electrical">LV Cabinets</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product226} alt="..." />
                  <CardBody>
                    <CardTitle>MSDB horizontal busbar 3 phase 400 V 315 A rated</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product227} alt="..." />
                  <CardBody>
                    <CardTitle>Insulated 10-way MSDB board</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product228} alt="..." />
                  <CardBody>
                    <CardTitle>Multi-service distribution boards</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product229} alt="..." />
                  <CardBody>
                    <CardTitle>Sub-Mains Distribution Boards</CardTitle>

                  </CardBody>
                </Card>
              </div>
             


            </div>
          </Container>
          <Container className="no-padding">
          <div className="heading-electrical">LV Cutouts</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product230} alt="..." />
                  <CardBody>
                    <CardTitle>Indoor cutout</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product230} alt="..." />
                  <CardBody>
                    <CardTitle>Outdoor Cutout and Distribution Board</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product231} alt="..." />
                  <CardBody>
                    <CardTitle>Fuse handles</CardTitle>

                  </CardBody>
                </Card>
              </div>
            


            </div>
          </Container>
         
        </Container>

        <Container>
          <div className="heading-electrical-main">Switchgear components (schnider electric)
</div>

          <Container className="no-padding">
          <div className="heading-electrical">Switches and Disconnectors
</div>
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product232} alt="..." />
                  <CardBody>
                    <CardTitle>EvoPact LF</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product233} alt="..." />
                  <CardBody>
                    <CardTitle>EasyPact EXE</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product234} alt="..." />
                  <CardBody>
                    <CardTitle>Evolis</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product235} alt="..." />
                  <CardBody>
                    <CardTitle>SF6 CB</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product236} alt="..." />
                  <CardBody>
                    <CardTitle>LBSkit</CardTitle>

                  </CardBody>
                </Card>
              </div>
             
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product237} alt="..." />
                  <CardBody>
                    <CardTitle>LARC</CardTitle>

                  </CardBody>
                </Card>
              </div></div>
          </Container>
      
        </Container>

        <Container>
          <div className="heading-electrical-main">Cable connectors
</div>

          <Container className="no-padding">
          
            <div className="item-grid-container">
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Compression Lugs</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Grounding Connectors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Power Cable Terminations</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Separable Connectors</CardTitle>

                  </CardBody>
                </Card>
              </div>
              <div className="item-grid-container-item">
                <Card >
                  <CardImg top src={product1} alt="..." />
                  <CardBody>
                    <CardTitle>Stem Connectors & PG Clamps</CardTitle>

                  </CardBody>
                </Card>
              </div>
             
             
              </div>
          </Container>

     
      
        </Container>

        <Container>
          <div className="heading-electrical-main">Energy Meter

</div>

      
          <Container className="no-padding">
          <div className="heading-electrical">Bidirectional & Net Meters
</div>
          <div className="item-grid-container">
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product238} alt="..." />
                <CardBody>
                  <CardTitle>Single Phase Whole Current Energy Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product239} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase Whole Current Trivector Energy Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product239} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase HTCT Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product239} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase LTCT Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product239} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase HTCT Multi-Port Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
           
           
            </div>
        </Container>
        <Container className="no-padding">
          <div className="heading-electrical">Energy Management Solutions
</div>
          <div className="item-grid-container">
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Single Function Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Advanced Multifunction Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Maximum Demand Controller</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Multifunction Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Basic Multifunction Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Dual Source Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Energy Meter Counter Type</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Energy Meter DIN Type</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>VAF Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
           
            </div>
        </Container>
        <Container className="no-padding">
          <div className="heading-electrical">Grid and Audit Meters
</div>
          <div className="item-grid-container">
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Single Phase DT Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase Thread Through Long Range Trivector Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase HTCT Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase LTCT Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase HTCT Multi-Port Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
           
           
            </div>
        </Container>
        <Container className="no-padding">
          <div className="heading-electrical">Industrial Meters
</div>
          <div className="item-grid-container">
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase LTCT Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase Whole Current Trivector Energy Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase Thread Through Long Range Trivector Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase HTCT Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
           
           
            </div>
        </Container>
        <Container className="no-padding">
          <div className="heading-electrical">Prepayment and Smart Meters
</div>
          <div className="item-grid-container">
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Single Phase Prepayment Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase Prepayment Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Single Phase Smart Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase Smart Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
           
           
            </div>
        </Container>
        <Container className="no-padding">
          <div className="heading-electrical">Residential & Commercial Meters
</div>
          <div className="item-grid-container">
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Single Phase Whole Current Energy Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase Whole Current Trivector Energy Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Single Phase Prepayment Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase Prepayment Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Single Phase Smart Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Three Phase Smart Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>DIN Rail Mounted Meter</CardTitle>

                </CardBody>
              </Card>
            </div>
           
            </div>
        </Container>
        </Container>


        <Container>
          <div className="heading-electrical-main">Panel Accessories

</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product240} alt="..." />
                <CardBody>
                  <CardTitle>Cable Ducts</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product241} alt="..." />
                <CardBody>
                  <CardTitle>LED Indicators, Push Button Actuators & Stations</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product242} alt="..." />
                <CardBody>
                  <CardTitle>Rotary & Load Break Switches</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product243} alt="..." />
                <CardBody>
                  <CardTitle>Timers, Time Switches, Supply Monitors & Counters</CardTitle>

                </CardBody>
              </Card>
            </div>
           
           
            </div>
        </Container>
        
        </Container>


        <Container>
          <div className="heading-electrical-main">Panel Accessories

</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Cable Ducts</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>LED Indicators, Push Button Actuators & Stations</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Rotary & Load Break Switches</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Timers, Time Switches, Supply Monitors & Counters</CardTitle>

                </CardBody>
              </Card>
            </div>
           
           
            </div>
        </Container>
        
        </Container>


        <Container>
          <div className="heading-electrical-main">Changeover Switch

</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Enclosed ATS</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>C-line Manual Changeover Switch</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>C-line Motorised Changeover Switch</CardTitle>

                </CardBody>
              </Card>
            </div>
         
            </div>
        </Container>
        
        </Container>

        <Container>
          <div className="heading-electrical-main">Changeover Switch

</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
          <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product244} alt="..." />
                <CardBody>
                  <CardTitle>au solutions</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product245} alt="..." />
                <CardBody>
                  <CardTitle>Distribution Board Range</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product246} alt="..." />
                <CardBody>
                  <CardTitle>Exora</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product247} alt="..." />
                <CardBody>
                  <CardTitle>Tripper</CardTitle>

                </CardBody>
              </Card>
            </div>
            </div>
        </Container>
        
        </Container>



        <Container>
          <div className="heading-electrical-main">LV & MV Busducts
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product214} alt="..." />
                <CardBody>
                  <CardTitle>Isolated Phase Busduct (IPB) </CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product215} alt="..." />
                <CardBody>
                  <CardTitle>Segregated Phase Busduct</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product216} alt="..." />
                <CardBody>
                  <CardTitle>Non-Segregated Phase Busduct (NSPB)</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product217} alt="..." />
                <CardBody>
                  <CardTitle>Auxillary Equipments </CardTitle>

                </CardBody>
              </Card>
            </div>
            </div>
        </Container>
        
        </Container>



        
        <Container>
          <div className="heading-electrical-main">LV Bustrunking
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Sandwich Bustrunking (SB)</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Compact Air Bustrunking</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Lighting Trunking (LB)</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Track Busway</CardTitle>

                </CardBody>
              </Card>
            </div>
            </div>
        </Container>
        
        </Container>

        <Container>
          <div className="heading-electrical-main">Terminations
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Medium Voltage Switchgear Terminations</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Heat Shrink High Voltage Terminations</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Heat Shrink High Voltage Joints</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>Pre Moulded EHV Joints</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>EHV Outdoor Terminations</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>GIS/Equipment Terminations</CardTitle>

                </CardBody>
              </Card>
            </div>
            </div>
        </Container>
        
        </Container>

        <Container>
          <div className="heading-electrical-main">Automatic transfer switches
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product23} alt="..." />
                <CardBody>
                  <CardTitle>Automatic transfer switch controllers and remote annunciators</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product24} alt="..." />
                <CardBody>
                  <CardTitle>Bypass isolation contactor type automatic transfer switches</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product25} alt="..." />
                <CardBody>
                  <CardTitle>Bypass isolation power frame type automatic transfer switches</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product26} alt="..." />
                <CardBody>
                  <CardTitle>Contactor type automatic transfer switches</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product27} alt="..." />
                <CardBody>
                  <CardTitle>Molded case type automatic transfer switches</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product28} alt="..." />
                <CardBody>
                  <CardTitle>Power frame type automatic transfer switches</CardTitle>

                </CardBody>
              </Card>
            </div>
            </div>
        </Container>
        
        </Container>


        <Container>
          <div className="heading-electrical-main">Network Connectivity
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product29} alt="..." />
                <CardBody>
                  <CardTitle>BestLink Web/SNMP Adapter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product30} alt="..." />
                <CardBody>
                  <CardTitle>Environmental Monitoring Probe Gen 2</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product31} alt="..." />
                <CardBody>
                  <CardTitle>Expansion-Chassis</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product32} alt="..." />
                <CardBody>
                  <CardTitle>Gigabit Industrial Gateway X2 Card</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product33} alt="..." />
                <CardBody>
                  <CardTitle> Gigabit Network Card</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product34} alt="..." />
                <CardBody>
                  <CardTitle>Industrial Gateway Card</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product35} alt="..." />
                <CardBody>
                  <CardTitle>Industrial Relay Card-MS</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product36} alt="..." />
                <CardBody>
                  <CardTitle>Relay Card-MS</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product37} alt="..." />
                <CardBody>
                  <CardTitle>Power Xpert Gateway PDP X-Slot Card</CardTitle>

                </CardBody>
              </Card>
            </div>
            </div>
        </Container>
        
        </Container>


        <Container>
          <div className="heading-electrical-main">Power conditioners 
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product1} alt="..." />
                <CardBody>
                  <CardTitle>PDI Current Limiting Reactor</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product38} alt="..." />
                <CardBody>
                  <CardTitle>Power-Suppress 100</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product39} alt="..." />
                <CardBody>
                  <CardTitle>Power-Suppress 600</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product40} alt="..." />
                <CardBody>
                  <CardTitle>Power-Sure 700</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product41} alt="..." />
                <CardBody>
                  <CardTitle>Sag Ride-Through power conditioner</CardTitle>

                </CardBody>
              </Card>
            </div>
         
            </div>
        </Container>
        
        </Container>


        <Container>
          <div className="heading-electrical-main">Power distribution units for IT equipment
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product42} alt="..." />
                <CardBody>
                  <CardTitle>ATS rack PDU</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product42} alt="..." />
                <CardBody>
                  <CardTitle>Basic Rack PDU</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product43} alt="..." />
                <CardBody>
                  <CardTitle> Bypass Power Module (BPM)</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product44} alt="..." />
                <CardBody>
                  <CardTitle>fuse disconnect rack PDU</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product45} alt="..." />
                <CardBody>
                  <CardTitle>High Density rack PDU</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product46} alt="..." />
                <CardBody>
                  <CardTitle>Managed Rack PDU</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product47} alt="..." />
                <CardBody>
                  <CardTitle>Metered Input rack PDU</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product48} alt="..." />
                <CardBody>
                  <CardTitle>Metered Outlet rack PDU</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product49} alt="..." />
                <CardBody>
                  <CardTitle>PDI BCMS Hub</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product50} alt="..." />
                <CardBody>
                  <CardTitle>PDI BCMS kit</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product51} alt="..." />
                <CardBody>
                  <CardTitle>PDI JCOMM</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product51} alt="..." />
                <CardBody>
                  <CardTitle>PDI Modular Compact RPP</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product52} alt="..." />
                <CardBody>
                  <CardTitle>PDI PowerHub 2 PDU</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product53} alt="..." />
                <CardBody>
                  <CardTitle>PDI PowerPak 2 PDU</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product54} alt="..." />
                <CardBody>
                  <CardTitle>PDI PowerPak PDU</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product55} alt="..." />
                <CardBody>
                  <CardTitle>PDI PowerPak RPP</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product56} alt="..." />
                <CardBody>
                  <CardTitle>PDI Static Transfer Switch</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product57} alt="..." />
                <CardBody>
                  <CardTitle>PDI TFA Static Transfer Switch</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product58} alt="..." />
                <CardBody>
                  <CardTitle>PDI Wall Mount RPP</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product58} alt="..." />
                <CardBody>
                  <CardTitle>PDI WaveStar Infinity Monitoring System</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product59} alt="..." />
                <CardBody>
                  <CardTitle>PDU cables and accessories</CardTitle>

                </CardBody>
              </Card>
            </div>
         
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product59} alt="..." />
                <CardBody>
                  <CardTitle>Rack Power Module</CardTitle>

                </CardBody>
              </Card>
            </div>

            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product60} alt="..." />
                <CardBody>
                  <CardTitle> REPO Rack PDU</CardTitle>

                </CardBody>
              </Card>
            </div>
         
            </div>
        </Container>
        
        </Container>


        <Container>
          <div className="heading-electrical-main">Surge protection
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product61} alt="..." />
                <CardBody>
                  <CardTitle>series UL Surge Protective Devices</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product61} alt="..." />
                <CardBody>
                  <CardTitle>Home surge protection</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product61} alt="..." />
                <CardBody>
                  <CardTitle> Aegis series</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product62} alt="..." />
                <CardBody>
                  <CardTitle>CVX series</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product63} alt="..." />
                <CardBody>
                  <CardTitle>Eclipse surge suppressors</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product64} alt="..." />
                <CardBody>
                  <CardTitle>Retrofit surge protector (RSPF)</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product65} alt="..." />
                <CardBody>
                  <CardTitle>SP1 Series</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product66} alt="..." />
                <CardBody>
                  <CardTitle>SPC series</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product67} alt="..." />
                <CardBody>
                  <CardTitle> SPD Max series surge protective device</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product68} alt="..." />
                <CardBody>
                  <CardTitle>SPD series integrated surge protective device</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product69} alt="..." />
                <CardBody>
                  <CardTitle>SPD series sidemounted surge protective device</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product70} alt="..." />
                <CardBody>
                  <CardTitle>Airguard Radio Frequency surge protector</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product71} alt="..." />
                <CardBody>
                  <CardTitle>CPAC range for video surveillance systems</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product71} alt="..." />
                <CardBody>
                  <CardTitle>MTL data surge tester</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product72} alt="..." />
                <CardBody>
                  <CardTitle>DP200 BT & RJ range for modems and telemetry</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product73} alt="..." />
                <CardBody>
                  <CardTitle>FP32 fieldbus trunk and spur surge protection</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product74} alt="..." />
                <CardBody>
                  <CardTitle>MTL FS32 fieldbus trunk and spur surge protection</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product75} alt="..." />
                <CardBody>
                  <CardTitle>MTL IOP digital & analogue I/O surge protector</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product76} alt="..." />
                <CardBody>
                  <CardTitle>MTL LC30 load cell and weighing system surge protector</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product77} alt="..." />
                <CardBody>
                  <CardTitle>MTL LS range LED surge protector</CardTitle>

                </CardBody>
              </Card>
            </div>
         
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product78} alt="..." />
                <CardBody>
                  <CardTitle>MTL MA05/10/30 equipment surge protector</CardTitle>

                </CardBody>
              </Card>
            </div>

            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product79} alt="..." />
                <CardBody>
                  <CardTitle>MTL MA15 AC and DC mains filter and surge protector</CardTitle>

                </CardBody>
              </Card>
            </div>
            
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product80} alt="..." />
                <CardBody>
                  <CardTitle>MTL MA3100 Type 2 surge protector</CardTitle>

                </CardBody>
              </Card>
            </div>

            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product81} alt="..." />
                <CardBody>
                  <CardTitle>MTL MA3350 distribution board compact surge protector</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product82} alt="..." />
                <CardBody>
                  <CardTitle>MTL MA4000 Hazardous Area AC/DC surge protector</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product83} alt="..." />
                <CardBody>
                  <CardTitle>MTL mSA remote surge protection for signal, data cabling and telecom</CardTitle>

                </CardBody>
              </Card>
            </div>
        
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product84} alt="..." />
                <CardBody>
                  <CardTitle>MTL RackPro 12-outlet rack-mounted surge protector</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product85} alt="..." />
                <CardBody>
                  <CardTitle>MTL SD surge protector for data & signal applications</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product86} alt="..." />
                <CardBody>
                  <CardTitle>MTL SDM modular surge protector for data & signal applications</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product87} alt="..." />
                <CardBody>
                  <CardTitle>MTL SLP dual-channel hybrid surge protector for data and signal</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product88} alt="..." />
                <CardBody>
                  <CardTitle>MTL SSP self-healing surge protection</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product88} alt="..." />
                <CardBody>
                  <CardTitle>MTL TP-AC range for AC powered field-mounted transmitters</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product89} alt="..." />
                <CardBody>
                  <CardTitle>MTL TP-Pipe surge protection for 2 wire transmitters</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product90} alt="..." />
                <CardBody>
                  <CardTitle>MTL TP24/7 intrinsically safe and flameproof surge protection</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product91} alt="..." />
                <CardBody>
                  <CardTitle>TP32 & TP32-T for process transmitters and devices</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product92} alt="..." />
                <CardBody>
                  <CardTitle>MTL Zonemaster All Mode modular power surge protectors</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product93} alt="..." />
                <CardBody>
                  <CardTitle>PSPD series surge protective device</CardTitle>

                </CardBody>
              </Card>
            </div>
         
            </div>
        </Container>
        
        </Container>

        <Container>
          <div className="heading-electrical-main">Contactors and starters
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product128} alt="..." />
                <CardBody>
                  <CardTitle>Freedom  motor control</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product129} alt="..." />
                <CardBody>
                  <CardTitle>A200 NEMA motor control</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product130} alt="..." />
                <CardBody>
                  <CardTitle>A202 lighting contactors</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product131} alt="..." />
                <CardBody>
                  <CardTitle>C30CN mechanically held lighting contactors</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product132} alt="..." />
                <CardBody>
                  <CardTitle>CN35 electrically held lighting contactors</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product133} alt="..." />
                <CardBody>
                  <CardTitle>DC contactors</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product134} alt="..." />
                <CardBody>
                  <CardTitle>Definite purpose motor control</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product135} alt="..." />
                <CardBody>
                  <CardTitle>Enclosed HVAC starters</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product136} alt="..." />
                <CardBody>
                  <CardTitle>Enclosed IEC contactors and starters</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product137} alt="..." />
                <CardBody>
                  <CardTitle>Enclosed lighting contactors</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product138} alt="..." />
                <CardBody>
                  <CardTitle>Mill-type DC contactors</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product139} alt="..." />
                <CardBody>
                  <CardTitle>Vacuum contactors</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product140} alt="..." />
                <CardBody>
                  <CardTitle>XT IEC mini contactors</CardTitle>

                </CardBody>
              </Card>
            </div>
         
            </div>
        </Container>
        
        </Container>


        <Container>
          <div className="heading-electrical-main">Control Relays and timers
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product141} alt="..." />
                <CardBody>
                  <CardTitle>Timing relays</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product142} alt="..." />
                <CardBody>
                  <CardTitle>XT IEC control relays</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product143} alt="..." />
                <CardBody>
                  <CardTitle>Safety relays</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product144} alt="..." />
                <CardBody>
                  <CardTitle>Alternating relays</CardTitle>

                </CardBody>
              </Card>
            </div>
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product145} alt="..." />
                <CardBody>
                  <CardTitle>Mill-type DC relays and overloads</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product146} alt="..." />
                <CardBody>
                  <CardTitle> Machine tool relays</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product147} alt="..." />
                <CardBody>
                  <CardTitle>Solid state relays</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product148} alt="..." />
                <CardBody>
                  <CardTitle>Terminal block relays</CardTitle>

                </CardBody>
              </Card>
            </div>
          
         
            </div>
        </Container>
        
        </Container>
        

        <Container>
          <div className="heading-electrical-main">MV/ LV Segment
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product149} alt="..." />
                <CardBody>
                  <CardTitle>Metered Ring Main Unit (MRMU)</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product150} alt="..." />
                <CardBody>
                  <CardTitle>MV Switchgear</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product151} alt="..." />
                <CardBody>
                  <CardTitle>MV/ LV Soft Starter</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product152} alt="..." />
                <CardBody>
                  <CardTitle>LV VFDs</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product153} alt="..." />
                <CardBody>
                  <CardTitle>LV Switchgear & Control Gear</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product154} alt="..." />
                <CardBody>
                  <CardTitle>LV Control KIOSK</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product155} alt="..." />
                <CardBody>
                  <CardTitle>Pad Mounted Switchgear</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product156} alt="..." />
                <CardBody>
                  <CardTitle>Pad Mounted Metering Unit</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product157} alt="..." />
                <CardBody>
                  <CardTitle>Power Quality Management</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product158} alt="..." />
                <CardBody>
                  <CardTitle>Package Substations</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product159} alt="..." />
                <CardBody>
                  <CardTitle>Unit Substations</CardTitle>

                </CardBody>
              </Card>
            </div>
       
         
            </div>
        </Container>
        
        </Container>
        
        <Container>
          <div className="heading-electrical-main">Cable Accessories
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product160} alt="..." />
                <CardBody>
                  <CardTitle>Premolded Cable Joints</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product161} alt="..." />
                <CardBody>
                  <CardTitle>Pre-molded Cable Termination </CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product162} alt="..." />
                <CardBody>
                  <CardTitle>Procelain Outdoor Sealing End</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product163} alt="..." />
                <CardBody>
                  <CardTitle>Dead break connector</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product164} alt="..." />
                <CardBody>
                  <CardTitle>Link Box</CardTitle>

                </CardBody>
              </Card>
            </div>
            
         
            </div>
        </Container>
        
        </Container>
        

           
        <Container>
          <div className="heading-electrical-main">Insulators
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product165} alt="..." />
                <CardBody>
                  <CardTitle>Porcelain Insulators</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product166} alt="..." />
                <CardBody>
                  <CardTitle>polymer insulator</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product167} alt="..." />
                <CardBody>
                  <CardTitle>SF Insulator String Fitting</CardTitle>

                </CardBody>
              </Card>
            </div>
           
            
         
            </div>
        </Container>
        
        </Container>
        

        <Container>
          <div className="heading-electrical-main">Protection Relays
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product168} alt="..." />
                <CardBody>
                  <CardTitle>MCA4 – Directional Feeder Protection</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product169} alt="..." />
                <CardBody>
                  <CardTitle>MRA4 – Directional Feeder Protection</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product170} alt="..." />
                <CardBody>
                  <CardTitle>MRI4 – Non-Directional Feeder Protection Relay</CardTitle>

                </CardBody>
              </Card>
            </div>
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product171} alt="..." />
                <CardBody>
                  <CardTitle>MRU4 — Voltage and Frequency Protection</CardTitle>

                </CardBody>
              </Card>
            </div>
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product172} alt="..." />
                <CardBody>
                  <CardTitle>MRM4 – Motor Protection</CardTitle>

                </CardBody>
              </Card>
            </div>
                 
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product173} alt="..." />
                <CardBody>
                  <CardTitle>MCDTV4 – Enhanced Transformer Differential Protectio</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product174} alt="..." />
                <CardBody>
                  <CardTitle>MRDT4 — Non‑Directional Transformer Differential Protection</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product175} alt="..." />
                <CardBody>
                  <CardTitle>MCDGV4-2 – Generator Differential Protection</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product176} alt="..." />
                <CardBody>
                  <CardTitle>MCDLV4 – Line Differential Protection</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product177} alt="..." />
                <CardBody>
                  <CardTitle>MRMV4 – Motor Protection with Voltage Measurement</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product178} alt="..." />
                <CardBody>
                  <CardTitle>HighTech Line </CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product179} alt="..." />
                <CardBody>
                  <CardTitle>Professional Line </CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product180} alt="..." />
                <CardBody>
                  <CardTitle>Basic Line protection relay </CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product181} alt="..." />
                <CardBody>
                  <CardTitle>WI Line protection relay</CardTitle>

                </CardBody>
              </Card>
            </div>
            </div>
        </Container>
        
        </Container>


        <Container>
          <div className="heading-electrical-main">Cables
</div>

      
          <Container className="no-padding">
        
          <div className="item-grid-container">
           
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product182} alt="..." />
                <CardBody>
                  <CardTitle>EXTRA HIGH VOLTAGE CABLES </CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product183} alt="..." />
                <CardBody>
                  <CardTitle>HIGH VOLTAGE CABLES </CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product184} alt="..." />
                <CardBody>
                  <CardTitle>LOW VOLTAGE CABLES</CardTitle>

                </CardBody>
              </Card>
            </div>
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product185} alt="..." />
                <CardBody>
                  <CardTitle>ESP CABLES</CardTitle>

                </CardBody>
              </Card>
            </div>
           
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product186} alt="..." />
                <CardBody>
                  <CardTitle>CONTROL CABLES</CardTitle>

                </CardBody>
              </Card>
            </div>
                 
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product187} alt="..." />
                <CardBody>
                  <CardTitle>SINGLE CORE / MULTICORE FLEXIBLE CABLES</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product188} alt="..." />
                <CardBody>
                  <CardTitle>COMMUNICATION CABLES</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product189} alt="..." />
                <CardBody>
                  <CardTitle>INSTRUMENTATION CABLES</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product190} alt="..." />
                <CardBody>
                  <CardTitle>THERMOCOUPLE EXTENSION</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product191} alt="..." />
                <CardBody>
                  <CardTitle>SOLAR CABLES </CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product192} alt="..." />
                <CardBody>
                  <CardTitle>RUBBER CABLES</CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product193} alt="..." />
                <CardBody>
                  <CardTitle>FIRE SURVIVAL / RESISTANT CABLES </CardTitle>

                </CardBody>
              </Card>
            </div>
            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product194} alt="..." />
                <CardBody>
                  <CardTitle>MARINE & OFFSHORE CABLES</CardTitle>

                </CardBody>
              </Card>
            </div>

            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product195} alt="..." />
                <CardBody>
                  <CardTitle>PVC WINDING WIRES</CardTitle>

                </CardBody>
              </Card>
            </div>

            <div className="item-grid-container-item">
              <Card >
                <CardImg top src={product196} alt="..." />
                <CardBody>
                  <CardTitle>STAINLESS STEEL WIRES </CardTitle>

                </CardBody>
              </Card>
            </div>
            </div>
        </Container>
        
        </Container>
      </div>


      <DemoFooter />
    </>
  );
}

export default ProductsElectrical;

/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import "views/examples/LandingPage.scss";
import Logo from "../../assets/img/Leistung-Logo.jpg";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CardText,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <div className="content-section">
        <div className="carousel-bg">
          <Carousel
            interval="7000"
            infiniteLoop
            autoPlay
            transitionTime="3000"
            showThumbs={false}
            dynamicHeight={false}
          >
            <div className="bg-image-home-carousel">
              <div
                className="bg1"
                style={{
                  backgroundImage:
                    "url(" +
                    require("assets/img/bg2-electrical.webp").default +
                    ")",
                }}
              ></div>
            </div>
            <div className="bg-image-home-carousel">
              <div
                className="bg1"
                style={{
                  backgroundImage:
                    "url(" +
                    require("assets/img/bg2-mechanical.webp").default +
                    ")",
                }}
              >
                {" "}
              </div>
            </div>
            <div className="bg-image-home-carousel">
              <div
                className="bg1"
                style={{
                  backgroundImage:
                    "url(" +
                    require("assets/img/bg2-pneumatic.webp").default +
                    ")",
                }}
              >
                {" "}
              </div>
            </div>
            <div className="bg-image-home-carousel">
              <div
                className="bg1"
                style={{
                  backgroundImage:
                    "url(" + require("assets/img/bg-safety.webp").default + ")",
                }}
              >
                {" "}
              </div>
            </div>
            <div className="bg-image-home-carousel">
              <div
                className="bg1 safety"
                style={{
                  backgroundImage:
                    "url(" + require("assets/img/bg-4.webp").default + ")",
                }}
              >
                {" "}
              </div>
            </div>
            <div className="bg-image-home-carousel">
              <div
                className="bg1 heavy"
                style={{
                  backgroundImage:
                    "url(" + require("assets/img/bg2-heavy.webp").default + ")",
                }}
              >
                {" "}
              </div>
            </div>
          </Carousel>

          <div className="home-intro">
            <div className="intro-card">
              <Card style={{ width: "20rem" }}>
                <CardImg top src={Logo} alt="..." />
                <CardBody>
                  <CardTitle>
                    Providing Utilities solutions All over the world
                  </CardTitle>
                  <CardText></CardText>
                  <a href="/contact">
                    {" "}
                    <Button color="danger">
                    +1 713-999-9099<br />
                      <p>sales@leistungcorp.com</p>
                    </Button>{" "}
                  </a>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="intro-img">
            <Carousel
              interval="7000"
              infiniteLoop
              autoPlay
              transitionTime="3000"
              showThumbs={false}
              dynamicHeight={false}
              width="550px"
            >
              <div className="bg-card-home-carousel">
                <div className="carousel-card card-1">
                  <p>
                    Providing Best quality solutions to Utility, Petrochemical,
                    Oil and Gas sectors which enables them to boost
                    operation-maintenance procedures
                  </p>
                </div>
              </div>
              <div className="bg-card-home-carousel">
                <div className="carousel-card card-2">
                  <p>
                    Leistung group of engineers keeps track of latest
                    innovations across the globe and brings the same to the
                    customer hands.
                  </p>
                </div>
              </div>
              <div className="bg-card-home-carousel">
                <div className="carousel-card card-3">
                  <p>
                    Leistung incorporates the experience in working with wide
                    sectors all over the world, and applies best possible
                    patterns and trends , which brings the best of the world to
                    our clients
                  </p>
                </div>
              </div>
              <div className="bg-card-home-carousel">
                <div className="carousel-card card-4">
                  <p>
                    Leistung serves to provide flexible solutions to enhance
                    customer operations at a minimum cost.
                  </p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
     
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title text-left">About Leistung</h2>
                <h5 className="description text-left">
                  Leistung Corporation is a leading player providing solutions
                  in the field of{" "}
                  <b>Electro-mechanical, Petrochemical, Oil and Gas</b>{" "}
                  globally. Our team of engineers will advise you on your needs
                  to help you choose the best product. <br /> <br />
                  We aims to take varying customer requirements individually,
                  study thenm and provide the <b>Best solutions</b> available.
                  Standing by this principle, Leistung started penetrating roots
                  into global power markets with our innovative products and
                  services.
                  <br /> <br />
                  We are dedicated to the{" "}
                  <b>Latest technology instrumentation</b> for the diagnosis and
                  testing of electrical substations, which makes us specialists
                  in our sector.
                  <br /> <br />
                  We offer a complete range of{" "}
                  <b>Electrical measurement equipment</b> , hand-held
                  instrumentation and testing and diagnostic equipment for
                  substations, lines and transformation centers.
                </h5>

                <br />
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-album-2" />
                  </div>
                  <div className="description">
                   
                    <p className="description">
                      Providing Best quality solutions to sectors which enables
                      them to boost operation-maintanace parameters
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-bulb-63" />
                  </div>
                  <div className="description">
                   
                    <p>
                      Leistung Engineers keeps track of latest innovations
                      acrosss the globe and brings the same to the customer
                      hands.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-chart-bar-32" />
                  </div>
                  <div className="description">
                 
                    <p>
                      Leistung incorporates the experince in working with
                      utilities all over the world, and applies best possible
                      patterns and trends , which brings the best of the world
                      to out clients
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-sun-fog-29" />
                  </div>
                  <div className="description">
                   
                    <p>
                      We serves to provide flexible solutions to enhance
                      customer operations at a minimum cost.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section section-grey text-center">
          <div className="container home-intro-tiles ">
            <div className="home-tiles-grid">
              <h2 className="text-center mb-4 mt-0 text-h2-primary">
                {" "}
                Our Products
              </h2>
              <div className="row-1">
                <span className="home-tiles orange" href="/products-electrical">
                  <a className="" href="/products-electrical">
                    Electrical
                  </a>{" "}
                </span>
                <span className=" home-tiles grey">
                  {" "}
                  <a className="" href="/products-mechanical">
                    Mechanical
                  </a>
                </span>
                <span className=" home-tiles yellow">
                  <a className="" href="/products-pneumatic">
                    Pneumatics
                  </a>{" "}
                </span>
              </div>
              <div className="row-2">
                <span className="home-tiles blue">
                  {" "}
                  <a className="" href="/products-instrument">
                    Instruments
                  </a>
                </span>
                <span className="home-tiles green">
                  <a className="tile-safety" href="/products-safety">
                    Safety and Protective Equipments
                  </a>
                </span>
                <span className="home-tiles red">
                  <a className="tile-heavy" href="/products-heavy">
                    Heavy Euipments and Tools
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="section landing-section d-none">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Keep in touch?</h2>
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Name</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Name" type="text" />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="text" />
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Message</label>
                  <Input
                    placeholder="Tell us your thoughts and feelings..."
                    type="textarea"
                    rows="4"
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button className="btn-fill" color="danger" size="lg">
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <DemoFooter />
    </>
  );
}

export default LandingPage;
